import { KeycloakService } from 'keycloak-angular';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { OrgUserService } from './Services/org-user/org-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  items: MenuItem[] = [];
  activeItem: any = [];

  loading = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private keycloak: KeycloakService,
    private orgUserService: OrgUserService,
    private messageService: MessageService
  ) {
    router.navigateByUrl('');
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');
  }

  async ngOnInit(): Promise<any> {
    const superadmin = this.keycloak.isUserInRole('superadminrole');
    const orgadmin = this.keycloak.isUserInRole('orgadminrole');

    if (orgadmin === true) {
      this.showLoad();
      await this.keycloak.loadUserProfile();
      const username = await this.keycloak.getUsername();
      // get user organisation
      const usr = await this.orgUserService.getOrganisationUsers(0, 0, ' ', username)
      .toPromise().catch(err => this.handleError(err));
      if (usr !== undefined && usr !== null && usr.length > 0) {
        localStorage.setItem('uo', usr[0].organisationName);
      } else {
        localStorage.setItem('uo', 'xxxxxxxxxxxxx000000000000000000000');
      }

      this.hideLoad();
    }

    if (superadmin === true) {
      localStorage.setItem('uo', ' ');
    }

    const users = await this.translate.get('GENERAL.USERS').toPromise().catch(err => console.log(err));
    const orgs = await this.translate.get('GENERAL.ORGANISATIONS').toPromise().catch(err => console.log(err));

    // this.items.push({ label: users, icon: 'pi pi-fw pi-user', routerLink: 'users' });
    // this.items.push({ label: orgs, icon: 'pi pi-fw pi-users', routerLink: 'organisations' });

    this.items = [
      {
          label: users,
          icon: 'pi pi-fw pi-user',
          routerLink: 'users'
      },
      {
        label: orgs,
        icon: 'pi pi-fw pi-sitemap',
        routerLink: 'organisations'
      }
    ];

    const height = document.getElementById('contentdiv')?.offsetHeight;
    if (height !== undefined && height !== null) {
      localStorage.setItem('content', height?.toString());
    }

    this.router.navigateByUrl('users');
    this.activeItem = this.items[0];
  }

  showLoad(): any {
    this.showConfirm('', '', 'loaderhome', 'aaa');
  }

  hideLoad(): any {
    this.messageService.clear('loaderhome');
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  handleError(err: any): any {
    this.hideLoad();
    console.log(err);
  }
}
