import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { OrganisationUser } from 'src/app/Models/organisation-user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrgUserService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getOrganisationUsers(page: number, size: number, org: string, phone: string): Observable<any> {
    const url = this.apiUrl + '/api/organisationuser/' + page + '/' + size + '/' + org
     + '/' + phone + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postOrganisationUser(org: OrganisationUser): Observable<any> {
    return this.httpclient.post(this.apiUrl + '/api/organisationuser', org)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteOrganisation(value: OrganisationUser): Observable<any> {
    return this.httpclient.put(this.apiUrl + '/api/organisationuser/0', value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
