// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // apiUrl: 'https://localhost:44345',
  // appUrl: 'http://localhost:4201',
  // realm: 'TIMRealm',
  // clientid: 'TIMWebClient',
  // keycloakUrl: 'http://localhost:8088'

  production: true,
  apiUrl: window['env' as any]['APIURL' as any] as any || 'default',
  appUrl: window['env' as any]['APPURL' as any] as any || 'default',
  keycloakUrl: window['env' as any]['KEYCLOAKURL' as any] || 'default',
  realm: window['env' as any]['REALM' as any] as any || 'default',
  clientid: window['env' as any]['CLIENTID' as any] as any || 'default',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
