import { TestUComponent } from './Components/test-u/test-u.component';
import { TestComponent } from './Components/test/test.component';
import { OrgComponent } from './Components/org/org.component';
import { UserComponent } from './Components/user/user.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'users', component: UserComponent },
  { path: 'organisations', component: OrgComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
