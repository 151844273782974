import { Channel } from './../../Models/channel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { StringUpdater } from 'src/app/Helpers/updaters';
import { Organisation } from 'src/app/Models/organisation';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getChannels(page: number, size: number, chanid: string, channame: string, org: string): Observable<any> {
    const url = this.apiUrl + '/api/channel/' + page + '/' + size + '/' + chanid
     + '/' + channame + '/' + org + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postChannel(channel: Channel): Observable<any> {
    return this.httpclient.post(this.apiUrl + '/api/channel', channel)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putChannel(value: StringUpdater): Observable<any> {
    return this.httpclient.put(this.apiUrl + '/api/channel/' + value.Id, value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteChannel(value: string): Observable<any> {
    return this.httpclient.delete(this.apiUrl + '/api/channel/' + value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
