import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { StringUpdater } from 'src/app/Helpers/updaters';
import { User } from 'src/app/Models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getUsers(page: number, size: number, phone: string, name: string, org: string): Observable<any> {
    const url = this.apiUrl + '/api/user/' + page + '/' + size + '/' + phone
     + '/' + name + '/' + org + '/0';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postUser(user: User): Observable<any> {
    return this.httpclient.post(this.apiUrl + '/api/user', user, {responseType: 'text'})
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putUser(value: StringUpdater): Observable<any> {
    return this.httpclient.put(this.apiUrl + '/api/user/' + 'phone', value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteUser(value: StringUpdater): Observable<any> {
    return this.httpclient.put(this.apiUrl + '/api/user/delete/phone', value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
