

<div style="margin-top: 5px; margin-left: 5px; margin-right: 5px;">
  <div style="background-color: #DCDCDC; margin-left: -10px; margin-right: -10px; margin-top: -10px; padding-left: 10px; padding-right: 10px;">
    <p style="float: left; margin-top: 8px; color: black; font-weight: bold;">
      <label style="color: black; font-size: 20px;">{{ 'GENERAL.USERS' | translate }}</label>
    </p>
    <div style="height: 45px; text-align: end; margin-top: -32px; border-bottom: solid 1px black;
                margin-left: -10px; margin-right: -10px; padding-right: 10px; padding-top: 3px; padding-bottom: -20px;
                background-color: transparent;">
      
      <!-- <button pButton type="button"
              style="padding: 3px; padding-left: 8px; padding-right: 8px; font-weight: bold;" label="+"
              class="p-button-outlined p-button-sm p-button-rounded"
              (click)="showCreateUser()"
              id="showcreateuser"></button> -->
  
      <p-button label="" icon="pi pi-plus" styleClass="p-button-sm p-button-rounded p-button-outlined"
                (click)="showCreateUser()" [style]="{'padding': '1px'}"
                id="createuser"></p-button>
    </div>
    
    <div style="margin-top: 1px; margin-bottom: 0px; padding-top: 5px; padding-left: 5px;
                padding-bottom: 0px; margin-left: -1px; margin-right: -1px; background-color: transparent; height: 45px;">
      <div style="margin-bottom: 0px;">
        <i class="pi pi-filter" style="font-size: 2rem; float: left; margin-right: 5px;"></i>
        <div class="p-formgroup-inline" style="margin-left: 30px;">
          <div class="p-field">
            <form [formGroup]="filterform" (ngSubmit)="apply()">
              <p-dropdown [options]="filters" formControlName="filter"
                          placeholder="{{ 'GENERAL.SELECTFILTER' | translate }}"
                          [showClear]="true" [style]="{'width': '225px', 'height': '35px'}"
                          (onChange)="filterSelection()" styleClass="filterStyle"
                          id="filter"></p-dropdown>
            </form>
          </div>
          <div class="p-field">
            <form [formGroup]="filterform" (ngSubmit)="apply()">
              <input type="text" pInputText placeholder="" *ngIf="!isorg && !isname" 
                    [style]="{'width': '225px'}"
                    styleClass="filterStyle" class="filterStyle p-inputtext-sm">
              <label for="namefilter" class="p-sr-only" *ngIf="isname">{{ 'GENERAL.ENTERNAME' | translate }}</label>
              <input id="namefilter" type="text" pInputText placeholder="{{ 'GENERAL.ENTERNAME' | translate }}"
                      formControlName="name" *ngIf="isname" [style]="{'width': '225px'}"
                      styleClass="filterStyle" class="filterStyle  p-inputtext-sm">
              <p-dropdown [options]="organisations" formControlName="org"
                          placeholder="{{ 'GENERAL.SELECTORGANISATION' | translate }}"
                          [showClear]="true" [style]="{'width': '225px', 'height': '35px'}"
                          *ngIf="isorg" styleClass="filterStyle"
                          id="orgsfilter"></p-dropdown>
            </form>
          </div>
          <div class="p-field">
            <button pButton pRipple type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                    class="p-button-outlined p-button-sm" (click)="apply()" id="apply"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p style="padding: 5px; margin: 0px;"></p>
  <div class="contents" id="users" #content>
    <p style="text-align: center; vertical-align: middle; font-size: 20px; margin-top: 50px;"
      *ngIf="(!users || users.length == 0) && !loading" id="nousers">{{ 'GENERAL.NOUSERS' | translate }}</p>
    <p-card [style]="{'margin-bottom': '10px'}"
           *ngFor="let user of users"
           styleClass="cardhover">
           <div style="margin-top: -15px; margin-bottom: -15px;">
            <div class="p-grid">
              <div class="p-col">
                  <div class="box">
                    <div>
                      <div class="header" style="margin: -10px;">
                        <div class="toolbar" role="banner">
                          <div style="margin-right: 5px;">
                            <img [src]="user.userImage" alt="{{ 'GENERAL.PICTURE' | translate }}" *ngIf="user.userImage"
                                 style="max-width: 70px; max-height: 70px; min-width: 70px; min-height: 70px;
                                        border-radius: 50%;" id="image{{ user.phoneNumber }}">
                            <div style="width: 70px; height: 70px; border-radius: 50%;;
                                        text-align: center; display: flex; justify-content: center; align-items: center;"
                                        *ngIf="!user.userImage" id="noimage{{ user.phoneNumber }}"
                                        [style.background]=user.col>
                              <label style="font-size: 30px; color: black; vertical-align: middle;">{{user.myImage}}</label>
                            </div>
                          </div>
                          <div class="spacer"></div> 
                          <div>
                            <div>
                              <label style="font-weight: bold;" id="firstname{{ user.phoneNumber }}">{{ user.firstName }} </label>
                              <label style="font-weight: bold;" id="lastname{{ user.phoneNumber }}">{{ user.lastName }}</label>
                              <br>
                              <div style="margin-bottom: 3px;">
                                <label id="{{ user.phoneNumber }}">{{ user.phoneNumber }}</label>
                              </div>
                              <p-tag styleClass="p-mr-2" severity="success" value="{{ 'GENERAL.UNLOCKED' | translate }}"
                                     *ngIf="user.status == 'UNLOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                     id="status{{ user.phoneNumber }}"></p-tag>
                              <p-tag severity="danger" value="{{ 'GENERAL.LOCKED' | translate }}"
                                     *ngIf="user.status == 'LOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                     id="status{{ user.phoneNumber }}"></p-tag>
                              <div class="smallscreen">
                              <div class="p-d-flex p-flex-column p-flex-md-row">
                                <div *ngFor="let item of user.orgs" style="margin-top: 5px;" class="p-mb-2 p-mr-2">
                                  <p-tag severity="info" value="{{ item.organisationName }}"
                                         [style]="{'margin-bottom': '-5px'}"></p-tag>
                                </div>
                              </div> 
                            </div>
                            </div>
                          </div>
                          <div class="spacer2"></div>
                          <div class="bigscreen">
                            <div class="p-d-flex p-flex-column p-flex-md-row">
                              <div *ngFor="let item of user.orgs" style="margin-top: 5px;" class="p-mb-2 p-mr-2">
                                <p-tag severity="info" value="{{ item.organisationName }}"></p-tag>
                              </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="p-col-fixed" style="width: 25px; text-align: center; display: flex; justify-content: center; align-items: center;">
                  <div class="box">
                    <div class="menuhover">
                      <i class="pi pi-ellipsis-v" style="font-size: 18px; font-weight: bold;
                            padding: 10px; margin-right: 0px; margin-top: 0px;" id="options{{ user.phoneNumber }}"
                        (click)="menu.toggle($event)" #menubutton></i>
                    </div>
                    <p-menu #menu [popup]="true" [model]="user.options" [style]="{'min-width': '50px'}"
                            [appendTo]="content"></p-menu>
                  </div>
              </div>
          </div>
           </div>
</p-card>
    <button pButton type="button" label="{{ 'GENERAL.MOREUSERS' | translate }}"
            *ngIf="users && users.length"
            class="p-button-outlined p-button-sm"
            (click)="loadMore()"
            [disabled]="!isLoadMore"
            id="loadMore">
    </button>
  </div>
</div>

<!-- CREATE USER -->
<p-dialog header="{{ 'GENERAL.CREATEUSER' | translate }}" [(visible)]="iscreateuser" [blockScroll]="true"
          [modal]="true" id="createuserdialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '500px', 'min-width': '500px'}"
          styleClass="createuserstyle" [focusOnShow]="false" #createuserdialog>
          <div style="padding-top: 10px">
            <div class="p-field">
              <label for="firstname">{{ 'GENERAL.FIRSTNAME' | translate }} (*)</label>
              <input id="firstname" type="username" aria-describedby="firstname-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERFIRSTNAME' | translate }}"
                      [(ngModel)]="FirstName" />
              <small id="firstname-help" class="p-error" *ngIf="firstnameerror">
                {{ 'GENERAL.ENTERFIRSTNAME' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="lastname">{{ 'GENERAL.LASTNAME' | translate }} (*)</label>
              <input id="lastname" type="username" aria-describedby="lastname-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERLASTNAME' | translate }}"
                      [(ngModel)]="LastName" />
              <small id="lastname-help" class="p-error" *ngIf="lastnameerror">
                {{ 'GENERAL.ENTERLASTNAME' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="phone">{{ 'GENERAL.PHONENUMBER' | translate }} (*)</label>
              <input id="phone" type="username" aria-describedby="phone-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERPHONENUMBER' | translate }}"
                      [(ngModel)]="Phone" />
              <small id="phone-help" class="p-error" *ngIf="phoneerror">
                {{ 'GENERAL.ENTERPHONENUMBER' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="password">{{ 'GENERAL.PASSWORD' | translate }} (*)</label>
              <input id="password" type="password" aria-describedby="password-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERPASSWORD' | translate }}"
                      [(ngModel)]="Password"
                       />
              <small id="password-help" class="p-error" *ngIf="passworderror">
                {{ 'GENERAL.ENTERPASSWORD' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="confirmpassword">{{ 'GENERAL.CONFIRMPASSWORD' | translate }} (*)</label>
              <input id="confirmpassword" type="password" aria-describedby="confirmpassword-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.CONFIRMPASSWORD' | translate }}"
                      [(ngModel)]="ConfirmPassword" />
              <small id="confirmpassword-help" class="p-error" *ngIf="confirmpassworderror">
                {{ 'GENERAL.ENTERPASSWORDCONFIRMATION' | translate }}
              </small>
              <small id="passwordmismatch-help" class="p-error" *ngIf="passwordmismatcherror">
                {{ 'GENERAL.PASSWORDSDONOMATCH' | translate }}
              </small>
            </div>
            <div>
              <input type="file" hidden (change)="onFileSelected()" #fileInput id="file" accept="image/*">
              <div class="p-field">
                <label for="picture">{{ 'GENERAL.PICTURE' | translate }}</label>
                <!-- <label for="picture">{{ 'GENERAL.PICTURE' | translate }} (*)</label> -->
                <div>
                  <button pButton type="button" (click)="fileInput.click()"
                          style="margin-top: 0px; margin-bottom: -10px; width: 100%; color: #4b4949;
                                  padding-top: 7px; background-color: lightgray; border: none;"
                          class="p-button-raised" label="{{ 'GENERAL.SELECTPICTURE' | translate }}">
                  </button>
                </div>
                <p>
                  <img [src]="pic" style="width: 50px; height: 50px; margin-right: 15px; margin-left: 10px;
                                        margin-bottom: 5px;"
                      *ngIf="pic"/>
                      <br>
                  <label *ngIf="picturename">{{ picturename }}</label>
                </p>
                <small id="picture-help" class="p-error" *ngIf="pictureerror">
                  {{ 'GENERAL.SELECTPICTURE' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div>
            <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                    style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                    (click)="cancel()"
                    [disabled]="loading"
                    id="canceluser">
            </button>
            <button pButton type="button" label="{{ 'GENERAL.CREATE' | translate }}"
                    style="margin-top: 5px;" class="p-button-raised"
                    (click)="createUser()"
                    [disabled]="loading"
                    id="createuser">
            </button>
          </div>
</p-dialog>

<!-- EDIT USER -->
<p-dialog header="{{ 'GENERAL.EDITUSER' | translate }}" [(visible)]="isedituser" [blockScroll]="true"
          [modal]="true" id="createuserdialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '500px', 'min-width': '500px'}"
          styleClass="createuserstyle" [focusOnShow]="false" #createuserdialog>
          <div style="padding-top: 10px">
            <div class="p-field">
              <label for="firstname">{{ 'GENERAL.FIRSTNAME' | translate }} (*)</label>
              <input id="firstname" type="username" aria-describedby="firstname-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERFIRSTNAME' | translate }}"
                      [(ngModel)]="FirstName" />
              <small id="firstname-help" class="p-error" *ngIf="firstnameerror">
                {{ 'GENERAL.ENTERFIRSTNAME' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="lastname">{{ 'GENERAL.LASTNAME' | translate }} (*)</label>
              <input id="lastname" type="username" aria-describedby="lastname-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERLASTNAME' | translate }}"
                      [(ngModel)]="LastName" />
              <small id="lastname-help" class="p-error" *ngIf="lastnameerror">
                {{ 'GENERAL.ENTERLASTNAME' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="phone">{{ 'GENERAL.PHONENUMBER' | translate }} (*)</label>
              <input id="phone" type="username" aria-describedby="phone-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERPHONENUMBER' | translate }}"
                      [(ngModel)]="Phone" disabled="true" />
              <small id="phone-help" class="p-error" *ngIf="phoneerror">
                {{ 'GENERAL.ENTERPHONENUMBER' | translate }}
              </small>
            </div>
            <!-- <br>
            <div class="p-field">
              <label for="password">{{ 'GENERAL.PASSWORD' | translate }} (*)</label>
              <input id="password" type="username" aria-describedby="password-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERPASSWORD' | translate }}"
                      [(ngModel)]="Password" />
              <small id="password-help" class="p-error" *ngIf="passworderror">
                {{ 'GENERAL.ENTERPASSWORD' | translate }}
              </small>
            </div>
            <br>
            <div class="p-field">
              <label for="confirmpassword">{{ 'GENERAL.CONFIRMPASSWORD' | translate }} (*)</label>
              <input id="confirmpassword" type="username" aria-describedby="confirmpassword-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.CONFIRMPASSWORD' | translate }}"
                      [(ngModel)]="Password" />
              <small id="password-help" class="p-error" *ngIf="confirmpassworderror">
                {{ 'GENERAL.ENTERPASSWORDCONFIRMATION' | translate }}
              </small>
            </div> -->
            <div>
              <input type="file" hidden (change)="onFileSelectedEdit()" #fileInput2 id="file2" accept="image/*">
              <div class="p-field">
                <label for="picture">{{ 'GENERAL.PICTURE' | translate }} (*)</label>
                <div>
                  <button pButton type="button" (click)="fileInput2.click()"
                          style="margin-top: 0px; margin-bottom: -10px; width: 100%; color: #4b4949;
                                  padding-top: 7px; background-color: lightgray; border: none;"
                          class="p-button-raised" label="{{ 'GENERAL.SELECTPICTURE' | translate }}">
                  </button>
                </div>
                <p>
                  <img [src]="pic" style="width: 50px; height: 50px; margin-right: 15px; margin-left: 10px;
                                        margin-bottom: 5px;"
                      *ngIf="pic"/>
                      <br>
                  <label *ngIf="picturename">{{ picturename }}</label>
                </p>
                <small id="picture-help" class="p-error" *ngIf="pictureerror">
                  {{ 'GENERAL.SELECTPICTURE' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div>
            <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                    style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                    (click)="cancel()"
                    [disabled]="loading"
                    id="canceluser">
            </button>
            <button pButton type="button" label="{{ 'GENERAL.SAVE' | translate }}"
                    style="margin-top: 5px;" class="p-button-raised"
                    (click)="saveUser()"
                    [disabled]="loading"
                    id="saveuser">
            </button>
          </div>
</p-dialog>

<!-- CONFIRM DIALOG -->
<p-confirmDialog id="confirm" #cd header="{{ 'GENERAL.INFO' | translate }}">
  <p-footer>
    <button type="button" id="butconfirm" pButton label="{{ 'GENERAL.CLOSEDIALOG' | translate }}" (click)="cd.accept()"></button>
</p-footer>
</p-confirmDialog>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cd" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
                <button type="button" pButton (click)="onReject()" 
                        label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                        id="rejectdelete"></button>
            </div>
              <div class="p-col-6">
                  <button type="button" pButton (click)="onConfirmDelete()"
                          label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                          id="confirmdelete"></button>
              </div>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cl" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
                <button type="button" pButton (click)="onReject()" 
                        label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                        id="rejectlock"></button>
            </div>
              <div class="p-col-6">
                  <button type="button" pButton (click)="onConfirmLock()"
                          label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                          id="confirmlock"></button>
              </div>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cu" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
                <button type="button" pButton (click)="onReject()" 
                        label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                        id="rejecetunlock"></button>
            </div>
              <div class="p-col-6">
                  <button type="button" pButton (click)="onConfirmUnlock()"
                          label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                          id="confirmunlock"></button>
              </div>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- CONFIRM MESSAGE -->
<p-toast position="center" key="cm" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div style="text-align: center;">
            <button type="button" pButton (click)="onConfirmOk()"
                    label="{{ 'GENERAL.OK' | translate }}" class="p-button-primary"
                    id="confirmmessage"></button>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- LOADER -->
<p-toast position="center" key="loader" [baseZIndex]="50000"
         closable="false" styleClass="stickytoastloader" id="loader"
         [preventOpenDuplicates]="true" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
      <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1;">
            <div class="p-text-center">
              <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
            </div>
        </div>
      </ng-template>
</p-toast>
