

<div class="wrapper" [style.height]="contentdiv" (window:resize)="onResize($event)"
      style="min-height: 500px;" id="mycontentdiv">

    <!-- CHANNELS AND ORGS -->
    <div class="x" [style.height]="contentdiv"
          style="min-height: 500px;">
      <div [class]="divname" style="height: 100%; display: flex; flex-direction: column;
            border: 1px solid transparent;">
        <div class="bshadow">
          <div class="p-card-filter">
            <div style="margin: 0px; padding: 0px;">
             <div class="headerdiv" >
               <div class="header" style="height: 30px; margin-top: -8px;">
                <div class="toolbar" role="banner">
                    <label style="font-weight: bold; font-size: 20px; margin-top: -3px;" class="wordellipsis">
                        {{ 'GENERAL.ORGANISATIONS' | translate }}
                    </label>
                    <div class="spacer2"></div>
                    <div *ngIf="showfilter">
                      <i class="pi pi-filter" style="font-size: 1.5rem;" 
                        *ngIf="isfilter" (click)="toggleFilter()" id="filteron"></i>
                      <i class="pi pi-filter-slash" style="font-size: 1.5rem;"
                          *ngIf="!isfilter" (click)="toggleFilter()" id="filteroff"></i>
                    </div>
                    <div class="spacer2"></div>
                    <!-- <button pButton type="button"
                            style="padding: 3px; padding-left: 8px; padding-right: 8px; font-weight: bold;" label="+"
                            class="p-button-outlined p-button-rounded"
                            (click)="showCreateOrg()"
                            id="showcreateorg"></button> -->
                    <!-- <button pButton type="button"
                            style="padding: 3px; padding-left: 8px; padding-right: 8px; font-weight: bold;" label="+"
                            class="p-button-outlined p-button-sm p-button-rounded"
                            (click)="showCreateOrg()"
                            id="showcreateorg"></button> -->
                    <p-button label="" icon="pi pi-plus"
                                styleClass="p-button-sm p-button-rounded p-button-outlined"
                                id="showcreateorg" (click)="showCreateOrg()"
                                [style]="{'margin-right': '0px'}"></p-button>               
                </div>
              </div>
             </div>
             <div style="margin-top: 10px; margin-left: -10px; margin-right: -10px; padding: 10px;
                         border-top: solid 1px black; padding-bottom: 0px; margin-bottom: -10px;"
                  *ngIf="isfilter">
                <div class="smallscreenfilter">
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width:50px"  id="filterlabel">
                            <div class="box">
                                <label for="filtersmall">{{ 'GENERAL.BY' | translate }}</label>
                            </div>
                        </div>
                        <div class="p-col">
                            <div class="box">
                              <form [formGroup]="filterform" (ngSubmit)="apply()">
                                <p-dropdown [options]="filters" formControlName="filter"
                                        placeholder="{{ 'GENERAL.SELECTFILTER' | translate }}"
                                        [showClear]="true" [style]="{'width': '100%'}"
                                        id="filtersmall" (onChange)="filterSelection()"
                                        styleClass="p-inputtext-sm">
                                </p-dropdown>
                              </form>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width:50px" id="filterlabel">
                            <div class="box">
                                <label for="valuesmall">{{ 'GENERAL.NAME' | translate }}</label>
                            </div>
                        </div>
                        <div class="p-col">
                            <div class="box">
                              <form [formGroup]="filterform" (ngSubmit)="apply()">
                                <input id="valuesmall" type="text" pInputText formControlName="name"
                                        style="width: 100%;" class="p-inputtext-sm"
                                        placeholder="{{ 'GENERAL.ENTERVALUE' | translate }}">
                              </form>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid p-jc-end">
                        <div style="padding: 10px;">
                            <button pButton pRipple type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                                class="p-button-outlined p-button-sm"
                                (click)="apply()" id="applysmall"></button>
                        </div>
                    </div>
                </div>
                <div class="bigscreenfilter">
                  <div class="p-grid">
                      <div class="p-col-fixed" style="width:50px" id="filterlabel">
                          <div class="box">
                              <label for="filterbig">{{ 'GENERAL.BY' | translate }}</label>
                          </div>
                      </div>
                      <div class="p-col">
                          <div class="box">
                            <form [formGroup]="filterform" (ngSubmit)="apply()">
                              <p-dropdown [options]="filters" formControlName="filter"
                                      placeholder="{{ 'GENERAL.SELECTFILTER' | translate }}"
                                      [showClear]="true" [style]="{'width': '100%'}"
                                      id="filterbig" (onChange)="filterSelection()"
                                      styleClass="p-inputtext-sm filterDrop">
                              </p-dropdown>
                            </form>
                          </div>
                      </div>
                      <div class="p-col-fixed" style="padding-right: 10px;" id="noapply">
                          <div class="box">
                              <button pButton pRipple type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                                      style="color: transparent; background-color: transparent;"
                                      class="p-button-outlined p-button-sm"></button>
                          </div>
                      </div>
                  </div>
                  <div class="p-grid">
                      <div class="p-col-fixed" style="width:50px;" id="filterlabel">
                          <div class="box">
                              <label for="valuebig">{{ 'GENERAL.NAME' | translate }}</label>
                          </div>
                      </div>
                      <div class="p-col">
                          <div class="box">
                            <form [formGroup]="filterform" (ngSubmit)="apply()">
                              <input id="valuebig" type="text" pInputText formControlName="name"
                                    style="width: 100%;" class="noapplys" class="p-inputtext-sm"
                                    placeholder="{{ 'GENERAL.ENTERVALUE' | translate }}"> 
                            </form>
                          </div>
                      </div>
                      <div class="p-col-fixed" style="padding-right: 10px">
                          <div class="box">
                              <button pButton pRipple type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                                      class="p-button-outlined p-button-sm"
                                      (click)="apply()" id="apply"></button>
                          </div>
                      </div>
                  </div>
                </div>
                <!-- <div class="bigscreenfilter">
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width:50px" id="filterlabel">
                            <div class="box">
                                <label for="filterbig">{{ 'GENERAL.BY' | translate }}</label>
                            </div>
                        </div>
                        <div class="p-col">
                            <div class="box">
                                <p-dropdown [options]="filters" [(ngModel)]="filter"
                                        placeholder="{{ 'GENERAL.SELECTFILTER' | translate }}"
                                        [showClear]="true" [style]="{'width': '100%'}"
                                        id="filterbig" (onChange)="filterSelection()">
                          </p-dropdown>
                            </div>
                        </div>
                        <div class="p-col-fixed" style="padding-right: 10px">
                            <div class="box">
                                <button pButton pRipple type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                                        style="color: transparent; background-color: transparent;"
                                        class="p-button-outlined"></button>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width:50px;" id="filterlabel">
                            <div class="box">
                                <label for="valuebig">{{ 'GENERAL.NAME' | translate }}</label>
                            </div>
                        </div>
                        <div class="p-col">
                            <div class="box">
                                <input id="valuebig" type="text" pInputText [(ngModel)]="Name"
                                        style="width: 100%;" 
                                        placeholder="{{ 'GENERAL.ENTERVALUE' | translate }}"> 
                            </div>
                        </div>
                        <div class="p-col-fixed" style="padding-right: 10px">
                            <div class="box">
                                <button pButton pRipple type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                                        class="p-button-outlined"
                                        (click)="apply()" id="apply"></button>
                            </div>
                        </div>
                    </div>
                </div> -->
             </div>
            </div>
          </div>
        </div>
        <div style="background-color: #F8F8F8; flex-grow: 1; overflow: auto; padding: 5px;">
          <div style="margin-bottom: 0px;" #content>
            <div *ngFor="let org of orgs" id="orgs" class="bigorgs">
              <div class="itemmenu" style="float: right; margin-top: 10px;">
              <i class="pi pi-ellipsis-v" style="font-size: 18px; font-weight: bold;
                    padding: 10px; margin-right: 0px; margin-top: 0px;" id="options{{ org.organisationName }}"
                (click)="menu.toggle($event)" #menubutton></i>
              </div>
              <p-menu #menu [popup]="true" [model]="org.options" [style]="{'min-width': '50px'}"
                          [appendTo]="content"></p-menu>
              <p-card [style]="{margin: '5px', 'margin-bottom': '10px',
                      'background-color': org.color}"
                    (click)="orgClicked(org)" id="{{org.organisationName}}" styleClass="hover">

                    <div>
                      <div class="p-grid">
                        <div class="p-col">
                            <div class="box">
                              <div class="header" style="margin: -5px; margin-top: -30px; margin-bottom: -30px;">
                                <div class="toolbar" role="banner">
                                  <div style="float: left;">
                                    <i [class]="org.Icon" style="font-size: 2rem; margin-top: 5px;"
                                      (click)="showChannels(org)" id="icon{{org.organisationName}}"></i>
                                  </div>
                                  <div>
                                    <p class="space">
                                      <label style="font-size: 17px; font-weight: bold;" class="pointer" id="name{{org.organisationName}}">{{ org.organisationName }}</label>
                                    </p>
                                    <p class="space">
                                      <label style="font-size: 15px;" class="label">NTM: </label>
                                      <label style="font-size: 15px;" class="pointer" id="ntmurl{{org.organisationName}}">{{ org.ntmUrl }}</label>
                                    </p>
                                    <p class="space">
                                      <label style="font-size: 15px;" class="label">SSO: </label>
                                      <label style="font-size: 15px;" class="pointer" id="ssourl{{org.organisationName}}">{{ org.ssoUrl }}</label>
                                    </p>
                                    <p class="space">
                                      <label style="font-size: 15px;" class="label">Secret: </label>
                                      <label style="font-size: 15px;" class="pointer" id="ssosecret{{org.organisationName}}">{{ org.ssoSecret }}</label>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="p-col-fixed" style="width: 25px; text-align: start; display: flex; justify-content: center; align-items: center;">
                              <div class="box">
                                <div class="itemmenu">
                                  <i class="pi pi-ellipsis-v" style="font-size: 18px; font-weight: bold;
                                        padding: 10px; margin-right: 0px; margin-top: 0px;" id="options{{ org.organisationName }}"
                                    (click)="menu.toggle($event)" #menubutton></i>
                                </div>
                                <p-menu #menu [popup]="true" [model]="org.options" [style]="{'min-width': '50px'}"
                                            [appendTo]="content"></p-menu>
                              </div>
                          </div> -->
                      </div>
                    </div>
                    
                </p-card>
                <!-- <div *ngIf="org.showchannels" style="padding-left: 25px;">
                  <p *ngIf="channels == undefined || c == null || channels.length == 0">{{ 'GENERAL.NOCHANNELS' | translate }}</p>
                <div *ngFor="let channel of channels" id="channels">
                  <p-card [style]="{margin: '5px', 'margin-bottom': '10px',
                            'background-color': channel.Color}"
                        (click)="channelClicked(channel)" 
                        styleClass="hover">
                    <div class="header" style="margin: -10px;">
                      <div class="toolbar" role="banner">
                        <div style="float: left;">
                          <img style="max-width: 75px; max-height: 100px; margin-right: 5px;"
                               [src]="channel.Image"
                               id="image{{channel.channelName}}">
                        </div>
                        <div class="spacer"></div> 
                        <div>
                          <div>
                            <label style="font-size: 17px; font-weight: bold;" class="pointer"
                                   id="{{ channel.channelName }}">
                              {{ channel.channelName }}
                            </label>
                            <br>
                            <label style="font-size: 15px; font-style: italic;"
                                   class="pointer">{{ channel.channel.channelType }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    </p-card>
                  </div>
  
                  <button pButton type="button" label="{{ 'GENERAL.LOADMOREchannelS' | translate }}"
                        style="float: right; margin-right: 5px; margin-bottom: 15px;"
                        [disabled]="!loadmorec" (click)="loadMoreC()"
                        *ngIf="channels.length" id="loadmorec"></button>
                  <br *ngIf="channels.length"><br *ngIf="channels.length">
                </div> -->
            </div>

            
            <div *ngFor="let org of orgs" id="orgs" class="smallorgs">
              <div class="itemmenu" style="float: right; margin-top: 10px;">
              <i class="pi pi-ellipsis-v" style="font-size: 18px; font-weight: bold;
                    padding: 10px; margin-right: 0px; margin-top: 0px;" id="options{{ org.organisationName }}"
                (click)="menu.toggle($event)" #menubutton></i>
              </div>
              <p-menu #menu [popup]="true" [model]="org.options" [style]="{'min-width': '50px'}"
                          [appendTo]="content"></p-menu>
              <p-card [style]="{margin: '5px', 'margin-bottom': '10px', 'background-color': org.color}"
                      id="{{org.organisationName}}" styleClass="hover"
                      (click)="orgSmallClicked(org)">

                    <div>
                      <div class="p-grid">
                        <div class="p-col">
                            <div class="box">
                              <div class="header" style="margin: -5px; margin-top: -30px; margin-bottom: -30px;">
                                <div class="toolbar" role="banner">
                                  <div style="float: left;">
                                    <i [class]="org.Icon" style="font-size: 2rem; margin-top: 5px;"
                                      (click)="showChannels(org)" id="icon{{org.organisationName}}"></i>
                                  </div>
                                  <div>
                                    <p class="space">
                                      <label style="font-size: 17px; font-weight: bold;" class="pointer">{{ org.organisationName }}</label>
                                    </p>
                                    <p class="space">
                                      <label style="font-size: 15px;" class="label">NTM: </label>
                                      <label style="font-size: 15px;" class="pointer">{{ org.ntmUrl }}</label>
                                    </p>
                                    <p class="space">
                                      <label style="font-size: 15px;" class="label">SSO: </label>
                                      <label style="font-size: 15px;" class="pointer">{{ org.ssoUrl }}</label>
                                    </p>
                                    <p class="space">
                                      <label style="font-size: 15px;" class="label">Secret: </label>
                                      <label style="font-size: 15px;" class="pointer">{{ org.ssoSecret }}</label>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="p-col-fixed" style="width: 25px; text-align: start; display: flex; justify-content: center; align-items: center;">
                              <div class="box">
                                <div class="itemmenu">
                                  <i class="pi pi-ellipsis-v" style="font-size: 18px; font-weight: bold;
                                        padding: 10px; margin-right: 0px; margin-top: 0px;" id="options{{ org.organisationName }}"
                                    (click)="menu.toggle($event)" #menubutton></i>
                                </div>
                                <p-menu #menu [popup]="true" [model]="org.options" [style]="{'min-width': '50px'}"
                                            [appendTo]="content"></p-menu>
                              </div>
                          </div> -->
                      </div>
                    </div>
                    

                
                
                </p-card>
                <!-- <div *ngIf="org.showchannels" style="padding-left: 25px;">
                  <p *ngIf="channels == undefined || c == null || channels.length == 0">{{ 'GENERAL.NOCHANNELS' | translate }}</p>
                <div *ngFor="let channel of channels" id="channels">
                  <p-card [style]="{margin: '5px', 'margin-bottom': '10px',
                            'background-color': channel.Color}"
                        (click)="channelClicked(channel)" 
                        styleClass="hover">
                    <div class="header" style="margin: -10px;">
                      <div class="toolbar" role="banner">
                        <div style="float: left;">
                          <img style="max-width: 75px; max-height: 100px; margin-right: 5px;"
                               [src]="channel.Image"
                               id="image{{channel.channelName}}">
                        </div>
                        <div class="spacer"></div> 
                        <div>
                          <div>
                            <label style="font-size: 17px; font-weight: bold;" class="pointer"
                                   id="{{ channel.channelName }}">
                              {{ channel.channelName }}
                            </label>
                            <br>
                            <label style="font-size: 15px; font-style: italic;"
                                   class="pointer">{{ channel.channel.channelType }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    </p-card>
                  </div>
  
                  <button pButton type="button" label="{{ 'GENERAL.LOADMOREchannelS' | translate }}"
                        style="float: right; margin-right: 5px; margin-bottom: 15px;"
                        [disabled]="!loadmorec" (click)="loadMoreC()"
                        *ngIf="channels.length" id="loadmorec"></button>
                  <br *ngIf="channels.length"><br *ngIf="channels.length">
                </div> -->
            </div>
        
            <button pButton type="button" label="{{ 'GENERAL.LOADMOREORGS' | translate }}"
                    style="float: left; margin-left: 5px; margin-bottom: 15px;"
                    class="p-button-outlined p-button-sm"
                    [disabled]="!loadmoreo" (click)="loadMoreO()"
                    *ngIf="orgs.length" id="loadmoreo"></button>
        
            <p *ngIf="isnoorgs" style="text-align: center; margin-top: 30px; font-size: 20px;"
                id="noorgs">{{ 'GENERAL.NOORGS' | translate }}</p>
            <br><br>
          </div>
        </div>
      </div>
    </div>
    
    <!-- DETAIL -->
    <div class="y" *ngIf="org" [style.height]="contentdiv"
          style="min-height: 500px;">
        <div style="height: 100%; display: flex; flex-direction: column;
        border: 1px solid #d3cece;">
          <div>
            <div class="p-card-filter" id="myheader">
              <div style="margin: 0px; padding: 0px;">
               <div class="headerdiv">
                 <div class="header" style="height: 25px; margin-top: -3px;">
                  <div class="toolbar" role="banner">
                      <label style="font-weight: bold; font-size: 20px; margin-top: -5px;" class="wordellipsis">
                          {{ org.organisationName }}
                      </label>
                      <div class="spacer2"></div>
                      <div class="spacer2"></div>
                      <i class="pi pi-trash"
                          style="font-size: 1.5rem; margin-right: -5px; padding: 1px;" 
                          id="deleteorg" (click)="showDelete()"></i>
                  </div>
                </div>
               </div>
              </div>
            </div>
          </div>
          <div style="padding: 10px; background-color: #F8F8F8;">
           <div id="maindata">
             <p-card styleClass="p-card-shadow">
              <ng-template pTemplate="header">
                <div style="margin: 10px; margin-bottom: 0px; height: 25px;">
                  <label style="font-weight: bold;">{{ 'GENERAL.MAINDATA' | translate }}</label>
                  <i class="pi pi-pencil" 
                     style="font-size: 1.5rem; margin-right: 5px; padding: 1px; float: right;" 
                      id="editorg" (click)="showEditOrg()"></i>
                </div>
              </ng-template>
               <div style="margin: -10px; margin-top: -30px; margin-bottom: -10px;">

                <div class="p-grid" style="margin-bottom: -15px;">
                  <br>
                  <div class="p-col-fixed" style="width:100px;">
                      <div class="box" style="text-align: right; color: gray;">{{ 'GENERAL.NAME' | translate }}</div>
                  </div>
                  <div class="p-col">
                      <div class="box"><label id="infoorgname" style="color: black;">{{org.organisationName}}</label></div>
                  </div>
                </div>
                <div class="p-grid" style="margin-bottom: -15px;">
                  <div class="p-col-fixed" style="width:100px;">
                      <div class="box" style="text-align: right; color: gray;">{{ 'GENERAL.NTMURL' | translate }}</div>
                  </div>
                  <div class="p-col">
                      <div class="box"><label id="infontmurl" style="color: black;">{{org.ntmUrl}}</label></div>
                  </div>
                </div>
                <div class="p-grid" style="margin-bottom: -15px;">
                  <div class="p-col-fixed" style="width:100px">
                      <div class="box" style="text-align: right; color: gray;">{{ 'GENERAL.SSOURL' | translate }}</div>
                  </div>
                  <div class="p-col">
                      <div class="box"><label id="infossourl" style="color: black;">{{org.ssoUrl}}</label></div>
                  </div>
                </div>
                <div class="p-grid" style="margin-bottom: -25px;">
                  <div class="p-col-fixed" style="width:100px">
                      <div class="box" style="text-align: right; color: gray;">{{ 'GENERAL.SSOSECRET' | translate }}</div>
                  </div>
                  <div class="p-col">
                      <div class="box"><label id="infossosecret" style="color: black;">{{org.ssoSecret}}</label></div>
                  </div>
                </div>

                <!-- <p class="info" style="vertical-align: middle;"><label class="infotitle">{{ 'GENERAL.NAME' | translate }}</label><label class="infodetail" id="infoorgname">{{org.organisationName}}</label></p>
                <p class="info"><label class="infotitle">{{ 'GENERAL.NTMURL' | translate }}</label><label class="infodetail" id="infontmurl">{{org.ntmUrl}}</label></p>
                <p class="info"><label class="infotitle">{{ 'GENERAL.SSOURL' | translate }}</label><label class="infodetail" id="infossourl">{{org.ssoUrl}}</label></p>
                <p class="info"><label class="infotitle">{{ 'GENERAL.SSOSECRET' | translate }}</label><label class="infodetail" id="infossosecret">{{org.ssoSecret}}</label></p> -->
               </div>
             </p-card>
           </div>
          </div>
          <div style="background-color: #F8F8F8; flex-grow: 1; overflow: auto; padding: 0px;"
                #orgvalues id="orgvalues">
            <div style="margin: 0px;" *ngIf="showdivs">
              <div style="padding: 5px;">
                <div class="p-grid" style="margin: 0px; padding: 5px; margin-top: -10px;">
                 <div class="p-col" style="margin: 0px; padding: 0px; margin-right: 5px;">
                   <p-card [style]="{'width': '100%'}" styleClass="p-card-shadow">
                       <!-- <ng-template pTemplate="header">
                         <div style="margin: 10px; margin-left: 5px; margin-right: 0px;">
                           <label style="font-weight: bold;">{{ 'GENERAL.ORGUSERS' | translate }}</label>
                           <button pButton type="button"
                                  style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                                  margin-right: 5px;" label="{{ 'GENERAL.ADD' | translate }}"
                                     class="p-button-sm p-button-outlined"
                                     (click)="showAddOrgUser()" id="showaddorguser">
                           </button>
                         </div>
                       </ng-template> -->
                       <ng-template pTemplate="header">
                         <div style="padding-left: 5px; padding-right: 5px; margin-bottom: -5px;"
                              class="p-shadow-3">
                           <div class="p-grid p-jc-between">
                             <div class="p-col-8" style="padding-top: 1.3rem;">
                                 <div class="box">
                                   <label style="font-weight: bold;">{{ 'GENERAL.ORGUSERS' | translate }}</label>
                                 </div>
                             </div>
                             <div class="p-col-2" style="text-align: end; padding-top: 1rem;">
                                 <div class="box">
                                   <button pButton type="button"
                                           style="padding: 1px; padding-left: 5px; padding-right: 5px;" label="{{ 'GENERAL.ADD' | translate }}"
                                               class="p-button-sm p-button-outlined but"
                                               (click)="showAddOrgUser()" id="showaddorguser">
                                     </button>
                                 </div>
                             </div>
                           </div>
                         </div>
                       </ng-template>
                       <div style="margin: -10px; margin-top: -25px; margin-bottom: -30px;"
                            [style.height]="divheight">
                         <p-scrollPanel [style]="{'height': '100%', 'width': '100%'}"
                                        styleClass="custombar">
                           <div>
                             <div style="text-align: center; padding-top: 25px;"
                                  *ngIf="(!orgusers || orgusers.length == 0) && !loading">
                               <label *ngIf="(!orgusers || orgusers.length == 0) && !loading" id="noorgusers">{{ 'GENERAL.NOUSERS' | translate }}</label>
                             </div>
                             <li class="listitem" *ngFor="let orguser of orgusers" id="orgusers">
                              <p-card styleClass="hover" [style]="{'border': '1px solid lightgray',
                                                  'background-color': orguser.color}"
                                      (click)="orgUserClicked(orguser)">
                                <div style="margin: -10px; margin-top: -25px; margin-bottom: -25px;">
                                  <div class="delete" style="margin-top: 0px; float: right;">
                                    <i class="pi pi-trash" 
                                        style="font-size: 1rem; padding: 5px; margin-right: -5px;" 
                                        id="deleteuser{{ orguser.phoneNumber }}"
                                        (click)="showDeleteOrgUser(orguser)"></i> 
                                  </div>
                                  <div class="header" style="margin: 0px; margin-top: 0px; margin-bottom: 5px;">
                                    <div class="toolbar" role="banner">
                                      <div style="margin-right: 5px;">
                                        <img [src]="orguser.user.userImage" alt="{{ 'GENERAL.PICTURE' | translate }}" *ngIf="orguser.user.userImage"
                                             style="max-width: 60px; max-height: 60px; min-width: 60px; min-height: 60px;
                                                    border-radius: 50%;" id="image{{ orguser.user.phoneNumber }}">
                                        <div style="width: 60px; height: 60px; border-radius: 50%;;
                                                    text-align: center; display: flex; justify-content: center; align-items: center;"
                                                    *ngIf="!orguser.user.userImage" id="noimage{{ orguser.user.phoneNumber }}"
                                                    [style.background]=orguser.user.col>
                                          <label style="font-size: 20px; color: black; vertical-align: middle;">{{orguser.user.myImage}}</label>
                                        </div>
                                      </div>
                                      <div class="spacer"></div> 
                                      <div>
                                        <div>
                                          <label style="font-weight: bold;" id="firstname{{ orguser.user.phoneNumber }}">{{ orguser.user.firstName }} </label>
                                          <label style="font-weight: bold;" id="lastname{{ orguser.user.phoneNumber }}">{{ orguser.user.lastName }}</label>
                                          <br>
                                          <div style="margin-bottom: 3px;">
                                            <label id="{{ orguser.user.phoneNumber }}">{{ orguser.user.phoneNumber }}</label>
                                          </div>
                                          <div style="margin-bottom: 3px; margin-top: -3px;">
                                            <label style="color: gray;">ID: </label>
                                            <label id="uid{{ orguser.user.phoneNumber }}">{{ orguser.userId }}</label>
                                          </div>
                                          <div class="smallscreen">
                                            <p-tag styleClass="p-mr-2" severity="success" value="{{ 'GENERAL.UNLOCKED' | translate }}"
                                                 *ngIf="orguser.user.status == 'UNLOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                                 id="status{{ orguser.user.phoneNumber }}"></p-tag>
                                            <p-tag severity="danger" value="{{ 'GENERAL.LOCKED' | translate }}"
                                                 *ngIf="orguser.user.status == 'LOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                                 id="status{{ orguser.user.phoneNumber }}"></p-tag>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="spacer4"></div>
                                      <div class="bigscreen">
                                        <p-tag styleClass="p-mr-2" severity="success" value="{{ 'GENERAL.UNLOCKED' | translate }}"
                                             *ngIf="orguser.user.status == 'UNLOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                             id="status{{ orguser.user.phoneNumber }}"></p-tag>
                                        <p-tag severity="danger" value="{{ 'GENERAL.LOCKED' | translate }}"
                                             *ngIf="orguser.user.status == 'LOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                             id="status{{ orguser.user.phoneNumber }}"></p-tag>
                                      </div>
                                      <!-- <div class="spacer2"></div>
                                      <div class="delete" style="margin-top: 0px;">
                                        <i class="pi pi-trash" 
                                            style="font-size: 1rem; padding: 5px; margin-right: -5px;" 
                                            id="deleteuser{{ orguser.phoneNumber }}"
                                            (click)="showDeleteOrgUser(orguser)"></i> 
                                      </div> -->
                                    </div>
                                  </div>
                                </div>
                              </p-card>
                             </li>
                           </div>
                         </p-scrollPanel>
                       </div>
                   </p-card>
                 </div>
                 <div class="p-col" style="margin: 0px; padding: 0px; margin-left: 5px;">
                   <p-card [style]="{'width': '100%', 'height': '100%'}" styleClass="p-card-shadow">
                       <!-- <ng-template pTemplate="header">
                         <div style="margin: 10px; margin-left: 5px; margin-right: 0px;">
                           <label style="font-weight: bold;">{{ 'GENERAL.ORGCHANELS' | translate }}</label>
                           <button pButton type="button"
                                  style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                                  margin-right: 5px;" label="{{ 'GENERAL.ADD' | translate }}"
                                     class="p-button-sm p-button-outlined"
                                     (click)="showAddOrgChannel()" id="showaddorgchannel">
                           </button>
                         </div>
                       </ng-template> -->
                       <ng-template pTemplate="header">
                        <div style="padding-left: 5px; padding-right: 5px; margin-bottom: -5px;"
                             class="p-shadow-3">
                          <div class="p-grid p-jc-between">
                            <div class="p-col-8" style="padding-top: 1.3rem;">
                                <div class="box">
                                  <label style="font-weight: bold;">{{ 'GENERAL.ORGCHANELS' | translate }}</label>
                                </div>
                            </div>
                            <div class="p-col-2" style="text-align: end; padding-top: 1rem;">
                                <div class="box">
                                  <button pButton type="button"
                                          style="padding: 1px; padding-left: 5px; padding-right: 5px;" label="{{ 'GENERAL.ADD' | translate }}"
                                              class="p-button-sm p-button-outlined but"
                                              (click)="showAddOrgChannel()" id="showaddorgchannel">
                                    </button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                       <div style="margin: -10px; margin-top: -25px; margin-bottom: -30px;"
                            [style.height]="divheight2">
                         <p-scrollPanel [style]="{'height': '100%', 'width': '100%'}" styleClass="custombar">
                           <div>
                            <div style="text-align: center; padding-top: 25px;"
                            *ngIf="(!ochannels || ochannels.length == 0) && !loading">
                              <label *ngIf="(!ochannels || ochannels.length == 0) && !loading" id="noorgchannels">{{ 'GENERAL.NOCHANNELS' | translate }}</label>
                            </div>
                             <li class="listitem" *ngFor="let ochan of ochannels" id="orgchannels">
                               <!-- {{ochan.channelName}} -->
                              <div class="menuhover" style="float: right; margin-top:5px; margin-right: 5px;">
                                <i class="pi pi-ellipsis-v" style="font-size: 18px; font-weight: bold;
                                      padding: 10px; margin-right: -10px; margin-top: 0px;"
                                   id="options{{ ochan.channelName }}"
                                  (click)="menu.toggle($event)" #menubutton></i>
                                </div>
                                <p-menu #menu [popup]="true" [model]="ochan.options" [style]="{'min-width': '50px'}"
                                      [appendTo]="content"></p-menu>
                               <p-card styleClass="hover" [style]="{'border': '1px solid lightgray',
                              'background-color': ochan.color}" (click)="channelClicked(ochan)">
                                <div style="margin: -10px; margin-top: -25px; margin-bottom: -25px;">
                                  <div class="header" style="margin: 0px; margin-top: 0px; margin-bottom: 5px;">
                                    <div class="toolbar" role="banner">
                                      <div style="margin-right: 5px;">
                                        <p-avatar *ngIf="ochan.chanImage" [image]="ochan.chanImage" styleClass="p-mr-2"
                                                  size="large" [style]="{'background-color': ochan.col}"></p-avatar>
                                        <p-avatar *ngIf="!ochan.chanImage" label="{{ochan.myImage}}" styleClass="p-mr-2"
                                                  size="large" [style]="{'background-color': ochan.col}"></p-avatar>
                                        <!-- <img [src]="ochan.chanImage" alt="{{ 'GENERAL.PICTURE' | translate }}" *ngIf="ochan.chanImage"
                                             style="max-width: 60px; max-height: 60px; min-width: 60px; min-height: 60px;
                                                    border-radius: 50%;" id="image{{ ochan.channelName }}"> -->
                                        <!-- <div style="width: 60px; height: 60px; border-radius: 50%;;
                                                    text-align: center; display: flex; justify-content: center; align-items: center;"
                                                    *ngIf="!ochan.chanImage" id="noimage{{ ochan.channelName }}"
                                                    [style.background]=ochan.col>
                                          <label style="font-size: 20px; color: black; vertical-align: middle;">{{ochan.myImage}}</label>
                                        </div> -->
                                      </div>
                                      <div class="spacer"></div> 
                                      <div>
                                        <div>
                                          <label style="font-weight: bold;" id="name{{ ochan.channelName }}">{{ ochan.channelName }} </label>
                                          <br>
                                          <div style="margin-bottom: 3px;">
                                            <label style="color: gray;">{{ 'GENERAL.USERS' | translate }}: </label>
                                            <label id="usercount{{ ochan.channelName }}">{{ ochan.userCount }}</label>
                                          </div>
                                          <div style="margin-bottom: 3px;">
                                            <label style="color: gray;">{{ 'GENERAL.TYPES' | translate }}: </label>
                                            <label id="typecount{{ ochan.channelName }}">{{ ochan.typeCount }}</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                               </p-card>
                              </li>
                           </div>
                         </p-scrollPanel>
                       </div>
                   </p-card>
                 </div>
            </div>
          </div>
         </div>
           </div>
  
        </div>
    </div>
</div>


<!-- CREATE ORGANISATION -->
<p-dialog header="{{ 'GENERAL.CREATEORG' | translate }}" [(visible)]="iscreateorg"
          [blockScroll]="true"
          [modal]="true" id="createorgdialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '500px', 'min-width': '500px'}"
          styleClass="createorgstyle" [focusOnShow]="false" #createorgdialog>
          <div style="padding-top: 10px">
            <div class="p-field">
              <label for="orgname">{{ 'GENERAL.ORGANISATIONNAME' | translate }} (*)</label>
              <input id="orgname" type="username" aria-describedby="orgname-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERORGANISATIONNAME' | translate }}"
                      [(ngModel)]="OrgName" />
              <small id="orgname-help" class="p-error" *ngIf="orgnameerror">
                {{ 'GENERAL.ENTERORGANISATIONNAME' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="ntmurl">{{ 'GENERAL.NTMURL' | translate }} (*)</label>
              <input id="ntmurl" type="username" aria-describedby="ntmurl-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERNTMURL' | translate }}"
                      [(ngModel)]="NtmUrl" />
              <small id="ntmurl-help" class="p-error" *ngIf="ntmurlerror">
                {{ 'GENERAL.ENTERNTMURL' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="ssourl">{{ 'GENERAL.SSOURL' | translate }} (*)</label>
              <input id="ssourl" type="username" aria-describedby="ssourl-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERSSOURL' | translate }}"
                      [(ngModel)]="SsoUrl" />
              <small id="ssourl-help" class="p-error" *ngIf="ssourlerror">
                {{ 'GENERAL.ENTERSSOURL' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="ssosecret">{{ 'GENERAL.SSOSECRET' | translate }} (*)</label>
              <input id="ssosecret" type="username" aria-describedby="ssosecret-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERSSOSECRET' | translate }}"
                      [(ngModel)]="SsoSecret"
                       />
              <small id="ssosecret-help" class="p-error" *ngIf="ssosecreterror">
                {{ 'GENERAL.ENTERSSOSECRET' | translate }}
              </small>
            </div>
          </div>
          <div>
            <div class="p-grid p-jc-end" style="text-align: right;">
              <div class="p-col-12">
                  <div class="box">
                    <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                            style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                            (click)="cancel()"
                            [disabled]="loading"
                            id="cancelorg">
                    </button>
                    <button pButton type="button" label="{{ 'GENERAL.CREATE' | translate }}"
                            style="margin-top: 5px;" class="p-button-raised"
                            (click)="createOrg()"
                            [disabled]="loading"
                            id="createorg">
                    </button>
                  </div>
              </div>
            </div>
          </div>
</p-dialog>

<!-- EDIT ORGANISATION -->
<p-dialog header="{{ 'GENERAL.EDITORG' | translate }}" [(visible)]="iseditorg"
          [blockScroll]="true"
          [modal]="true" id="editorgdialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '500px', 'min-width': '500px'}"
          styleClass="createorgstyle" [focusOnShow]="false" #editorgdialog>
          <div style="padding-top: 10px">
            <div class="p-field">
              <label for="orgname">{{ 'GENERAL.ORGANISATIONNAME' | translate }} (*)</label>
              <input id="orgname" type="username" aria-describedby="orgname-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERORGANISATIONNAME' | translate }}"
                      [(ngModel)]="OrgName" disabled="true"/>
              <small id="orgname-help" class="p-error" *ngIf="orgnameerror">
                {{ 'GENERAL.ENTERORGANISATIONNAME' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="ntmurl">{{ 'GENERAL.NTMURL' | translate }} (*)</label>
              <input id="ntmurl" type="username" aria-describedby="ntmurl-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERNTMURL' | translate }}"
                      [(ngModel)]="NtmUrl" />
              <small id="ntmurl-help" class="p-error" *ngIf="ntmurlerror">
                {{ 'GENERAL.ENTERNTMURL' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="ssourl">{{ 'GENERAL.SSOURL' | translate }} (*)</label>
              <input id="ssourl" type="username" aria-describedby="ssourl-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERSSOURL' | translate }}"
                      [(ngModel)]="SsoUrl" />
              <small id="ssourl-help" class="p-error" *ngIf="ssourlerror">
                {{ 'GENERAL.ENTERSSOURL' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="ssosecret">{{ 'GENERAL.SSOSECRET' | translate }} (*)</label>
              <input id="ssosecret" type="username" aria-describedby="ssosecret-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERSSOSECRET' | translate }}"
                      [(ngModel)]="SsoSecret"
                       />
              <small id="ssosecret-help" class="p-error" *ngIf="ssosecreterror">
                {{ 'GENERAL.ENTERSSOSECRET' | translate }}
              </small>
            </div>
          </div>
          <div>
            <div class="p-grid p-jc-end" style="text-align: right;">
              <div class="p-col-12">
                  <div class="box">
                    <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                            style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                            (click)="cancel()"
                            [disabled]="loading"
                            id="cancelorg">
                    </button>
                    <button pButton type="button" label="{{ 'GENERAL.SAVE' | translate }}"
                            style="margin-top: 5px;" class="p-button-raised"
                            (click)="saveOrg()"
                            [disabled]="loading"
                            id="saveorg">
                    </button>
                  </div>
              </div>
            </div>
          </div>
</p-dialog>

<!-- ADD ORGANISATION USER -->
<p-dialog header="{{ 'GENERAL.ADDORGUSER' | translate }}" [(visible)]="isaddorguser"
          [blockScroll]="true"
          [modal]="true" id="addorguserdialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '500px', 'min-width': '500px'}"
          styleClass="createorgstyle" [focusOnShow]="false" #addorguserdialog>
          <div style="padding-top: 10px">
            <div class="p-field">
              <label for="user">{{ 'GENERAL.USER' | translate }} (*)</label>
              <p-dropdown [options]="users" [(ngModel)]="user"
                          placeholder="{{ 'GENERAL.SELECTUSER' | translate }}"
                          [showClear]="true" [style]="{'width': '100%'}"
                          id="user" [appendTo]="addorguserdialog">
                <ng-template pTemplate="selectedItem">
                  <div *ngIf="user">
                      <div>{{user.firstName}} {{user.lastName}} ({{user.phoneNumber}})</div>
                  </div>
                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div>
                        <div>{{user.label}}</div>
                        <div>{{user.value.phoneNumber}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
              <small id="user-help" class="p-error" *ngIf="usererror">
                {{ 'GENERAL.SELECTUSER' | translate }}
              </small>
            </div>
            <div class="p-field">
              <label for="uids">{{ 'GENERAL.USERIDINORG' | translate }} (*)</label>
              <!-- <input id="uid" type="username" aria-describedby="uid-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERUSERID' | translate }}"
                      [(ngModel)]="Uid" /> -->
              <p-inputNumber id="uids" mode="decimal" inputId="uid"
                             [useGrouping]="false" [style]="{'width': '100%'}" aria-describedby="uid-help"
                             placeholder="{{ 'GENERAL.ENTERUSERID' | translate }}"
                             [(ngModel)]="Uid" [maxlength]="19">
              </p-inputNumber>
              <small id="uid-help" class="p-error" *ngIf="uiderror">
                {{ 'GENERAL.ENTERUSERID' | translate }}
              </small>
            </div>
          </div>
          <div>
            <div class="p-grid p-jc-end" style="text-align: right;">
              <div class="p-col-12">
                  <div class="box">
                    <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                            style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                            (click)="cancel()"
                            [disabled]="loading"
                            id="cancelorguser">
                    </button>
                    <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                            style="margin-top: 5px;" class="p-button-raised"
                            (click)="addOrgUser()"
                            [disabled]="loading"
                            id="addorguser">
                    </button>
                  </div>
              </div>
            </div>
          </div>
</p-dialog>

<!-- CREATE ORGANISATION CHANNEL -->
<p-dialog header="{{ 'GENERAL.CREATEORGANISATIONCHANNEL' | translate }}" [(visible)]="isaddorgchannel"
          [blockScroll]="true"
          [modal]="true" id="createorgchanneldialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '500px', 'min-width': '500px'}"
          styleClass="createorgstyle" [focusOnShow]="false" #createorgchanneldialog>
          <div style="padding-top: 10px">
            <div class="p-field">
              <label for="channame">{{ 'GENERAL.CHANNELNAME' | translate }} (*)</label>
              <input id="channame" type="username" aria-describedby="channame-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERCHANNELNAME' | translate }}"
                      [(ngModel)]="ChannelName" />
              <small id="channame-help" class="p-error" *ngIf="channelnameerror">
                {{ 'GENERAL.ENTERCHANNELNAME' | translate }}
              </small>
            </div>
            <div>
              <input type="file" hidden (change)="onFileSelected()" #fileInput id="file" accept="image/*">
              <div class="p-field">
                <label for="picture">{{ 'GENERAL.CHANNELICON' | translate }}</label>
                <!-- <label for="picture">{{ 'GENERAL.PICTURE' | translate }} (*)</label> -->
                <div>
                  <button pButton type="button" (click)="fileInput.click()"
                          style="margin-top: 0px; margin-bottom: -10px; width: 100%; color: #4b4949;
                                  padding-top: 7px; background-color: lightgray; border: none;"
                          class="p-button-raised" label="{{ 'GENERAL.SELECTICON' | translate }}">
                  </button>
                </div>
                <p *ngIf="icon">
                  <p-avatar [image]="icon" styleClass="p-mr-2" size="large"></p-avatar>
                  <!-- <img [src]="icon" style="width: 50px; height: 50px; margin-right: 15px; margin-left: 10px;
                                        margin-bottom: 5px;"
                      *ngIf="icon"/> -->
                      <!-- <br> -->
                  <label *ngIf="iconname">{{ iconname }}</label>
                </p>
                <small id="icon-help" class="p-error" *ngIf="channeliconerror">
                  {{ 'GENERAL.SELECTICON' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div>
            <div class="p-grid p-jc-end" style="text-align: right;">
              <div class="p-col-12">
                  <div class="box">
                    <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                            style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                            (click)="cancel()"
                            [disabled]="loading"
                            id="cancelorgchannel">
                    </button>
                    <button pButton type="button" label="{{ 'GENERAL.CREATE' | translate }}"
                            style="margin-top: 5px;" class="p-button-raised"
                            (click)="createOrgChannel()"
                            [disabled]="loading"
                            id="createorgchannel">
                    </button>
                  </div>
              </div>
            </div>
          </div>
</p-dialog>

<!-- EDIT ORGANISATION CHANNEL -->
<p-dialog header="{{ 'GENERAL.EDITORGANISATIONCHANNEL' | translate }}" [(visible)]="iseditorgchannel"
          [blockScroll]="true"
          [modal]="true" id="editorgchanneldialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '500px', 'min-width': '500px'}"
          styleClass="createorgstyle" [focusOnShow]="false" #editorgchanneldialog>
          <div style="padding-top: 10px">
            <div class="p-field">
              <label for="channame">{{ 'GENERAL.CHANNELNAME' | translate }} (*)</label>
              <input id="channame" type="username" aria-describedby="channame-help"
                      pInputText style="width: 100%;"
                      placeholder="{{ 'GENERAL.ENTERCHANNELNAME' | translate }}"
                      [(ngModel)]="ChannelName" />
              <small id="channame-help" class="p-error" *ngIf="channelnameerror">
                {{ 'GENERAL.ENTERCHANNELNAME' | translate }}
              </small>
            </div>
            <div>
              <input type="file" hidden (change)="onFileSelectedEdit()" #fileInput2 id="file2" accept="image/*">
              <div class="p-field">
                <label for="picture">{{ 'GENERAL.CHANNELICON' | translate }}</label>
                <div>
                  <button pButton type="button" (click)="fileInput2.click()"
                          style="margin-top: 0px; margin-bottom: -10px; width: 100%; color: #4b4949;
                                  padding-top: 7px; background-color: lightgray; border: none;"
                          class="p-button-raised" label="{{ 'GENERAL.SELECTICON' | translate }}">
                  </button>
                </div>
                <p *ngIf="icon">
                  <p-avatar [image]="icon" styleClass="p-mr-2" size="large"></p-avatar>
                  <!-- <img [src]="icon" style="width: 50px; height: 50px; margin-right: 15px; margin-left: 10px;
                                        margin-bottom: 5px;"
                      *ngIf="icon"/> -->
                      <br *ngIf="iconname">
                  <label *ngIf="iconname">{{ iconname }}</label>
                </p>
                <small id="icon-help" class="p-error" *ngIf="channeliconerror">
                  {{ 'GENERAL.SELECTICON' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div>
            <div class="p-grid p-jc-end" style="text-align: right;">
              <div class="p-col-12">
                  <div class="box">
                    <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                            style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                            (click)="cancel()"
                            [disabled]="loading"
                            id="cancelorgchannel">
                    </button>
                    <button pButton type="button" label="{{ 'GENERAL.SAVE' | translate }}"
                            style="margin-top: 5px;" class="p-button-raised"
                            (click)="saveOrgChannel()"
                            [disabled]="loading"
                            id="saveorgchannel">
                    </button>
                  </div>
              </div>
            </div>
          </div>
</p-dialog>

<!-- CHANNEL TASK TYPES -->
<p-dialog header="{{chanheader}}" [(visible)]="ischantasktype"
          [blockScroll]="true"
          [modal]="true" id="channeltasktypedialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '500px', 'min-width': '500px'}"
          styleClass="createorgstyle" [focusOnShow]="false" #channeltasktype>
          <div>
            <p-card styleClass="borderLine">
              <ng-template pTemplate="header">
                <div style="padding-left: 10px; padding-right: 10px; margin-bottom: -5px;"
                     class="p-shadow-3">
                  <div class="p-grid p-jc-between">
                    <div class="p-col-8" style="padding-top: 1.3rem;">
                        <div class="box">
                          <label style="font-weight: bold;">{{ 'GENERAL.CHANNELTASKTYPES' | translate }}</label>
                        </div>
                    </div>
                    <div class="p-col-2" style="text-align: end; padding-top: 1rem;">
                        <div class="box">
                          <button pButton type="button"
                                  style="padding: 1px; padding-left: 5px; padding-right: 5px;" label="{{ 'GENERAL.ADD' | translate }}"
                                      class="p-button-sm p-button-outlined but"
                                      (click)="showAddTaskType()" id="showaddtasktype">
                            </button>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <div style="text-align: center; padding-top: 10px;"
                    *ngIf="(!chantasktypes || chantasktypes.length == 0) && !loading">
                <label id="notasktypes">{{ 'GENERAL.NOTASKTYPES' | translate }}</label>
              </div>
              <div style="margin: -17px; margin-top: -25px; margin-bottom: -25px;">
                <p-scrollPanel [style]="{'height': '300px', 'width': '100%'}"
                                styleClass="custombar addheight">
                  <li class="listitems listitem" *ngFor="let type of chantasktypes" id="chantasktypes"
                      style="padding-left: 10px; padding-right: 10px;"
                      [style.background]="type.color" (click)="chanTaskTypeClicked(type)">
                    <div class="header">
                      <div class="toolbar" role="banner">
                        <label style="font-size: 15px; font-weight: 600;" id="{{type.taskTypeId}}">
                        {{ type.taskTypeId }}
                        </label>
                        <label style="font-size: 15px; font-weight: 600; margin-left: 15px;"
                                *ngIf="type.isDefault == 'Y'">
                          ({{ 'GENERAL.DEFAULT' | translate }})
                          </label>
                        <div class="spacer2"></div>
                        <div class="delete" style="margin-top: 0px;">
                          <i class="pi pi-trash" 
                              style="font-size: 1rem; padding: 5px; margin-right: -5px;" 
                              id="deletetype{{ type.taskTypeId }}"
                              (click)="showDeleteChanTaskType(type)"></i> 
                        </div>             
                      </div>
                    </div>
                  </li>
                </p-scrollPanel>
              </div>
            </p-card>
          </div>
</p-dialog>

<!-- ADD CHANNEL TASK TYPE -->
<p-dialog header="{{ 'GENERAL.ADDCHANTASKTYPE' | translate }}" [(visible)]="isaddchanneltype"
          [blockScroll]="true"
          [modal]="true" id="addchantasktypedialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '400px', 'min-width': '400px'}"
          styleClass="createorgstyle" [focusOnShow]="false" #addchantasktypedialog>
          <div style="padding-top: 10px">
            <div class="p-field">
              <label for="typeids">{{ 'GENERAL.TASKTYPEID' | translate }} (*)</label>
              <p-inputNumber id="typeids" mode="decimal" inputId="typeid"
                             [useGrouping]="false" [style]="{'width': '100%'}" aria-describedby="typeid-help"
                             placeholder="{{ 'GENERAL.ENTERUSERID' | translate }}"
                             [(ngModel)]="TaskTypeId" [maxlength]="19">
              </p-inputNumber>
              <small id="typeid-help" class="p-error" *ngIf="typeiderror">
                {{ 'GENERAL.ENTERTASKTYPEID' | translate }}
              </small>
            </div>
            <div class="p-field-checkbox">
              <p-checkbox [(ngModel)]="isdefaulttasktype" [binary]="true" inputId="defaulttasktype"
                          id="defaulttasktype"></p-checkbox>
              <label for="defaulttasktype">{{ 'GENERAL.DEFAULT' | translate }}</label>
            </div>
          </div>
          <div>
            <div class="p-grid p-jc-end" style="text-align: right;">
              <div class="p-col-12">
                  <div class="box">
                    <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                            style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                            (click)="cancel()"
                            [disabled]="loading"
                            id="cancelchantasktype">
                    </button>
                    <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                            style="margin-top: 5px;" class="p-button-raised"
                            (click)="addChanTaskType()"
                            [disabled]="loading"
                            id="addchantasktype">
                    </button>
                  </div>
              </div>
            </div>
          </div>
</p-dialog>

<!-- CHANNEL USERS -->
<p-dialog header="{{chanheader}}" [(visible)]="ischanuser"
          [blockScroll]="true"
          [modal]="true" id="channeluserdialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '500px', 'min-width': '500px'}"
          styleClass="createorgstyle" [focusOnShow]="false" #channeluser>
          <div>
            <p-card styleClass="borderLine">
              <ng-template pTemplate="header">
                <div style="padding-left: 5px; padding-right: 5px; margin-bottom: -5px;"
                     class="p-shadow-3">
                  <div class="p-grid p-jc-between">
                    <div class="p-col-8" style="padding-top: 1.3rem;">
                        <div class="box">
                          <label style="font-weight: bold;">{{ 'GENERAL.CHANNELUSERS' | translate }}</label>
                        </div>
                    </div>
                    <div class="p-col-2" style="text-align: end; padding-top: 1rem;">
                        <div class="box">
                          <button pButton type="button"
                                  style="padding: 1px; padding-left: 5px; padding-right: 5px;" label="{{ 'GENERAL.ADD' | translate }}"
                                      class="p-button-sm p-button-outlined but"
                                      (click)="showAddChanUser()" id="showaddchanuser">
                            </button>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <div style="text-align: center; padding-top: 10px;"
                    *ngIf="(!chanusers || chanusers.length == 0) && !loading">
                <label id="nochanusers">{{ 'GENERAL.NOUSERS' | translate }}</label>
              </div>
              <div style="margin: -10px; margin-top: -25px; margin-bottom: -25px;">
                <p-scrollPanel [style]="{'height': '300px', 'width': '100%'}"
                                styleClass="custombar addheight">
                  <li class="listitem" *ngFor="let usr of chanusers" id="chanusers">
                    <p-card styleClass="hover" [style]="{'border': '1px solid lightgray',
                            'background-color': usr.color}" (click)="chanUserClicked(usr)">
                      <div style="margin: -10px; margin-top: -25px; margin-bottom: -25px;">
                        <div class="delete" style="margin-top: 0px; float: right;">
                          <i class="pi pi-trash" 
                              style="font-size: 1rem; padding: 5px; margin-right: -5px;" 
                              id="deleteuser{{ usr.phoneNumber }}"
                              (click)="showDeleteChanUser(usr)"></i> 
                        </div>
                        <div class="header" style="margin: 0px; margin-top: 0px; margin-bottom: 5px;">
                          <div class="toolbar" role="banner">
                            <div style="margin-right: 5px;">
                              <img [src]="usr.user.userImage" alt="{{ 'GENERAL.PICTURE' | translate }}" *ngIf="usr.user.userImage"
                                  style="max-width: 60px; max-height: 60px; min-width: 60px; min-height: 60px;
                                          border-radius: 50%;" id="image{{ usr.user.phoneNumber }}">
                              <div style="width: 60px; height: 60px; border-radius: 50%;;
                                          text-align: center; display: flex; justify-content: center; align-items: center;"
                                          *ngIf="!usr.user.userImage" id="noimage{{ usr.user.phoneNumber }}"
                                          [style.background]=usr.user.col>
                                <label style="font-size: 20px; color: black; vertical-align: middle;">{{usr.user.myImage}}</label>
                              </div>
                            </div>
                            <div class="spacer"></div> 
                            <div>
                              <div>
                                <label style="font-weight: bold;" id="firstname{{ usr.user.phoneNumber }}">{{ usr.user.firstName }} </label>
                                <label style="font-weight: bold;" id="lastname{{ usr.user.phoneNumber }}">{{ usr.user.lastName }}</label>
                                <br>
                                <div style="margin-bottom: 3px;">
                                  <label id="{{ usr.user.phoneNumber }}">{{ usr.user.phoneNumber }}</label>
                                </div>
                                <div class="smallscreen">
                                  <p-tag styleClass="p-mr-2" severity="success" value="{{ 'GENERAL.UNLOCKED' | translate }}"
                                      *ngIf="usr.user.status == 'UNLOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                      id="status{{ usr.user.phoneNumber }}"></p-tag>
                                  <p-tag severity="danger" value="{{ 'GENERAL.LOCKED' | translate }}"
                                      *ngIf="usr.user.status == 'LOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                      id="status{{ usr.user.phoneNumber }}"></p-tag>
                                </div>
                              </div>
                            </div>
                            <div class="spacer4"></div>
                            <div class="bigscreen">
                              <p-tag styleClass="p-mr-2" severity="success" value="{{ 'GENERAL.UNLOCKED' | translate }}"
                                      *ngIf="usr.user.status == 'UNLOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                      id="status{{ usr.user.phoneNumber }}"></p-tag>
                              <p-tag severity="danger" value="{{ 'GENERAL.LOCKED' | translate }}"
                                    *ngIf="usr.user.status == 'LOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                    id="status{{ usr.user.phoneNumber }}"></p-tag>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-card>
                  </li>
                </p-scrollPanel>
              </div>
            </p-card>
          </div>
</p-dialog>

<!-- ADD CHANNEL USER -->
<p-dialog header="{{ 'GENERAL.ADDCHANUSER' | translate }}" [(visible)]="isaddchanneluser"
          [blockScroll]="true"
          [modal]="true" id="addchanuserdialog" [draggable]="false" [resizable]="false"
          [style]="{'max-width': '400px', 'min-width': '400px'}"
          styleClass="createorgstyle" [focusOnShow]="false" #addchanuserdialog>
          <div style="box-shadow: 0px -3px 0px 0px lightgray; padding: 0px; padding-top: 0px;">
            <p style="text-align: center; margin-top: 10px;"
                *ngIf="(!users || !users.length) && !loading">{{ 'GENERAL.NOUSERS' | translate }}</p>
              <p-scrollPanel [style]="{'height': '300px', 'width': '100%', 'box-shadow': '0px -1px 0px 0px lightgray'}"
                styleClass="custombar addheight">
               <li class="listitem" *ngFor="let user of users" id="users"
                   (click)="showConfirmAddChannelUser(user)">
              <p-card styleClass="hover" [style]="{'border': '1px solid lightgray',
                      'background-color': user.color}">
                <div style="margin: -10px; margin-top: -25px; margin-bottom: -25px;">
                  <div class="header" style="margin: 0px; margin-top: 0px; margin-bottom: 5px;">
                    <div class="toolbar" role="banner">
                      <div style="margin-right: 5px;">
                        <img [src]="user.userImage" alt="{{ 'GENERAL.PICTURE' | translate }}" *ngIf="user.userImage"
                             style="max-width: 60px; max-height: 60px; min-width: 60px; min-height: 60px;
                                    border-radius: 50%;" id="image{{ user.phoneNumber }}">
                        <div style="width: 60px; height: 60px; border-radius: 50%;;
                                    text-align: center; display: flex; justify-content: center; align-items: center;"
                                    *ngIf="!user.userImage" id="noimage{{ user.phoneNumber }}"
                                    [style.background]=user.col>
                          <label style="font-size: 20px; color: black; vertical-align: middle;">{{user.myImage}}</label>
                        </div>
                      </div>
                      <div class="spacer"></div> 
                      <div>
                        <div>
                          <label style="font-weight: bold;" id="firstname{{ user.phoneNumber }}">{{ user.firstName }} </label>
                          <label style="font-weight: bold;" id="lastname{{ user.phoneNumber }}">{{ user.lastName }}</label>
                          <br>
                          <div style="margin-bottom: 3px;">
                            <label id="{{ user.phoneNumber }}">{{ user.phoneNumber }}</label>
                          </div>
                          <p-tag styleClass="p-mr-2" severity="success" value="{{ 'GENERAL.UNLOCKED' | translate }}"
                                 *ngIf="user.status == 'UNLOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                 id="status{{ user.phoneNumber }}"></p-tag>
                          <p-tag severity="danger" value="{{ 'GENERAL.LOCKED' | translate }}"
                                 *ngIf="user.status == 'LOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                 id="status{{ user.phoneNumber }}"></p-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-card>
                </li>
              </p-scrollPanel>
          </div>
          <div>
          </div>
</p-dialog>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cd" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
                <button type="button" pButton (click)="onReject()" 
                        label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                        id="rejectdelete"></button>
            </div>
              <div class="p-col-6">
                  <button type="button" pButton (click)="onConfirmDelete()"
                          label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                          id="confirmdelete"></button>
              </div>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cdu" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
                <button type="button" pButton (click)="onReject()" 
                        label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                        id="rejectdelete"></button>
            </div>
              <div class="p-col-6">
                  <button type="button" pButton (click)="onConfirmDeleteUser()"
                          label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                          id="confirmdeleteuser"></button>
              </div>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cdchan" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
                <button type="button" pButton (click)="onReject()" 
                        label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                        id="rejectdelete"></button>
            </div>
              <div class="p-col-6">
                  <button type="button" pButton (click)="onConfirmDeleteChannel()"
                          label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                          id="confirmdeletechannel"></button>
              </div>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cdchantt" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
                <button type="button" pButton (click)="onReject()" 
                        label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                        id="rejectdelete"></button>
            </div>
              <div class="p-col-6">
                  <button type="button" pButton (click)="onConfirmDeleteChannelTaskType()"
                          label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                          id="confirmdeletechanneltasktype"></button>
              </div>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cdchanuser" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
                <button type="button" pButton (click)="onReject()" 
                        label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                        id="rejectdelete"></button>
            </div>
              <div class="p-col-6">
                  <button type="button" pButton (click)="onConfirmDeleteChannelUser()"
                          label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                          id="confirmdeletechanneluser"></button>
              </div>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cachanuser" (onClose)="onRejectAddChannelUser()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
                <button type="button" pButton (click)="onRejectAddChannelUser()" 
                        label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                        id="rejectdelete"></button>
            </div>
              <div class="p-col-6">
                  <button type="button" pButton (click)="onConfirmAddChannelUser()"
                          label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                          id="confirmaddchanneluser"></button>
              </div>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- CONFIRM MESSAGE -->
<p-toast position="center" key="cm" (onClose)="onReject()" [baseZIndex]="5000"
         closable="false" styleClass="stickytoast">
  <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
              <h4>{{message.summary}}</h4>
              <p>{{message.detail}}</p>
          </div>
          <div style="text-align: center;">
            <button type="button" pButton (click)="onConfirmOk()"
                    label="{{ 'GENERAL.OK' | translate }}" class="p-button-primary"
                    id="confirmmessage"></button>
          </div>
      </div>
  </ng-template>
</p-toast>

<!-- LOADER -->
<p-toast position="center" key="loader" [baseZIndex]="50000"
         closable="false" styleClass="stickytoastloader" id="loader"
         [preventOpenDuplicates]="true" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
      <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1;">
            <div class="p-text-center">
              <p-progressSpinner></p-progressSpinner>
            </div>
        </div>
      </ng-template>
</p-toast>

<!-- DETAIL POPUP -->
<p-dialog header="{{ 'GENERAL.DETAIL' | translate }}" [(visible)]="isdetail"
          [blockScroll]="true" id="detaildialog" [draggable]="false" [resizable]="false"
          styleClass="detaildialogstyle" [focusOnShow]="false" #detaildialog>
          <div *ngIf="org" style="margin-left: -15px; margin-right: -15px;">
            <div>
              <div class="p-card-filter">
                <div style="margin: 0px; padding: 0px;">
                 <div class="headerdiv">
                   <div class="header">
                    <div class="toolbar" role="banner">
                        <label style="font-weight: bold; font-size: 15px; margin-left: 5px;" class="wordellipsis">
                            {{ org.organisationName }}
                        </label>
                        <div class="spacer2"></div>
                        <i class="pi pi-trash"
                            style="font-size: 1.5rem; margin-right: 5px; padding: 5px;" 
                            id="deleteorg" (click)="showDelete()"></i>
                    </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
            <div style="padding: 5px; background-color: #F8F8F8;">
             <div>
               <p-card styleClass="p-card-shadow">
                <ng-template pTemplate="header">
                  <div style="margin: 10px; margin-bottom: 0px; height: 25px;">
                    <label style="font-weight: bold;">{{ 'GENERAL.MAINDATA' | translate }}</label>
                    <i class="pi pi-pencil" 
                       style="font-size: 1.5rem; margin-right: 5px; padding: 1px; float: right;" 
                        id="editorg" (click)="showEditOrg()"></i>
                  </div>
                </ng-template>
                 <div style="margin: -10px; margin-top: -30px; margin-bottom: -10px;">
                  <div class="p-grid" style="margin-bottom: -15px;">
                    <div class="p-col-fixed" style="width:100px">
                        <div class="box" style="text-align: right; color: gray;">{{ 'GENERAL.NAME' | translate }}</div>
                    </div>
                    <div class="p-col">
                        <div class="box"><label id="infoorgname" style="color: black;">{{org.organisationName}}</label></div>
                    </div>
                  </div>
                  <div class="p-grid" style="margin-bottom: -15px;">
                    <div class="p-col-fixed" style="width:100px">
                        <div class="box" style="text-align: right; color: gray;">{{ 'GENERAL.NTMURL' | translate }}</div>
                    </div>
                    <div class="p-col">
                        <div class="box"><label id="infontmurl" style="color: black;">{{org.ntmUrl}}</label></div>
                    </div>
                  </div>
                  <div class="p-grid" style="margin-bottom: -15px;">
                    <div class="p-col-fixed" style="width:100px">
                        <div class="box" style="text-align: right; color: gray;">{{ 'GENERAL.SSOURL' | translate }}</div>
                    </div>
                    <div class="p-col">
                        <div class="box"><label id="infossourl" style="color: black;">{{org.ssoUrl}}</label></div>
                    </div>
                  </div>
                  <div class="p-grid" style="margin-bottom: -25px;">
                    <div class="p-col-fixed" style="width:100px">
                        <div class="box" style="text-align: right; color: gray;">{{ 'GENERAL.SSOSECRET' | translate }}</div>
                    </div>
                    <div class="p-col">
                        <div class="box"><label id="infossosecret" style="color: black;">{{org.ssoSecret}}</label></div>
                    </div>
                  </div>
                 </div>
               </p-card>
             </div>
             <br>
             <div>
               <p-card styleClass="p-card-shadow" [style]="{'height': '300px', 'overflow': 'auto'}">
                <ng-template pTemplate="header">
                  <div style="margin: 10px; margin-left: 5px; margin-right: 0px;">
                    <label style="font-weight: bold;">{{ 'GENERAL.ORGUSERS' | translate }}</label>
                    <button pButton type="button"
                           style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                           margin-right: 5px;" label="{{ 'GENERAL.ADD' | translate }}"
                              class="p-button-sm p-button-outlined"
                              (click)="showAddOrgUser()" id="showaddorguser">
                    </button>
                  </div>
                </ng-template>
                <div style="margin: -10px; margin-top: -35px;
                 margin-bottom: -30px; height: 250px;">
                  <p-scrollPanel [style]="{'height': '250px', 'width': '100%'}" styleClass="custombar">
                    <div>
                      <div style="text-align: center; padding-top: 25px;"
                      *ngIf="(!orgusers || orgusers.length == 0) && !loading">
                        <label *ngIf="(!orgusers || orgusers.length == 0) && !loading">{{ 'GENERAL.NOUSERS' | translate }}</label>
                      </div>
                      <li class="listitem" *ngFor="let orguser of orgusers" id="orgusers">
                        <p-card styleClass="hover" [style]="{'border': '1px solid lightgray'}">
                          <div style="margin: -10px; margin-top: -25px; margin-bottom: -25px;">
                            <div class="delete" style="margin-top: 0px; float: right;">
                              <i class="pi pi-trash" 
                                  style="font-size: 1rem; padding: 5px; margin-right: -5px;" 
                                  id="deleteuser{{ orguser.phoneNumber }}"
                                  (click)="showDeleteOrgUser(orguser)"></i> 
                            </div>
                            <div class="header" style="margin: 0px; margin-top: 0px; margin-bottom: 5px;">
                              <div class="toolbar" role="banner">
                                <div style="margin-right: 5px;">
                                  <img [src]="orguser.user.userImage" alt="{{ 'GENERAL.PICTURE' | translate }}" *ngIf="orguser.user.userImage"
                                       style="max-width: 60px; max-height: 60px; min-width: 60px; min-height: 60px;
                                              border-radius: 50%;" id="image{{ orguser.user.phoneNumber }}">
                                  <div style="width: 60px; height: 60px; border-radius: 50%;;
                                              text-align: center; display: flex; justify-content: center; align-items: center;"
                                              *ngIf="!orguser.user.userImage" id="noimage{{ orguser.user.phoneNumber }}"
                                              [style.background]=orguser.user.col>
                                    <label style="font-size: 20px; color: black; vertical-align: middle;">{{orguser.user.myImage}}</label>
                                  </div>
                                </div>
                                <div class="spacer"></div> 
                                <div>
                                  <div>
                                    <label style="font-weight: bold;" id="firstname{{ orguser.user.phoneNumber }}">{{ orguser.user.firstName }} </label>
                                    <label style="font-weight: bold;" id="lastname{{ orguser.user.phoneNumber }}">{{ orguser.user.lastName }}</label>
                                    <br>
                                    <div style="margin-bottom: 3px;">
                                      <label id="{{ orguser.user.phoneNumber }}">{{ orguser.user.phoneNumber }}</label>
                                    </div>
                                    <div style="margin-bottom: 3px; margin-top: -3px;">
                                      <label style="color: gray;">ID: </label>
                                      <label id="uid{{ orguser.user.phoneNumber }}">{{ orguser.userId }}</label>
                                    </div>
                                    <p-tag styleClass="p-mr-2" severity="success" value="{{ 'GENERAL.UNLOCKED' | translate }}"
                                           *ngIf="orguser.user.status == 'UNLOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                           id="status{{ orguser.user.phoneNumber }}"></p-tag>
                                    <p-tag severity="danger" value="{{ 'GENERAL.LOCKED' | translate }}"
                                           *ngIf="orguser.user.status == 'LOCKED'" [style]="{'width': '100px'}" [rounded]="true"
                                           id="status{{ orguser.user.phoneNumber }}"></p-tag>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </p-card>
                       </li>
                      <!-- <li class="listitem" *ngFor="let orguser of orgusers">
                        <div class="header">
                          <label style="font-size: 15px; font-weight: 600;">
                           {{ orguser.user.firstName }} {{ orguser.user.lastName }}
                          </label>
                         <div class="toolbar" role="banner">
                             <label style="font-size: 15px;">
                              {{ orguser.phoneNumber }}
                             </label>
                             <div class="spacer2"></div>
                             <div class="delete" style="margin-top: -20px;">
                              <i class="pi pi-trash" 
                                  style="font-size: 1rem; padding: 5px; margin-right: -5px;" 
                                  id="deleteuser{{ orguser.phoneNumber }}"
                                  (click)="showDeleteOrgUser(orguser)"></i> 
                             </div>             
                         </div>
                       </div>
                      </li> -->
                    </div>
                  </p-scrollPanel>
                </div>
               </p-card>
             </div>
             <br>
             <div>
              <p-card styleClass="p-card-shadow" [style]="{'height': '300px', 'overflow': 'auto'}">
               
                <ng-template pTemplate="header">
                  <div style="margin: 10px; margin-left: 5px;">
                    <label style="font-weight: bold;">{{ 'GENERAL.ORGCHANELS' | translate }}</label>
                    <button pButton type="button"
                           style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                           margin-right: -5px;" label="{{ 'GENERAL.ADD' | translate }}"
                              class="p-button-sm p-button-outlined"
                              (click)="showAddOrgChannel()" id="showaddorgchannel">
                    </button>
                  </div>
                </ng-template>
               <div style="margin: -10px; margin-top: -35px;
                          margin-bottom: -30px; height: 250px;">
                 <p-scrollPanel [style]="{'height': '250px', 'width': '100%'}" styleClass="custombar">
                   <div>
                    <div style="text-align: center; padding-top: 25px;"
                    *ngIf="(!ochannels || ochannels.length == 0) && !loading">
                      <label *ngIf="(!ochannels || ochannels.length == 0) && !loading">{{ 'GENERAL.NOCHANNELS' | translate }}</label>
                    </div>
                    <li class="listitem" *ngFor="let ochan of ochannels" id="orgchannels">
                      <!-- {{ochan.channelName}} -->
                      <p-card styleClass="hover" [style]="{'border': '1px solid lightgray'}">
                       <div style="margin: -10px; margin-top: -25px; margin-bottom: -25px;">
                         <div class="menuhover" style="float: right; margin-top: 0px;">
                          <i class="pi pi-ellipsis-v" style="font-size: 18px; font-weight: bold;
                                padding: 10px; margin-right: -10px; margin-top: 0px;" id="options{{ ochan.channelName }}"
                            (click)="menu.toggle($event)" #menubutton></i>
                         </div>
                         <p-menu #menu [popup]="true" [model]="ochan.options" [style]="{'min-width': '50px'}"
                                [appendTo]="content"></p-menu>
                         <div class="header" style="margin: 0px; margin-top: 0px; margin-bottom: 5px;">
                           <div class="toolbar" role="banner">
                             <div style="margin-right: 5px;">
                              <p-avatar *ngIf="ochan.chanImage" [image]="ochan.chanImage" styleClass="p-mr-2"
                                        size="large" [style]="{'background-color': ochan.col}"></p-avatar>
                              <p-avatar *ngIf="!ochan.chanImage" label="{{ochan.myImage}}" styleClass="p-mr-2"
                                        size="large" [style]="{'background-color': ochan.col}"></p-avatar>
                               <!-- <img [src]="ochan.chanImage" alt="{{ 'GENERAL.PICTURE' | translate }}" *ngIf="ochan.chanImage"
                                    style="max-width: 60px; max-height: 60px; min-width: 60px; min-height: 60px;
                                           border-radius: 50%;" id="image{{ ochan.channelName }}">
                               <div style="width: 60px; height: 60px; border-radius: 50%;;
                                           text-align: center; display: flex; justify-content: center; align-items: center;"
                                           *ngIf="!ochan.chanImage" id="noimage{{ ochan.channelName }}"
                                           [style.background]=ochan.col>
                                 <label style="font-size: 20px; color: black; vertical-align: middle;">{{ochan.myImage}}</label>
                               </div> -->
                             </div>
                             <div class="spacer"></div> 
                             <div>
                               <div>
                                 <label style="font-weight: bold;" id="name{{ ochan.channelName }}">{{ ochan.channelName }} </label>
                                 <br>
                                 <div style="margin-bottom: 3px;">
                                   <label style="color: gray;">{{ 'GENERAL.USERS' | translate }}: </label>
                                   <label id="usercount{{ ochan.channelName }}">{{ ochan.userCount }}</label>
                                 </div>
                                 <div style="margin-bottom: 3px;">
                                   <label style="color: gray;">{{ 'GENERAL.TYPES' | translate }}: </label>
                                   <label id="typecount{{ ochan.channelName }}">{{ ochan.typeCount }}</label>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                      </p-card>
                     </li>
                     <!-- <li class="listitem" *ngFor="let ochan of ochannels">{{ochan.channelName}}</li> -->
                   </div>
                 </p-scrollPanel>
               </div>
              </p-card>
             </div>
            </div>
          </div>
</p-dialog>

