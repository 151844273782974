import { TestUComponent } from './Components/test-u/test-u.component';
import { TestComponent } from './Components/test/test.component';
import { OrgComponent } from './Components/org/org.component';
import { UserComponent } from './Components/user/user.component';
import { enableProdMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakService } from 'keycloak-angular';
import { detect } from 'detect-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpConfigInterceptor } from './Interceptors/httpconfig.interceptor';

import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { TabMenuModule } from 'primeng/tabmenu';
import { AvatarModule } from 'primeng/avatar';
import { CheckboxModule } from 'primeng/checkbox';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

enableProdMode();

// tslint:disable-next-line: typedef
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const keycloakService = new KeycloakService();
const browser = detect();

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    OrgComponent,
    TestComponent,
    TestUComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    AccordionModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    TabViewModule,
    DialogModule,
    DropdownModule,
    InputTextareaModule,
    TableModule,
    InputNumberModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    ToastModule,
    CalendarModule,
    ScrollPanelModule,
    MenuModule,
    MultiSelectModule,
    MenubarModule,
    TagModule,
    TabMenuModule,
    AvatarModule,
    CheckboxModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    CookieService,
    MessageService,
    ConfirmationService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: KeycloakService,
      useValue: keycloakService
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  // bootstrap: [AppComponent]
  entryComponents: [ AppComponent ]
})
export class AppModule {
  ngDoBootstrap(app: any): any {
    localStorage.setItem('browser', 'true');
    keycloakService
      .init(
        {
          config: {
            url: environment.keycloakUrl + '/auth',
            realm: environment.realm,
            clientId: environment.clientid
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          enableBearerInterceptor: true,
          bearerPrefix: 'Bearer',
          bearerExcludedUrls: [
              '/assets',
              '/clients/public']
        })
      .then(async () => {
        // console.log('[ngDoBootstrap] bootstrap app');

        // console.log((await keycloakService.loadUserProfile()).email)

        localStorage.setItem('oi', '');
        localStorage.setItem('ui', '');
        app.bootstrap(AppComponent);
      })
      .catch(
        error => console.error('[ngDoBootstrap] init Keycloak failed', error)
      );
  }
 }
