<div style="height: 100%; padding: 5px; padding-top: 5px;
 display: flex; flex-direction: column; max-width: 1400px; margin: auto;">
    
    <div id="menudiv">
      <div class="bigscreenmenu" *ngIf="items.length">
        <p-card [style]="{'margin-bottom': '5px'}" styleClass="p-card-menu">
            <div style="margin: -10px; margin-top: -25px; margin-bottom: -25px;">
                <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
            </div>
        </p-card>
        <!-- <p-menubar [model]="items" [style]="{'margin-left': '-1px', 'margin-right': '-1px', 'margin-bottom': '5px'}">
        </p-menubar> -->
    </div>
    <div class="smallscreenmenu" *ngIf="items.length">
        <p-menubar [model]="items"
                    [style]="{'margin-left': '-1px', 'margin-right': '-1px', 'margin-bottom': '5px',
                                'background-color': 'white'}">
        </p-menubar>
    </div>
    </div>

  <!-- <div style="background-color: #f2f2f2; padding: 10px;"> -->
  <!-- <div style="background-color: white; padding: 10px;">
      <router-outlet>
      </router-outlet>
  </div> -->

  <div style="margin: 0; flex-grow: 1;" id="contentdiv">
    <p-card [style]="{'background-color': 'white', 'height': '100%'}">
      <div style="margin-left: -8px; margin-right: -8px; margin-top: -25px; margin-bottom: -20px;">
          <p-toast position="top-center" key="tc"></p-toast>
        
          <router-outlet>
          </router-outlet>
      </div>
    </p-card>
  </div>

<!-- LOADER -->
<p-toast position="center" key="loaderhome" [baseZIndex]="50000"
         closable="false" styleClass="stickytoastloader" id="loaderhome"
         [preventOpenDuplicates]="true" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
      <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1;">
            <div class="p-text-center">
              <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
            </div>
        </div>
      </ng-template>
</p-toast>
  
</div>