

<div class="wrapper" [style.height]="contentdiv">

  <!-- CHANNELS AND ORGS -->
  <div class="x" [style.height]="contentdiv">
    <div [class]="divname" style="height: 100%; display: flex; flex-direction: column;
          border: 1px solid transparent;">
      <div>
        <div class="p-card-filter">
          <div style="margin: 0px; padding: 0px;">
           <div class="headerdiv" >
             <div class="header" style="height: 30px; margin-top: -8px;">
              <div class="toolbar" role="banner">
                  <label style="font-weight: bold; font-size: 20px; margin-top: -3px;" class="wordellipsis">
                      {{ 'GENERAL.ORGANISATIONS' | translate }}
                  </label>
                  <div class="spacer2"></div>
                  <div *ngIf="showfilter">
                    <i class="pi pi-filter" style="font-size: 1.5rem;" 
                      *ngIf="isfilter" (click)="toggleFilter()" id="filteron"></i>
                    <i class="pi pi-filter-slash" style="font-size: 1.5rem;"
                        *ngIf="!isfilter" (click)="toggleFilter()" id="filteroff"></i>
                  </div>
                  <div class="spacer2"></div>
                  <!-- <button pButton type="button"
                          style="padding: 3px; padding-left: 8px; padding-right: 8px; font-weight: bold;" label="+"
                          class="p-button-outlined p-button-rounded"
                          (click)="showCreateOrg()"
                          id="showcreateorg"></button> -->
                  <!-- <button pButton type="button"
                          style="padding: 3px; padding-left: 8px; padding-right: 8px; font-weight: bold;" label="+"
                          class="p-button-outlined p-button-sm p-button-rounded"
                          (click)="showCreateOrg()"
                          id="showcreateorg"></button> -->
                  <p-button label="" icon="pi pi-plus"
                              styleClass="p-button-sm p-button-rounded p-button-outlined"
                              id="createorg" (click)="showCreateOrg()"
                              [style]="{'margin-right': '0px'}"></p-button>               
              </div>
            </div>
           </div>
           <div style="margin-top: 10px; margin-left: -10px; margin-right: -10px; padding: 10px;
                border-top: solid 5px white; padding-bottom: 0px; margin-bottom: -10px;">
           <div style="margin-top: 10px; margin-left: -10px; margin-right: -10px; padding: 10px;
                       border-top: solid 1px black; padding-bottom: 0px; margin-bottom: -10px;"
                *ngIf="isfilter">
              <div class="smallscreenfilter">
                  <div class="p-grid">
                      <div class="p-col-fixed" style="width:50px"  id="filterlabel">
                          <div class="box">
                              <label for="filtersmall">{{ 'GENERAL.BY' | translate }}</label>
                          </div>
                      </div>
                      <div class="p-col">
                          <div class="box">
                            <form [formGroup]="filterform" (ngSubmit)="apply()">
                              <p-dropdown [options]="filters" formControlName="filter"
                                      placeholder="{{ 'GENERAL.SELECTFILTER' | translate }}"
                                      [showClear]="true" [style]="{'width': '100%'}"
                                      id="filtersmall" (onChange)="filterSelection()">
                              </p-dropdown>
                            </form>
                          </div>
                      </div>
                  </div>
                  <div class="p-grid">
                      <div class="p-col-fixed" style="width:50px" id="filterlabel">
                          <div class="box">
                              <label for="valuesmall">{{ 'GENERAL.NAME' | translate }}</label>
                          </div>
                      </div>
                      <div class="p-col">
                          <div class="box">
                            <form [formGroup]="filterform" (ngSubmit)="apply()">
                              <input id="valuesmall" type="text" pInputText formControlName="name"
                                      style="width: 100%;" class="p-inputtext-sm"
                                      placeholder="{{ 'GENERAL.ENTERVALUE' | translate }}">
                            </form>
                          </div>
                      </div>
                  </div>
                  <div class="p-grid p-jc-end">
                      <div style="padding: 10px;">
                          <button pButton pRipple type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                              class="p-button-outlined p-button-sm"
                              (click)="apply()" id="applysmall"></button>
                      </div>
                  </div>
              </div>
              <div>
                <div class="bigscreenfilter">
                  <div class="p-grid">
                      <div class="p-col-fixed" style="width:50px" id="filterlabel">
                          <div class="box">
                              <label for="filterbig">{{ 'GENERAL.BY' | translate }}</label>
                          </div>
                      </div>
                      <div class="p-col">
                          <div class="box">
                            <form [formGroup]="filterform" (ngSubmit)="apply()">
                              <p-dropdown [options]="filters" formControlName="filter"
                                      placeholder="{{ 'GENERAL.SELECTFILTER' | translate }}"
                                      [showClear]="true" [style]="{'width': '100%', 'height': '35px', 'padding-top': '-15px'}"
                                      id="filterbig" (onChange)="filterSelection()"
                                      styleClass="filterDrop">
                              </p-dropdown>
                            </form>
                          </div>
                      </div>
                      <div class="p-col-fixed" style="padding-right: 10px;" id="noapply">
                          <div class="box">
                              <button pButton pRipple type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                                      style="color: transparent; background-color: transparent;"
                                      class="p-button-outlined p-button-sm"></button>
                          </div>
                      </div>
                  </div>
                  <div class="p-grid">
                      <div class="p-col-fixed" style="width:50px;" id="filterlabel">
                          <div class="box">
                              <label for="valuebig">{{ 'GENERAL.NAME' | translate }}</label>
                          </div>
                      </div>
                      <div class="p-col">
                          <div class="box">
                            <form [formGroup]="filterform" (ngSubmit)="apply()">
                              <input id="valuebig" type="text" pInputText formControlName="name"
                                    style="width: 100%;" class="noapplys" class="p-inputtext-sm"
                                    placeholder="{{ 'GENERAL.ENTERVALUE' | translate }}"> 
                            </form>
                          </div>
                      </div>
                      <div class="p-col-fixed" style="padding-right: 10px">
                          <div class="box">
                              <button pButton pRipple type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                                      class="p-button-outlined p-button-sm"
                                      (click)="apply()" id="apply"></button>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
           </div>
           </div>
          </div>
        </div>
      </div>
      <div style="background-color: #F8F8F8; flex-grow: 1; overflow: auto; padding: 5px;">
        <div style="margin-bottom: 25px;" #content>
          <div *ngFor="let org of orgs" id="orgs" class="bigorgs">
            <p-card [style]="{margin: '5px', 'margin-bottom': '10px',
                    'background-color': org.color}"
                  (click)="orgClicked(org)" id="{{org.organisationName}}" styleClass="hover">

                  <div>
                    <div class="p-grid">
                      <div class="p-col">
                          <div class="box">
                            <div class="header" style="margin: -5px; margin-top: -30px; margin-bottom: -30px;">
                              <div class="toolbar" role="banner">
                                <div style="float: left;">
                                  <i [class]="org.Icon" style="font-size: 2rem; margin-top: 5px;"
                                    (click)="showChannels(org)" id="icon{{org.organisationName}}"></i>
                                </div>
                                <div>
                                  <p class="space">
                                    <label style="font-size: 17px; font-weight: bold;" class="pointer" id="name{{org.organisationName}}">{{ org.organisationName }}</label>
                                  </p>
                                  <p class="space">
                                    <label style="font-size: 15px;" class="label">NTM: </label>
                                    <label style="font-size: 15px;" class="pointer" id="ntmurl{{org.organisationName}}">{{ org.ntmUrl }}</label>
                                  </p>
                                  <p class="space">
                                    <label style="font-size: 15px;" class="label">SSO: </label>
                                    <label style="font-size: 15px;" class="pointer" id="ssourl{{org.organisationName}}">{{ org.ssoUrl }}</label>
                                  </p>
                                  <p class="space">
                                    <label style="font-size: 15px;" class="label">Secret: </label>
                                    <label style="font-size: 15px;" class="pointer" id="ssosecret{{org.organisationName}}">{{ org.ssoSecret }}</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="p-col-fixed" style="width: 25px; text-align: start; display: flex; justify-content: center; align-items: center;">
                            <div class="box">
                              <div class="itemmenu">
                                <i class="pi pi-ellipsis-v" style="font-size: 18px; font-weight: bold;
                                      padding: 10px; margin-right: 0px; margin-top: 0px;" id="options{{ org.organisationName }}"
                                  (click)="menu.toggle($event)" #menubutton></i>
                              </div>
                              <p-menu #menu [popup]="true" [model]="org.options" [style]="{'min-width': '50px'}"
                                          [appendTo]="content"></p-menu>
                            </div>
                        </div>
                    </div>
                  </div>
                  
              </p-card>
              </div>

          
          <div *ngFor="let org of orgs" id="orgs" class="smallorgs">
            <p-card [style]="{margin: '5px', 'margin-bottom': '10px'}"
                    id="{{org.organisationName}}" styleClass="hover">

                  <div>
                    <div class="p-grid">
                      <div class="p-col">
                          <div class="box">
                            <div class="header" style="margin: -5px; margin-top: -30px; margin-bottom: -30px;">
                              <div class="toolbar" role="banner">
                                <div style="float: left;">
                                  <i [class]="org.Icon" style="font-size: 2rem; margin-top: 5px;"
                                    (click)="showChannels(org)" id="icon{{org.organisationName}}"></i>
                                </div>
                                <div>
                                  <p class="space">
                                    <label style="font-size: 17px; font-weight: bold;" class="pointer">{{ org.organisationName }}</label>
                                  </p>
                                  <p class="space">
                                    <label style="font-size: 15px;" class="label">NTM: </label>
                                    <label style="font-size: 15px;" class="pointer">{{ org.ntmUrl }}</label>
                                  </p>
                                  <p class="space">
                                    <label style="font-size: 15px;" class="label">SSO: </label>
                                    <label style="font-size: 15px;" class="pointer">{{ org.ssoUrl }}</label>
                                  </p>
                                  <p class="space">
                                    <label style="font-size: 15px;" class="label">Secret: </label>
                                    <label style="font-size: 15px;" class="pointer">{{ org.ssoSecret }}</label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="p-col-fixed" style="width: 25px; text-align: start; display: flex; justify-content: center; align-items: center;">
                            <div class="box">
                              <div class="itemmenu">
                                <i class="pi pi-ellipsis-v" style="font-size: 18px; font-weight: bold;
                                      padding: 10px; margin-right: 0px; margin-top: 0px;" id="options{{ org.organisationName }}"
                                  (click)="menu.toggle($event)" #menubutton></i>
                              </div>
                              <p-menu #menu [popup]="true" [model]="org.options" [style]="{'min-width': '50px'}"
                                          [appendTo]="content"></p-menu>
                            </div>
                        </div>
                    </div>
                  </div>
                  

              
              
              </p-card>
          </div>
      
          <button pButton type="button" label="{{ 'GENERAL.LOADMOREORGS' | translate }}"
                  style="float: left; margin-left: 5px; margin-bottom: 15px;"
                  class="p-button-outlined p-button-sm"
                  [disabled]="!loadmoreo" (click)="loadMoreO()"
                  *ngIf="orgs.length" id="loadmoreo"></button>
      
          <p *ngIf="isnoorgs" style="text-align: center; margin-top: 30px; font-size: 20px;"
              id="noorgs">{{ 'GENERAL.NOORGS' | translate }}</p>
          <br><br>
        </div>
      </div>
    </div>
  </div>
  
  <!-- DETAIL -->
  <div class="y" *ngIf="org" [style.height]="contentdiv">
      <div style="height: 100%; display: flex; flex-direction: column;
      border: 1px solid #d3cece;">
        <div>
          <div class="p-card-filter">
            <div style="margin: 0px; padding: 0px;">
             <div class="headerdiv">
               <div class="header" style="height: 25px; margin-top: -3px;">
                <div class="toolbar" role="banner">
                    <label style="font-weight: bold; font-size: 20px; margin-top: -5px;" class="wordellipsis">
                        {{ org.organisationName }}
                    </label>
                    <div class="spacer2"></div>
                    <div class="spacer2"></div>
                    <i class="pi pi-pencil" style="font-size: 1.5rem; margin-right: 5px; padding: 1px;" 
                        id="editorg" (click)="showEditOrg()"></i>
                    <i class="pi pi-trash"
                        style="font-size: 1.5rem; margin-right: -5px; padding: 1px;" 
                        id="deleteorg" (click)="showDelete()"></i>
                </div>
              </div>
             </div>
            </div>
          </div>
        </div>
        <div style="padding: 5px; background-color: #F8F8F8;">
         <div>
           <p-card styleClass="p-card-shadow">
             <div style="margin: -10px; margin-top: -30px; margin-bottom: -10px;">
              <p class="info"><label class="infotitle">{{ 'GENERAL.NAME' | translate }}</label><label class="infodetail" id="infoorgname">{{org.organisationName}}</label></p>
              <p class="info"><label class="infotitle">{{ 'GENERAL.NTMURL' | translate }}</label><label class="infodetail" id="infontmurl">{{org.ntmUrl}}</label></p>
              <p class="info"><label class="infotitle">{{ 'GENERAL.SSOURL' | translate }}</label><label class="infodetail" id="infossourl">{{org.ssoUrl}}</label></p>
              <p class="info"><label class="infotitle">{{ 'GENERAL.SSOSECRET' | translate }}</label><label class="infodetail" id="infossosecret">{{org.ssoSecret}}</label></p>
             </div>
           </p-card>
         </div>
        </div>
        <div style="background-color: #F8F8F8; flex-grow: 1; overflow: auto; padding: 0px;"
              #orgvalues id="orgvalues">
          <div style="margin: 0px;" *ngIf="showdivs">
            <div style="padding: 5px;">
              <div class="p-grid" style="margin: 0px;">
               <div class="p-col" style="margin: 0px; padding: 0px; margin-right: 5px;">
                 <p-card [style]="{'width': '100%', 'overflow': 'auto'}"
                     styleClass="p-card-shadow">
                     <ng-template pTemplate="header">
                       <div style="margin: 10px; margin-left: 15px;">
                         <label style="font-weight: bold;">{{ 'GENERAL.ORGUSERS' | translate }}</label>
                       </div>
                     </ng-template>
                     <div style="margin: -10px; margin-top: -35px; margin-bottom: -30px;"
                          [style.height]="divheight">
                       <p-scrollPanel [style]="{'height': '100%', 'width': '100%'}"
                                      styleClass="custombar">
                         <div>
                           <div style="text-align: center; padding-top: 25px;"
                                *ngIf="(!orgusers || orgusers.length == 0) && !loading">
                             <label *ngIf="(!orgusers || orgusers.length == 0) && !loading" id="noorgusers">{{ 'GENERAL.NOUSERS' | translate }}</label>
                           </div>
                           <li class="listitem" *ngFor="let orguser of orgusers" id="orgusers">
                             <div class="header">
                              <label style="font-size: 15px; font-weight: 600;" id="{{orguser.phoneNumber}}">
                               {{ orguser.user.firstName }} {{ orguser.user.lastName }}
                              </label>
                              <div class="toolbar" role="banner">
                                <label style="font-size: 15px;">
                                  {{ orguser.phoneNumber }}
                                </label>
                                <div class="spacer2"></div>
                                <div class="delete" style="margin-top: -20px;">
                                  <i class="pi pi-trash" 
                                      style="font-size: 1rem; padding: 5px;" 
                                      id="deleteuser{{ orguser.phoneNumber }}"
                                      (click)="showDeleteOrgUser(orguser)"></i> 
                                </div>             
                              </div>
                            </div>
                           </li>
                         </div>
                       </p-scrollPanel>
                     </div>
                     <ng-template pTemplate="footer">
                      <button pButton type="button"
                              style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                              margin-right: -10px; margin-top: 3px; margin-bottom: 5px;" label="{{ 'GENERAL.ADD' | translate }}"
                                 class="p-button-outlined p-button-sm"
                                 (click)="showAddOrgUser()" id="showaddorguser">
                      </button>
                     </ng-template>
                 </p-card>
               </div>
               <div class="p-col" style="margin: 0px; padding: 0px; margin-left: 5px;">
                 <p-card [style]="{'width': '100%', 'height': '100%',
                                 'overflow': 'auto'}"
                     styleClass="p-card-shadow">
                     <ng-template pTemplate="header">
                       <div style="margin: 10px;">
                         <label style="font-weight: bold;">{{ 'GENERAL.ORGCHANELS' | translate }}</label>
                       </div>
                     </ng-template>
                     <div style="margin: -10px; margin-top: -35px; margin-bottom: -30px;"
                          [style.height]="divheight2">
                       <p-scrollPanel [style]="{'height': '100%', 'width': '100%'}" styleClass="custombar">
                         <div>
                          <div style="text-align: center; padding-top: 25px;"
                          *ngIf="(!ochannels || ochannels.length == 0) && !loading">
                            <label *ngIf="(!ochannels || ochannels.length == 0) && !loading" id="noorgchannels">{{ 'GENERAL.NOCHANNELS' | translate }}</label>
                          </div>
                           <li class="listitem" *ngFor="let ochan of ochannels" id="orgchannels">{{ochan.channelName}}</li>
                         </div>
                       </p-scrollPanel>
                     </div>
                 </p-card>
               </div>
          </div>
        </div>
       </div>
         </div>

      </div>
  </div>
</div>


<!-- CREATE ORGANISATION -->
<p-dialog header="{{ 'GENERAL.CREATEORG' | translate }}" [(visible)]="iscreateorg"
        [blockScroll]="true"
        [modal]="true" id="createorgdialog" [draggable]="false" [resizable]="false"
        [style]="{'max-width': '500px', 'min-width': '500px'}"
        styleClass="createorgstyle" [focusOnShow]="false" #createorgdialog>
        <div style="padding-top: 10px">
          <div class="p-field">
            <label for="orgname">{{ 'GENERAL.ORGANISATIONNAME' | translate }} (*)</label>
            <input id="orgname" type="username" aria-describedby="orgname-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERORGANISATIONNAME' | translate }}"
                    [(ngModel)]="OrgName" />
            <small id="orgname-help" class="p-error" *ngIf="orgnameerror">
              {{ 'GENERAL.ENTERORGANISATIONNAME' | translate }}
            </small>
          </div>
          <div class="p-field">
            <label for="ntmurl">{{ 'GENERAL.NTMURL' | translate }} (*)</label>
            <input id="ntmurl" type="username" aria-describedby="ntmurl-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERNTMURL' | translate }}"
                    [(ngModel)]="NtmUrl" />
            <small id="ntmurl-help" class="p-error" *ngIf="ntmurlerror">
              {{ 'GENERAL.ENTERNTMURL' | translate }}
            </small>
          </div>
          <div class="p-field">
            <label for="ssourl">{{ 'GENERAL.SSOURL' | translate }} (*)</label>
            <input id="ssourl" type="username" aria-describedby="ssourl-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERSSOURL' | translate }}"
                    [(ngModel)]="SsoUrl" />
            <small id="ssourl-help" class="p-error" *ngIf="ssourlerror">
              {{ 'GENERAL.ENTERSSOURL' | translate }}
            </small>
          </div>
          <div class="p-field">
            <label for="ssosecret">{{ 'GENERAL.SSOSECRET' | translate }} (*)</label>
            <input id="ssosecret" type="username" aria-describedby="ssosecret-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERSSOSECRET' | translate }}"
                    [(ngModel)]="SsoSecret"
                     />
            <small id="ssosecret-help" class="p-error" *ngIf="ssosecreterror">
              {{ 'GENERAL.ENTERSSOSECRET' | translate }}
            </small>
          </div>
        </div>
        <div>
          <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                  style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                  (click)="cancel()"
                  [disabled]="loading"
                  id="cancelorg">
          </button>
          <button pButton type="button" label="{{ 'GENERAL.CREATE' | translate }}"
                  style="margin-top: 5px;" class="p-button-raised"
                  (click)="createOrg()"
                  [disabled]="loading"
                  id="createorg">
          </button>
        </div>
</p-dialog>

<!-- EDIT ORGANISATION -->
<p-dialog header="{{ 'GENERAL.EDITORG' | translate }}" [(visible)]="iseditorg"
        [blockScroll]="true"
        [modal]="true" id="editorgdialog" [draggable]="false" [resizable]="false"
        [style]="{'max-width': '500px', 'min-width': '500px'}"
        styleClass="createorgstyle" [focusOnShow]="false" #editorgdialog>
        <div style="padding-top: 10px">
          <div class="p-field">
            <label for="orgname">{{ 'GENERAL.ORGANISATIONNAME' | translate }} (*)</label>
            <input id="orgname" type="username" aria-describedby="orgname-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERORGANISATIONNAME' | translate }}"
                    [(ngModel)]="OrgName" disabled="true"/>
            <small id="orgname-help" class="p-error" *ngIf="orgnameerror">
              {{ 'GENERAL.ENTERORGANISATIONNAME' | translate }}
            </small>
          </div>
          <div class="p-field">
            <label for="ntmurl">{{ 'GENERAL.NTMURL' | translate }} (*)</label>
            <input id="ntmurl" type="username" aria-describedby="ntmurl-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERNTMURL' | translate }}"
                    [(ngModel)]="NtmUrl" />
            <small id="ntmurl-help" class="p-error" *ngIf="ntmurlerror">
              {{ 'GENERAL.ENTERNTMURL' | translate }}
            </small>
          </div>
          <div class="p-field">
            <label for="ssourl">{{ 'GENERAL.SSOURL' | translate }} (*)</label>
            <input id="ssourl" type="username" aria-describedby="ssourl-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERSSOURL' | translate }}"
                    [(ngModel)]="SsoUrl" />
            <small id="ssourl-help" class="p-error" *ngIf="ssourlerror">
              {{ 'GENERAL.ENTERSSOURL' | translate }}
            </small>
          </div>
          <div class="p-field">
            <label for="ssosecret">{{ 'GENERAL.SSOSECRET' | translate }} (*)</label>
            <input id="ssosecret" type="username" aria-describedby="ssosecret-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERSSOSECRET' | translate }}"
                    [(ngModel)]="SsoSecret"
                     />
            <small id="ssosecret-help" class="p-error" *ngIf="ssosecreterror">
              {{ 'GENERAL.ENTERSSOSECRET' | translate }}
            </small>
          </div>
        </div>
        <div>
          <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                  style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                  (click)="cancel()"
                  [disabled]="loading"
                  id="cancelorg">
          </button>
          <button pButton type="button" label="{{ 'GENERAL.SAVE' | translate }}"
                  style="margin-top: 5px;" class="p-button-raised"
                  (click)="saveOrg()"
                  [disabled]="loading"
                  id="saveorg">
          </button>
        </div>
</p-dialog>

<!-- ADD ORGANISATION USER -->
<p-dialog header="{{ 'GENERAL.ADDORGUSER' | translate }}" [(visible)]="isaddorguser"
        [blockScroll]="true"
        [modal]="true" id="addorguserdialog" [draggable]="false" [resizable]="false"
        [style]="{'max-width': '500px', 'min-width': '500px'}"
        styleClass="createorgstyle" [focusOnShow]="false" #addorguserdialog>
        <div style="padding-top: 10px">
          <div class="p-field">
            <label for="user">{{ 'GENERAL.USER' | translate }} (*)</label>
            <p-dropdown [options]="users" [(ngModel)]="user"
                        placeholder="{{ 'GENERAL.SELECTUSER' | translate }}"
                        [showClear]="true" [style]="{'width': '100%'}"
                        id="user" [appendTo]="addorguserdialog">
              <ng-template pTemplate="selectedItem">
                <div *ngIf="user">
                    <div>{{user.firstName}} {{user.lastName}} ({{user.phoneNumber}})</div>
                </div>
              </ng-template>
              <ng-template let-user pTemplate="item">
                  <div>
                      <div>{{user.label}}</div>
                      <div>{{user.value.phoneNumber}}</div>
                  </div>
              </ng-template>
          </p-dropdown>
            <small id="user-help" class="p-error" *ngIf="usererror">
              {{ 'GENERAL.SELECTUSER' | translate }}
            </small>
          </div>
          <div class="p-field">
            <label for="uid">{{ 'GENERAL.USERIDINORG' | translate }} (*)</label>
            <!-- <input id="uid" type="username" aria-describedby="uid-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERUSERID' | translate }}"
                    [(ngModel)]="Uid" /> -->
            <p-inputNumber id="uid" mode="decimal" inputId="withoutgrouping"
                           [useGrouping]="false" [style]="{'width': '100%'}" aria-describedby="uid-help"
                           placeholder="{{ 'GENERAL.ENTERUSERID' | translate }}"
                           [(ngModel)]="Uid" [maxlength]="19">
            </p-inputNumber>
            <small id="uid-help" class="p-error" *ngIf="uiderror">
              {{ 'GENERAL.ENTERUSERID' | translate }}
            </small>
          </div>
        </div>
        <div>
          <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
                  style="margin-right: 10px;" class="p-button-raised p-button-secondary"
                  (click)="cancel()"
                  [disabled]="loading"
                  id="cancelorguser">
          </button>
          <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                  style="margin-top: 5px;" class="p-button-raised"
                  (click)="addOrgUser()"
                  [disabled]="loading"
                  id="addorguser">
          </button>
        </div>
</p-dialog>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cd" (onClose)="onReject()" [baseZIndex]="5000"
       closable="false" styleClass="stickytoast">
<ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
        <div class="p-text-center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
            <h4>{{message.summary}}</h4>
            <p>{{message.detail}}</p>
        </div>
        <div class="p-grid p-fluid">
          <div class="p-col-6">
              <button type="button" pButton (click)="onReject()" 
                      label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                      id="rejectdelete"></button>
          </div>
            <div class="p-col-6">
                <button type="button" pButton (click)="onConfirmDelete()"
                        label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                        id="confirmdelete"></button>
            </div>
        </div>
    </div>
</ng-template>
</p-toast>

<!-- CONFIRM PROCESS -->
<p-toast position="center" key="cdu" (onClose)="onReject()" [baseZIndex]="5000"
       closable="false" styleClass="stickytoast">
<ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
        <div class="p-text-center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
            <h4>{{message.summary}}</h4>
            <p>{{message.detail}}</p>
        </div>
        <div class="p-grid p-fluid">
          <div class="p-col-6">
              <button type="button" pButton (click)="onReject()" 
                      label="{{ 'GENERAL.NO' | translate }}" class="p-button-secondary"
                      id="rejectdelete"></button>
          </div>
            <div class="p-col-6">
                <button type="button" pButton (click)="onConfirmDeleteUser()"
                        label="{{ 'GENERAL.YES' | translate }}" class="p-button-primary"
                        id="confirmdeleteuser"></button>
            </div>
        </div>
    </div>
</ng-template>
</p-toast>

<!-- CONFIRM MESSAGE -->
<p-toast position="center" key="cm" (onClose)="onReject()" [baseZIndex]="5000"
       closable="false" styleClass="stickytoast">
<ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
        <div class="p-text-center">
            <h4>{{message.summary}}</h4>
            <p>{{message.detail}}</p>
        </div>
        <div style="text-align: center;">
          <button type="button" pButton (click)="onConfirmOk()"
                  label="{{ 'GENERAL.OK' | translate }}" class="p-button-primary"
                  id="confirmmessage"></button>
        </div>
    </div>
</ng-template>
</p-toast>

<!-- LOADER -->
<p-toast position="center" key="loader" [baseZIndex]="50000"
       closable="false" styleClass="stickytoastloader" id="loader"
       [preventOpenDuplicates]="true" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
    <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1;">
          <div class="p-text-center">
            <p-progressSpinner></p-progressSpinner>
          </div>
      </div>
    </ng-template>
</p-toast>

<!-- DETAIL POPUP -->
<p-dialog header="{{ 'GENERAL.DETAIL' | translate }}" [(visible)]="isdetail"
        [blockScroll]="true" id="detaildialog" [draggable]="false" [resizable]="false"
        styleClass="detaildialogstyle" [focusOnShow]="false" #detaildialog>
        <div *ngIf="org" style="margin-left: -15px; margin-right: -15px;">
          <div>
            <div class="p-card-filter">
              <div style="margin: 0px; padding: 0px;">
               <div class="headerdiv">
                 <div class="header">
                  <div class="toolbar" role="banner">
                      <label style="font-weight: bold; font-size: 15px;" class="wordellipsis">
                          {{ org.organisationName }}
                      </label>
                      <div class="spacer2"></div>
                      <i class="pi pi-pencil" 
                          style="font-size: 1.5rem; margin-right: 5px; padding: 5px;" 
                          id="editorg" (click)="showEditOrg()"></i>
                      <i class="pi pi-trash"
                          style="font-size: 1.5rem; margin-right: 5px; padding: 5px;" 
                          id="deleteorg" (click)="showDelete()"></i>
                  </div>
                </div>
               </div>
              </div>
            </div>
          </div>
          <div style="padding: 5px; background-color: #F8F8F8;">
           <div>
             <p-card styleClass="p-card-shadow">
               <div style="margin: -10px; margin-top: -30px; margin-bottom: -10px;">
                <p class="info"><label class="infotitle">{{ 'GENERAL.NAME' | translate }}</label><label class="infodetail" id="infoorgname">{{org.organisationName}}</label></p>
                <p class="info"><label class="infotitle">{{ 'GENERAL.NTMURL' | translate }}</label><label class="infodetail" id="infontmurl">{{org.ntmUrl}}</label></p>
                <p class="info"><label class="infotitle">{{ 'GENERAL.SSOURL' | translate }}</label><label class="infodetail" id="infossourl">{{org.ssoUrl}}</label></p>
                <p class="info"><label class="infotitle">{{ 'GENERAL.SSOSECRET' | translate }}</label><label class="infodetail" id="infossosecret">{{org.ssoSecret}}</label></p>
               </div>
             </p-card>
           </div>
           <br>
           <div>
             <p-card styleClass="p-card-shadow" [style]="{'height': '300px', 'overflow': 'auto'}">
              <ng-template pTemplate="header">
                <div style="margin: 10px; margin-left: 15px;">
                  <label style="font-weight: bold;">{{ 'GENERAL.ORGUSERS' | translate }}</label>
                </div>
              </ng-template>
              <div style="margin: -10px; margin-top: -35px;
               margin-bottom: -30px; height: 215px;">
                <p-scrollPanel [style]="{'height': '215px', 'width': '100%'}" styleClass="custombar">
                  <div>
                    <div style="text-align: center; padding-top: 25px;"
                    *ngIf="(!orgusers || orgusers.length == 0) && !loading">
                      <label *ngIf="(!orgusers || orgusers.length == 0) && !loading">{{ 'GENERAL.NOUSERS' | translate }}</label>
                    </div>
                    <li class="listitem" *ngFor="let orguser of orgusers">
                      <div class="header">
                        <label style="font-size: 15px; font-weight: 600;">
                         {{ orguser.user.firstName }} {{ orguser.user.lastName }}
                        </label>
                       <div class="toolbar" role="banner">
                           <label style="font-size: 15px;">
                            {{ orguser.phoneNumber }}
                           </label>
                           <div class="spacer2"></div>
                           <div class="delete" style="margin-top: -20px;">
                            <i class="pi pi-trash" 
                                style="font-size: 1rem; padding: 5px;" 
                                id="deleteuser{{ orguser.phoneNumber }}"
                                (click)="showDeleteOrgUser(orguser)"></i> 
                           </div>             
                       </div>
                     </div>
                    </li>
                  </div>
                </p-scrollPanel>
              </div>
              <ng-template pTemplate="footer">
                <div style="float: right; padding-bottom: 5px; margin-right: -10px;">
                  <button pButton type="button"
                          style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                          margin-right: 0px; margin-top: 10px; margin-bottom: 0px;" 
                          label="{{ 'GENERAL.ADD' | translate }}"
                          class="p-button-outlined p-button-sm"
                          (click)="showAddOrgUser()"></button>
                </div>
              </ng-template>
             </p-card>
           </div>
           <br>
           <div>
            <p-card styleClass="p-card-shadow" [style]="{'height': '300px', 'overflow': 'auto'}">
             <ng-template pTemplate="header">
               <div style="margin: 10px;">
                 <label style="font-weight: bold;">{{ 'GENERAL.ORGCHANELS' | translate }}</label>
               </div>
             </ng-template>
             <div style="margin: -10px; margin-top: -35px;
                        margin-bottom: -30px; height: 250px;">
               <p-scrollPanel [style]="{'height': '250px', 'width': '100%'}" styleClass="custombar">
                 <div>
                  <div style="text-align: center; padding-top: 25px;"
                  *ngIf="(!ochannels || ochannels.length == 0) && !loading">
                    <label *ngIf="(!ochannels || ochannels.length == 0) && !loading">{{ 'GENERAL.NOCHANNELS' | translate }}</label>
                  </div>
                   <li class="listitem" *ngFor="let ochan of ochannels">{{ochan.channelName}}</li>
                 </div>
               </p-scrollPanel>
             </div>
            </p-card>
           </div>
          </div>
        </div>
</p-dialog>

