import { OrgUserService } from './../../Services/org-user/org-user.service';
import { OrgService } from './../../Services/org/org.service';
import { KeyValue } from './../../Helpers/updaters';
import { StringUpdater } from 'src/app/Helpers/updaters';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserService } from './../../Services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { User } from 'src/app/Models/user';
import { KeycloakService } from 'keycloak-angular';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  users: any = [];
  isLoadMore = false;

  loading = false;

  iscreateuser = false;
  isedituser = false;

  firstnameerror = false;
  lastnameerror = false;
  phoneerror = false;
  passworderror = false;
  confirmpassworderror = false;
  pictureerror = false;
  passwordmismatcherror = false;

  FirstName: string | undefined;
  LastName: string | undefined;
  Phone: string | undefined;
  Password: string | undefined;
  ConfirmPassword: string | undefined;
  Picture: string | undefined;

  picturename: string | undefined;
  pic: any;

  options: any = [];

  page = 1;
  size = 100;

  savedUser: any;

  filters: any = [];
  filter: any;

  organisations: any = [];
  organisation: any;
  Name: string | undefined;

  isorg = false;
  isname = false;

  orga = '';

  filterform: any;

  colorpalette = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

  constructor(
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private messageService: MessageService,
    private orgService: OrgService,
    private keycloak: KeycloakService,
    private orgUserService: OrgUserService,
    private fb: FormBuilder
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');
  }

  async ngOnInit(): Promise<any> {
    this.loading = true;
    this.showLoad();

    this.filterform = this.fb.group({
      filter: new FormControl(),
      name: new FormControl(),
      org: new FormControl()
    }) as FormGroup;

    const org = await this.translate.get('GENERAL.ORGANISATION').toPromise().catch(err => console.log(err));
    const name = await this.translate.get('GENERAL.NAME').toPromise().catch(err => console.log(err));

    const o = localStorage.getItem('uo');
    if (o !== undefined && o !== null) {
      this.orga = o;
    }

    // await this.keycloak.loadUserProfile();
    // const username = await this.keycloak.getUsername();

    const superadmin = this.keycloak.isUserInRole('superadminrole');
    if (superadmin === true) {
      this.filters.push({ label: org + ' ', value: 'organisation'});
      this.filters.push({ label: name + ' ', value: 'name'});

      await this.getOrgs();
      // await this.getUsers();
    }

  //   const orgadmin = this.keycloak.isUserInRole('orgadminrole');
  //   if (orgadmin === true) {
  //     // get user organisation
  //     const usr = await this.orgUserService.getOrganisationUsers(0, 0, ' ', username)
  //     .toPromise().catch(err => this.handleError(err));
  //     if (usr !== undefined && usr !== null && usr.length > 0) {
  //       this.filters.push({ label: name, value: 'name'});

  //       this.orga = usr[0].organisationName;
  //       await this.getUsers();
  //     } else {
  //       this.orga = 'xxxxxxxxxxxxx000000000000000000000';
  //     }
  //   }

    const orgadmin = this.keycloak.isUserInRole('orgadminrole');
    if (orgadmin === true) {
      this.filters.push({ label: name, value: 'name'});
    }

    await this.getUsers();
  }

  async getUsers(): Promise<any> {
    if (this.orga === 'xxxxxxxxxxxxx000000000000000000000') {
      return;
    }

    const orgadmin = this.keycloak.isUserInRole('orgadminrole');
    const superadmin = this.keycloak.isUserInRole('superadminrole');
    if (orgadmin === false && superadmin === false) {
      return;
    }

    this.loading = true;
    this.showLoad();

    let name = ' ';
    let org = ' ';
    if (this.Name === undefined || this.Name === null || this.Name.trim() === '') {
      name = ' ';
    } else {
      name = this.Name;
    }

    if (this.organisation === undefined || this.organisation === null) {
      org = ' ';
    } else {
      org = this.organisation.organisationName;
    }

    if (this.orga !== '' && this.orga !== ' ') {
      org = this.orga;
    }

    const myusers = [];
    const users = await this.userService.getUsers(this.page, this.size, ' ', name, org)
    .toPromise().catch(err => this.handleError(err));

    if (users != null && users.length > 0) {
      if (users.length < this.size) {
        this.isLoadMore = false;
      } else {
        this.isLoadMore = true;
      }

      let count = 0;
      for (const val of users) {
        this.options = [];
        const edit = await this.translate.get('GENERAL.EDIT').toPromise().catch(err => console.log(err));
        const del = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
        const lock = await this.translate.get('GENERAL.LOCK').toPromise().catch(err => console.log(err));
        const unlock = await this.translate.get('GENERAL.UNLOCK').toPromise().catch(err => console.log(err));

        this.options.push({ label: edit, value: 'Edit',
        command: async (event: any) => { await this.processUser(event, val); }});
        this.options.push({ label: del, value: 'Delete',
        command: async (event: any) => { await this.processUser(event, val); }});
        if (val.status === 'LOCKED') {
          this.options.push({ label: unlock + ' ', value: 'Unlock',
          command: async (event: any) => { await this.processUser(event, val); }});
        } else {
          this.options.push({ label: lock + ' ', value: 'Lock',
          command: async (event: any) => { await this.processUser(event, val); }});
        }

        val.options = this.options;
        val.color = 'white';
        if (val.image !== null) {
          val.userImage = this.domSanitizer.bypassSecurityTrustUrl(val.image);
        } else {
          val.userImage = '';
          val.myImage = val.firstName.charAt(0).toUpperCase() + val.lastName.charAt(0).toUpperCase();

          val.col = this.colorpalette[count];
          count += 1;
          if (count === this.colorpalette.length) {
            count = 0;
          }
        }

        if (this.page > 1) {
          this.users.push(val);
        } else {
          myusers.push(val);
        }
      }
    } else {
      // if (this.page === 1) {
      //   this.users = [];
      // }
      this.isLoadMore = false;
    }

    if (this.page === 1) {
      this.users = myusers;
    }

    this.loading = false;
    this.hideLoad();
  }

  async userClicked(myuser: any): Promise<any> {
    const myusers = [];
    for (let val of this.users) {
      const nuser: any = [];
      nuser.col = val.col;
      nuser.device = val.device;
      nuser.firstName = val.firstName;
      nuser.image = val.image;
      nuser.lastActivity = val.lastActivity;
      nuser.lastName = val.lastName;
      nuser.myImage = val.myImage;

      nuser.options = val.options;

      nuser.orgs = val.orgs;
      nuser.password = val.password;
      nuser.phoneNumber = val.phoneNumber;
      nuser.status = val.status;
      nuser.userImage = val.userImage;
      if (myuser.phoneNumber === val.phoneNumber) {
        nuser.color = '#c9e2ee';
        val.color = '#c9e2ee';
      } else {
        nuser.color = 'white';
        val.color = 'white';
      }
      myusers.push(nuser);
      val = nuser;
    }

    this.users = myusers;
  }

  async getOrgs(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const  orgs = await this.orgService.getOrganisations(0, 0, ' ')
    .toPromise().catch(err => this.handleError(err));

    if (orgs !== undefined && orgs !== null && orgs.length > 0) {
      for (const val of orgs) {
        this.organisations.push({ label: val.organisationName, value: val });
      }
    }

    // this.loading = false;
    // this.hideLoad();
  }

  showCreateUser(): any {
    const orgadmin = this.keycloak.isUserInRole('orgadminrole');
    const superadmin = this.keycloak.isUserInRole('superadminrole');
    if (orgadmin === false && superadmin === false) {
      return;
    }

    this.cancel();
    this.iscreateuser = true;
  }

  showEdit(user: any): any {
    if (user.image !== null && user.image !== '') {
      this.pic = this.domSanitizer.bypassSecurityTrustUrl(user.image);
    } else {
      this.pic = '';
    }
    this.picturename = '';
    this.FirstName = user.firstName;
    this.LastName = user.lastName;
    this.Phone = user.phoneNumber;
    this.Picture = user.image;

    this.isedituser = true;
  }

  loadMore(): any {
    this.page += 1;
    this.getUsers();
  }

  filterSelection(): any {
    if (this.filterform.value.filter === 'organisation') {
      this.isorg = true;
      this.isname = false;
      this.Name = '';
      this.filterform.controls.name.setValue('');
    } else if (this.filterform.value.filter === 'name') {
      this.isorg = false;
      this.isname = true;
      this.organisation = null;
      this.filterform.controls.org.setValue('');
    } else {
      this.isorg = false;
      this.isname = false;

      this.Name = '';
      this.organisation = null;
      this.filterform.reset();
    }
  }

  filterSelectionObsolete(): any {
    if (this.filter === 'organisation') {
      this.isorg = true;
      this.isname = false;
      this.Name = '';
    } else if (this.filter === 'name') {
      this.isorg = false;
      this.isname = true;
      this.organisation = null;
    } else {
      this.isorg = false;
      this.isname = false;

      this.Name = '';
      this.organisation = null;
    }
  }

  async processUser(event: any, value: any): Promise<any> {
    this.savedUser = value;

    if (event.item.value === 'Delete') {
      const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
      this.showConfirm(title + ' ' + value.firstName + ' ' + value.lastName + '?', '', 'cd');
      // await this.DeleteUser(value);
    } else if (event.item.value === 'Edit') {
      this.showEdit(value);
    } else if (event.item.value === 'Lock') {
      const title = await this.translate.get('GENERAL.LOCK').toPromise().catch(err => console.log(err));
      this.showConfirm(title + ' ' + value.firstName + ' ' + value.lastName + '?', '', 'cl');
      // await this.lockUnlockUser(event);
    } else if (event.item.value === 'Unlock') {
      const title = await this.translate.get('GENERAL.UNLOCK').toPromise().catch(err => console.log(err));
      this.showConfirm(title + ' ' + value.firstName + ' ' + value.lastName + '?', '', 'cu');
      // await this.lockUnlockUser(event);
    }
  }

  async DeleteUser(user: any): Promise<any> {
    this.loading = true;
    this.showLoad();

    const del = new StringUpdater();
    del.Id = user.phoneNumber;

    const res = await this.userService.deleteUser(del)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.USERDELETED').toPromise().catch(err => console.log(err));
        this.showSuccess(title, detail);

        this.cancel();
        const temppage = this.page;
        this.size = this.page * this.size;
        this.page = 1;
        await this.getUsers();
        this.page = temppage;
        this.size = 100;
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.USERNOTDELETED').toPromise().catch(err => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async createUser(): Promise<any> {
    this.firstnameerror = false;
    this.lastnameerror = false;
    this.phoneerror = false;
    this.passworderror = false;
    this.confirmpassworderror = false;
    this.pictureerror = false;
    this.passwordmismatcherror = false;

    let create = true;
    if (this.FirstName === undefined || this.FirstName === null || this.FirstName.trim() === '') {
      create = false;
      this.firstnameerror = true;
    }
    if (this.LastName === undefined || this.LastName === null || this.LastName.trim() === '') {
      create = false;
      this.lastnameerror = true;
    }
    if (this.Phone === undefined || this.Phone === null || this.Phone.trim() === '') {
      create = false;
      this.phoneerror = true;
    }
    if (this.Password === undefined || this.Password === null || this.Password.trim() === '') {
      create = false;
      this.passworderror = true;
    }
    if (this.ConfirmPassword === undefined || this.ConfirmPassword === null || this.ConfirmPassword.trim() === '') {
      create = false;
      this.confirmpassworderror = true;
    }
    if (this.Picture === undefined || this.Picture === null || this.Picture.trim() === '') {
      // create = false;
      // this.pictureerror = true;
      this.Picture = '';
    }

    if (this.Password !== this.ConfirmPassword) {
      // password mismatch
      create = false;
      this.passwordmismatcherror = true;
    }

    if (create === true) {
      // create user
      const user = new User();
      user.FirstName = this.FirstName as string;
      user.LastName = this.LastName as string;
      user.Password = this.Password as string;
      user.PhoneNumber = this.Phone as string;
      user.Image = this.Picture as string;
      user.Status = 'UNLOCKED';

      this.loading = true;
      this.showLoad();

      const res = await this.userService.postUser(user)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res.toString() === user.PhoneNumber) {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERCREATED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          const temppage = this.page;
          this.size = this.page * this.size;
          this.page = 1;
          await this.getUsers();
          this.page = temppage;
          this.size = 100;
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERNOTCREATED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }

        this.loading = false;
        this.hideLoad();
      }
    }
  }

  async saveUser(): Promise<any> {
    this.firstnameerror = false;
    this.lastnameerror = false;
    this.phoneerror = false;
    this.passworderror = false;
    this.confirmpassworderror = false;
    this.pictureerror = false;
    this.passwordmismatcherror = false;

    let create = true;
    if (this.FirstName === undefined || this.FirstName === null || this.FirstName.trim() === '') {
      create = false;
      this.firstnameerror = true;
    }
    if (this.LastName === undefined || this.LastName === null || this.LastName.trim() === '') {
      create = false;
      this.lastnameerror = true;
    }
    if (this.Phone === undefined || this.Phone === null || this.Phone.trim() === '') {
      create = false;
      this.phoneerror = true;
    }
    // if (this.Password === undefined || this.Password === null || this.Password.trim() === '') {
    //   create = false;
    //   this.passworderror = true;
    // }
    // if (this.ConfirmPassword === undefined || this.ConfirmPassword === null || this.ConfirmPassword.trim() === '') {
    //   create = false;
    //   this.confirmpassworderror = true;
    // }
    if (this.Picture === undefined || this.Picture === null || this.Picture.trim() === '') {
      // create = false;
      // this.pictureerror = true;
      this.Picture = '';
    }

    // if (this.Password !== this.ConfirmPassword) {
    //   // password mismatch
    //   create = false;
    //   this.passwordmismatcherror = true;
    // }

    if (create === true) {
      // save user
      const val = new StringUpdater();
      val.Id = this.savedUser.phoneNumber;
      val.Values = [];

      const firstname = new KeyValue();
      firstname.Key = 'firstname';
      firstname.Value = this.FirstName as string;
      val.Values.push(firstname);

      const lastname = new KeyValue();
      lastname.Key = 'lastname';
      lastname.Value = this.LastName as string;
      val.Values.push(lastname);

      const image = new KeyValue();
      image.Key = 'image';
      image.Value = this.Picture as string;
      val.Values.push(image);

      this.loading = true;
      this.showLoad();

      const res = await this.userService.putUser(val)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERUPDATED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          const temppage = this.page;
          this.size = this.page * this.size;
          this.page = 1;
          await this.getUsers();
          this.page = temppage;
          this.size = 100;
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERNOTUPDATED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }

        this.loading = false;
        this.hideLoad();
      }
    }
  }

  async lockUnlockUser(value: string): Promise<any> {
    // lock / unlock user
    // const value = event.item.value;

    const val = new StringUpdater();
    val.Id = this.savedUser.phoneNumber;
    val.Values = [];

    const status = new KeyValue();
    status.Key = 'status';
    if (value === 'Lock') {
      status.Value = 'LOCKED';
    } else {
      status.Value = 'UNLOCKED';
    }
    val.Values.push(status);

    this.loading = true;
    this.showLoad();

    const res = await this.userService.putUser(val)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // success
        if (value === 'Lock') {
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERLOCKED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);
        } else {
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERUNLOCKED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);
        }

        this.cancel();
        const temppage = this.page;
        this.size = this.page * this.size;
        this.page = 1;
        await this.getUsers();
        this.page = temppage;
        this.size = 100;
      } else {
        // failure
        if (value === 'Lock') {
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERNOTLOCKED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        } else {
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERNOTUNLOCKED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  cancel(): any {
    this.iscreateuser = false;
    this.isedituser = false;

    this.FirstName = '';
    this.firstnameerror = false;
    this.LastName = '';
    this.lastnameerror = false;
    this.Phone = '';
    this.phoneerror = false;
    this.Password = '';
    this.passworderror = false;
    this.ConfirmPassword = '';
    this.confirmpassworderror = false;
    this.Picture = '';
    this.pictureerror = false;

    this.picturename = '';
    this.pic = null;
  }

  onFileSelected(): any {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.picturename = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Picture = base64;
        this.pic = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.picturename = '';
        this.Picture = '';
        this.pic = null;
      });
    }
  }

  onFileSelectedEdit(): any {
    const inputNode: any = document.querySelector('#file2');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.picturename = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Picture = base64;
        this.pic = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.picturename = '';
        this.Picture = '';
        this.pic = null;
      });
    }
  }

  async apply(): Promise<any> {
    if (this.filterform === undefined || this.filterform === null
      || this.filterform.value === undefined || this.filterform.value === null
      || this.filterform.value.filter === undefined || this.filterform.value.filter === null) {
      // get all
      this.page = 1;
      await this.getUsers();
    } else if (this.filterform.value.filter === 'name') {
      if (this.filterform.value.name === undefined || this.filterform.value.name === null
         || this.filterform.value.name.trim() === '') {
        // no name
        const title = await this.translate.get('GENERAL.ENTERNAME').toPromise().catch(err => console.log(err));
        this.showConfirm(title, '', 'cm', 'info');
      } else {
        this.Name = this.filterform.value.name;
        // filter by user name
        this.page = 1;
        await this.getUsers();
      }
    } else if (this.filterform.value.filter === 'organisation') {
      if (this.filterform.value.org === undefined || this.filterform.value.org === null) {
        // no organisation
        const title = await this.translate.get('GENERAL.SELECTORGANISATION').toPromise().catch(err => console.log(err));
        this.showConfirm(title, '', 'cm', 'info');
      } else {
        this.organisation = this.filterform.value.org;
        // filter by organisation
        this.page = 1;
        await this.getUsers();
      }
    }
  }

  async applyObsolete(): Promise<any> {
    if (this.filter === undefined || this.filter === null) {
      // get all
      this.page = 1;
      // this.users = [];
      await this.getUsers();
    } else if (this.filter === 'name') {
      if (this.Name === undefined || this.Name === null || this.Name.trim() === '') {
        // no name
        const title = await this.translate.get('GENERAL.ENTERNAME').toPromise().catch(err => console.log(err));
        this.showConfirm(title, '', 'cm', 'info');
      } else {
        // filter by user name
        this.page = 1;
        // this.users = [];
        await this.getUsers();
      }
    } else if (this.filter === 'organisation') {
      if (this.organisation === undefined || this.organisation === null) {
        // no organisation
        const title = await this.translate.get('GENERAL.SELECTORGANISATION').toPromise().catch(err => console.log(err));
        this.showConfirm(title, '', 'cm', 'info');
      } else {
        // filter by organisation
        this.page = 1;
        // this.users = [];
        await this.getUsers();
      }
    }
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  async onConfirmDelete(): Promise<any> {
    this.messageService.clear('cd');
    await this.DeleteUser(this.savedUser);
  }

  async onConfirmLock(): Promise<any> {
    this.messageService.clear('cl');
    await this.lockUnlockUser('Lock');
  }

  async onConfirmUnlock(): Promise<any> {
    this.messageService.clear('cu');
    await this.lockUnlockUser('Unlock');
  }

  async onConfirmOk(): Promise<any> {
    this.messageService.clear('cm');
  }

  onReject(): any {
      this.messageService.clear('cd');
      this.messageService.clear('cu');
      this.messageService.clear('cl');
      this.messageService.clear('cm');
  }

  clear(): any {
      this.messageService.clear();
  }

  async handleError(err: any): Promise<any> {
    this.loading = false;
    this.hideLoad();

    if (err !== undefined && err != null) {
      if (err.error !== undefined && err.error !== null) {
        if (err.error.MessageText !== undefined && err.error.MessageText !== null) {
          if (err.error.MessageText === 'duplicate key value violates unique constraint "t_user_pkey"') {
            // user already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
            const detail = await this.translate.get('GENERAL.USERALREDAYEXISTS').toPromise().catch(myerr => console.log(myerr));
            this.showWarn(title, detail, 30000);
          }
        } else if (err.error.toString().includes('duplicate key value violates unique constraint \\\"t_user_pkey\\\"')) {
          // user already exists
          const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
          const detail = await this.translate.get('GENERAL.USERALREDAYEXISTS').toPromise().catch(myerr => console.log(myerr));
          this.showWarn(title, detail, 30000);
        } else {
          console.log(err);
        }
      }
    }
  }

}
