import { ChanUserService } from './../../Services/chan-user/chan-user.service';
import { ChannelUser } from './../../Models/channel-user';
import { ChannelTaskType } from './../../Models/channel-task-type';
import { ChanTaskTypeService } from './../../Services/chan-task-type/chan-task-type.service';
import { Channel } from './../../Models/channel';
import { ChannelService } from './../../Services/channel/channel.service';
import { OrganisationUser } from './../../Models/organisation-user';
import { OrgUserService } from './../../Services/org-user/org-user.service';
import { Organisation } from './../../Models/organisation';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { OrgService } from 'src/app/Services/org/org.service';
import { UserService } from 'src/app/Services/user/user.service';
import { KeyValue, StringUpdater } from 'src/app/Helpers/updaters';
import { KeycloakService } from 'keycloak-angular';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-org',
  templateUrl: './org.component.html',
  styleUrls: ['./org.component.css']
})
export class OrgComponent implements OnInit {

  orgs: any = [];
  org: any;

  isnoorgs = false;
  showchannels = false;
  loadmoreo = false;

  isfilter = false;

  divname = 'mydiv';

  page = 1;
  size = 100;

  loading = false;

  OrgName = '';
  NtmUrl = '';
  SsoUrl = '';
  SsoSecret = '';

  orgnameerror = false;
  ntmurlerror = false;
  ssourlerror = false;
  ssosecreterror = false;

  usererror = false;
  uiderror = false;

  iscreateorg = false;
  iseditorg = false;
  isaddorguser = false;
  isaddorgchannel = false;
  iseditorgchannel = false;
  isaddchanneluser = false;
  isaddchanneltype = false;

  isdetail = false;

  orgusers: any = [];
  orguser: any;

  ochannels: any = [];

  divheight = '0px';
  divheight2 = '0px';
  contentdiv = '';

  tempusers: any = [];
  users: any = [];
  user: any;
  Uid = '';

  showfilter = true;

  filters: any = [];
  filter: any;
  Name = '';
  orga = '';

  filterform: any;

  showdivs = false;

  channelnameerror = false;
  channeliconerror = false;

  ChannelName: string | undefined;
  ChannelIcon: string | undefined;

  iconname: string | undefined;
  icon: any;

  chanheader = '';
  ischantasktype = false;
  ischanuser = false;

  chantasktypes: any = [];
  chantasktype: any;

  chanusers: any = [];
  chanuser: any;

  TaskTypeId: number | undefined;
  typeiderror = false;

  PhoneNumber = '';
  phoneerror = false;

  isdefaulttasktype = false;

  colorpalette = ['#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

  colorpalette2 = ['#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

  colorpalette3 = ['#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933'];

  colorpalette4 = ['#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66',
                   '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000',
                   '#B33300', '#CC80CC', '#FF3380', '#CCCC00', '#66E64D',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#4D80CC', '#9900B3',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933'];

  colorpalette5 = ['#CC9999', '#B3B31A', '#00E680', '#FF99E6', '#CCFF1A',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#4D80CC', '#9900B3',
                  '#4D8066', '#809980', '#E6FF80', '#6680B3', '#66991A',
                  '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000',
                  '#B33300', '#CC80CC', '#FF3380', '#CCCC00', '#66E64D',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#1AFF33', '#999933', '#FF1A66'];

  options: any = [];

  orgchan: any;

  menuheight: any;

  constructor(
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private messageService: MessageService,
    private orgService: OrgService,
    private orgUserService: OrgUserService,
    private channelService: ChannelService,
    private keycloak: KeycloakService,
    private fb: FormBuilder,
    private chanTaskTypeService: ChanTaskTypeService,
    private chanUserService: ChanUserService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    const menuheight = document.getElementById('menudiv')?.offsetHeight;
    const contentheight = localStorage.getItem('content');

    const height = document.getElementById('contentdiv')?.offsetHeight;
    this.menuheight = menuheight;
    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 15) + 'px';
    } else {
      this.contentdiv = '85vh';
    }

    this.onResize(null);
  }

  async ngOnInit(): Promise<any> {
    this.filterform = this.fb.group({
      filter: new FormControl(),
      name: new FormControl()
    }) as FormGroup;

    const o = localStorage.getItem('uo');
    if (o !== undefined && o !== null) {
      this.orga = o;
    }

    const org = await this.translate.get('GENERAL.ORGANISATION').toPromise().catch(err => console.log(err));
    const chan = await this.translate.get('GENERAL.CHANNEL').toPromise().catch(err => console.log(err));

    this.filters.push({ label: org + ' ', value: 'organisation'});
    // this.filters.push({ label: chan, value: 'channel'});

    const orgadmin = this.keycloak.isUserInRole('orgadminrole');
    if (orgadmin === true) {
      this.showfilter = false;
    }
    await this.getOrganisations();
    await this.showFirst();
  }

  filterSelection(): any {
    if (this.filterform.value.filter === 'organisation') {
      // this.isorg = true;
      // this.isname = false;
      this.filterform.controls.name.setValue('');
    } else if (this.filterform.value.filter === 'channel') {
      // this.isorg = false;
      // this.isname = true;
      // this.organisation = null;
    } else {
      // this.isorg = false;
      // this.isname = false;

      this.filterform.reset();
      // this.organisation = null;
    }
  }

  filterSelectionObsolete(): any {
    if (this.filter === 'organisation') {
      // this.isorg = true;
      // this.isname = false;
      this.Name = '';
    } else if (this.filter === 'channel') {
      // this.isorg = false;
      // this.isname = true;
      // this.organisation = null;
    } else {
      // this.isorg = false;
      // this.isname = false;

      this.Name = ' ';
      // this.organisation = null;
    }
  }

  async showFirst(): Promise<any> {
    if (this.orgs !== undefined && this.orgs !== null && this.orgs.length > 0) {
      await this.orgClicked(this.orgs[0]);
    }
  }

  async getOrganisations(): Promise<any> {
    this.loading = true;
    this.showLoad();
    this.isnoorgs = false;

    const orgs = await this.orgService.getOrganisations(this.page, this.size, this.orga)
    .toPromise().catch(err => this.handleError(err));

    const os: any = [];
    if (orgs !== undefined && orgs !== null && orgs.length > 0) {
      if (orgs.length < this.size) {
        this.loadmoreo = false;
      } else {
        this.loadmoreo = true;
      }

      let options: any = [];
      for (const val of orgs) {
        options = [];
        const det = await this.translate.get('GENERAL.DETAIL')
        .toPromise().catch(err => console.log(err));

        options.push({ label: det, value: 'Detail',
        command: async (event: any) => { await this.showDetail(val); }});

        val.options = options;
        val.color = 'white';
        if (this.page > 1) {
          this.orgs.push(val);
        } else {
          os.push(val);
        }
      }
    } else {
      this.loadmoreo = false;

      if (this.page === 1 && os.length === 0) {
        this.isnoorgs = true;
      }
    }

    if (this.page === 1) {
      this.orgs = os;
    }

    this.loading = false;
    this.hideLoad();
  }

  async createOrg(): Promise<any> {
    let iscreate = true;

    this.orgnameerror = false;
    this.ntmurlerror = false;
    this.ssourlerror = false;
    this.ssosecreterror = false;

    if (this.OrgName === undefined || this.OrgName === null || this.OrgName.trim() === '') {
      this.orgnameerror = true;
      iscreate = false;
    }

    if (this.NtmUrl === undefined || this.NtmUrl === null || this.NtmUrl.trim() === '') {
      this.ntmurlerror = true;
      iscreate = false;
    }

    if (this.SsoUrl === undefined || this.SsoUrl === null || this.SsoUrl.trim() === '') {
      this.ssourlerror = true;
      iscreate = false;
    }

    if (this.SsoSecret === undefined || this.SsoSecret === null || this.SsoSecret.trim() === '') {
      this.ssosecreterror = true;
      iscreate = false;
    }

    if (iscreate === true) {
      // create org
      this.loading = true;
      this.showLoad();

      const org = new Organisation();
      org.OrganisationName = this.OrgName;
      org.NtmUrl = this.NtmUrl;
      org.SsoUrl = this.SsoUrl;
      org.SsoSecret = this.SsoSecret;

      const res = await this.orgService.postOrganisation(org)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGCREATED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          const temppage = this.page;
          this.size = this.size * this.page;
          this.page = 1;
          await this.getOrganisations();
          this.page = temppage;
          this.size = 100;

          this.orgClicked(this.org);
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGNOTCREATED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }

        this.loading = false;
        this.hideLoad();
      }
    }
  }

  async saveOrg(): Promise<any> {
    let iscreate = true;

    this.orgnameerror = false;
    this.ntmurlerror = false;
    this.ssourlerror = false;
    this.ssosecreterror = false;

    if (this.OrgName === undefined || this.OrgName === null || this.OrgName.trim() === '') {
      this.orgnameerror = true;
      iscreate = false;
    }

    if (this.NtmUrl === undefined || this.NtmUrl === null || this.NtmUrl.trim() === '') {
      this.ntmurlerror = true;
      iscreate = false;
    }

    if (this.SsoUrl === undefined || this.SsoUrl === null || this.SsoUrl.trim() === '') {
      this.ssourlerror = true;
      iscreate = false;
    }

    if (this.SsoSecret === undefined || this.SsoSecret === null || this.SsoSecret.trim() === '') {
      this.ssosecreterror = true;
      iscreate = false;
    }

    if (iscreate === true) {
      // create org
      this.loading = true;
      this.showLoad();

      const val = new StringUpdater();
      val.Id = this.OrgName;
      val.Values = [];

      const ntmurl = new KeyValue();
      ntmurl.Key = 'ntm_url';
      ntmurl.Value = this.NtmUrl;
      val.Values.push(ntmurl);

      const ssourl = new KeyValue();
      ssourl.Key = 'sso_url';
      ssourl.Value = this.SsoUrl;
      val.Values.push(ssourl);

      const ssosecret = new KeyValue();
      ssosecret.Key = 'sso_secret';
      ssosecret.Value = this.SsoSecret;
      val.Values.push(ssosecret);

      const res = await this.orgService.putOrganisation(val)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGUPDATED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          const temppage = this.page;
          this.size = this.size * this.page;
          this.page = 1;
          await this.getOrganisations();
          this.page = temppage;
          this.size = 100;

          for (const value of this.orgs) {
            if (value.organisationName === this.org.organisationName) {
              this.orgClicked(value);
              break;
            }
          }

          // for (const v of this.orgs) {
          //   if (v.organisationName === this.org.organisationName) {
          //     this.org = v;
          //     break;
          //   }
          // }
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGNOTUPDATED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }

        this.loading = false;
        this.hideLoad();
      }
    }
  }

  cancel(): any {
    this.iscreateorg = false;
    this.iseditorg = false;
    this.isaddorguser = false;

    this.orgnameerror = false;
    this.ntmurlerror = false;
    this.ssourlerror = false;
    this.ssosecreterror = false;

    this.usererror = false;
    this.uiderror = false;

    this.OrgName = '';
    this.NtmUrl = '';
    this.SsoUrl = '';
    this.SsoSecret = '';

    this.user = null;
    this.Uid = '';

    this.iconname = '';
    this.icon = null;

    this.ChannelName = '';
    this.ChannelIcon = '';

    this.channelnameerror = false;
    this.channeliconerror = false;

    this.isaddorgchannel = false;
    this.iseditorgchannel = false;

    this.TaskTypeId = 0;
    this.typeiderror = false;

    this.isaddchanneltype = false;
    this.isaddchanneluser = false;

    this.phoneerror = false;
    this.PhoneNumber = '';

    this.isdefaulttasktype = false;
  }

  showCreateOrg(): any {
    this.loading = false;
    this.cancel();
    this.iscreateorg = true;
  }

  showEditOrg(): any {
    this.loading = false;
    this.cancel();

    this.OrgName = this.org.organisationName;
    this.NtmUrl = this.org.ntmUrl;
    this.SsoUrl = this.org.ssoUrl;
    this.SsoSecret = this.org.ssoSecret;

    this.iseditorg = true;
  }

  async showDetail(org: any): Promise<any> {
    this.org = org;
    this.isdetail = true;

    await this.getOrgUsers();
    await this.getOrgChannels();
  }

  async orgClicked(org: any): Promise<any> {
    if (this.isdetail === true) {
      return;
    }

    this.org = org;

    const myorgs = [];
    for (const val of this.orgs) {
      const value: any = [];
      value.ntmUrl = val.ntmUrl;
      value.options = val.options;
      value.organisationName = val.organisationName;
      value.ssoSecret = val.ssoSecret;
      value.ssoUrl = val.ssoUrl;
      if (val.organisationName === org.organisationName) {
        value.color = '#c9e2ee';
      } else {
        value.color = 'white';
      }
      myorgs.push(value);
    }

    this.orgs = myorgs;

    this.orgusers = [];
    this.ochannels = [];
    await this.getOrgUsers();
    await this.getOrgChannels();

    const height = document.getElementById('orgvalues')?.offsetHeight;

    if (height !== undefined && height !== null) {
      this.divheight = (height - 65) + 'px';
      this.divheight2 = this.divheight;
    } else {
      this.divheight = '300px';
      this.divheight2 = '300px';
    }
    this.showdivs = true;
  }

  async orgSmallClicked(org: any): Promise<any> {
    const myorgs = [];
    for (const val of this.orgs) {
      const value: any = [];
      value.ntmUrl = val.ntmUrl;
      value.options = val.options;
      value.organisationName = val.organisationName;
      value.ssoSecret = val.ssoSecret;
      value.ssoUrl = val.ssoUrl;
      if (val.organisationName === org.organisationName) {
        value.color = '#c9e2ee';
      } else {
        value.color = 'white';
      }
      myorgs.push(value);
    }

    this.orgs = myorgs;
  }

  async showDelete(): Promise<any> {
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    if (this.orgusers !== undefined && this.orgusers !== null && this.orgusers.length > 0
      && this.ochannels !== undefined && this.ochannels !== null && this.ochannels.length > 0) {
        const addinfo = await this.translate.get('GENERAL.CONTAINSUSERSANDCHANNELS').toPromise().catch(err => console.log(err));
        this.showConfirm(title + ' ' + this.org.organisationName + '?', addinfo, 'cd');
    } else if (this.orgusers !== undefined && this.orgusers !== null && this.orgusers.length > 0) {
      const addinfo = await this.translate.get('GENERAL.CONTAINSUSERS').toPromise().catch(err => console.log(err));
      this.showConfirm(title + ' ' + this.org.organisationName + '?', addinfo, 'cd');
    } else if (this.ochannels !== undefined && this.ochannels !== null && this.ochannels.length > 0) {
      const addinfo = await this.translate.get('GENERAL.CONTAINSCHANNELS').toPromise().catch(err => console.log(err));
      this.showConfirm(title + ' ' + this.org.organisationName + '?', addinfo, 'cd');
    } else {
      this.showConfirm(title + ' ' + this.org.organisationName + '?', '', 'cd');
    }
  }

  async deleteOrg(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const res = await this.orgService.deleteOrganisation(this.org.organisationName)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGDELETED').toPromise().catch(err => console.log(err));
        this.showSuccess(title, detail);

        this.cancel();
        const temppage = this.page;
        this.size = this.page * this.size;
        this.page = 1;
        await this.getOrganisations();
        this.isdetail = false;
        this.org = null;
        await this.showFirst();
        this.page = temppage;
        this.size = 100;
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGNOTDELETED').toPromise().catch(err => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async deleteOrgUser(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const myorguser = new OrganisationUser();
    myorguser.OrganisationName = this.orguser.organisationName;
    myorguser.PhoneNumber = this.orguser.phoneNumber;
    myorguser.UserId = this.orguser.userId;

    const res = await this.orgUserService.deleteOrganisation(myorguser)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGUSERDELETED').toPromise().catch(err => console.log(err));
        this.showSuccess(title, detail);

        await this.getOrgUsers();
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGUSERNOTDELETED').toPromise().catch(err => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async deleteOrgChannel(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const res = await this.channelService.deleteChannel(this.orgchan.channelId)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGCHANNELDELETED').toPromise().catch(err => console.log(err));
        this.showSuccess(title, detail);

        this.cancel();
        this.page = 1;
        await this.getOrgChannels();
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGCHANNELNOTDELETED').toPromise().catch(err => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async deleteChannelTaskType(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const ttype = new ChannelTaskType();
    ttype.ChannelId = this.chantasktype.channelId;
    ttype.TaskTypeId = this.chantasktype.taskTypeId;

    const res = await this.chanTaskTypeService.deleteChannelTaskType(ttype)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELTASKTYPEDELETED').toPromise().catch(err => console.log(err));
        this.showSuccess(title, detail);

        await this.getChanTaskTypes();
        await this.getOrgChannels();
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELTASKTYPENOTDELETED').toPromise().catch(err => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async deleteChannelUser(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const cuser = new ChannelUser();
    cuser.ChannelId = this.chanuser.channelId;
    cuser.PhoneNumber = this.chanuser.phoneNumber;

    const res = await this.chanUserService.deleteChannelUser(cuser)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELUSERDELETED').toPromise().catch(err => console.log(err));
        this.showSuccess(title, detail);

        await this.getChannelUsers();
        await this.getOrgChannels();
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELUSERNOTDELETED').toPromise().catch(err => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async getOrgUsers(): Promise<any> {
    this.loading = true;
    this.showLoad();

    this.orgusers = [];
    const orgusers = await this.orgUserService.getOrganisationUsers(0, 0, this.org.organisationName, ' ')
    .toPromise().catch(err => this.handleError(err));

    if (orgusers !== undefined || orgusers !== null && orgusers.length > 0) {
      let count = 0;
      for (const val of orgusers) {
        val.color = 'white';
        if (val.user.image !== null) {
          val.user.userImage = this.domSanitizer.bypassSecurityTrustUrl(val.user.image);
        } else {
          val.user.userImage = '';
          val.user.myImage = val.user.firstName.charAt(0).toUpperCase() + val.user.lastName.charAt(0).toUpperCase();

          val.user.col = this.colorpalette[count];
          count += 1;
          if (count === this.colorpalette.length) {
            count = 0;
          }
        }
        this.orgusers.push(val);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  orgUserClicked(ouser: any): any {
    const mousers = [];
    for (const val of this.orgusers) {
      const ou: any = [];
      ou.organisation = val.organisation;
      ou.organisationName = val.organisationName;
      ou.user = val.user;
      ou.userId = val.userId;
      ou.phoneNumber = val.phoneNumber;
      if (ouser.phoneNumber === val.phoneNumber) {
        ou.color = '#c9e2ee';
      } else {
        ou.color = 'white';
      }
      mousers.push(ou);
    }
    this.orgusers = mousers;
  }

  async getOrgChannels(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const myochannels = [];
    const chans = await this.channelService.getChannels(0, 0, ' ', ' ', this.org.organisationName)
    .toPromise().catch(err => this.handleError(err));

    if (chans !== undefined || chans !== null && chans.length > 0) {
      let count = 0;
      for (const val of chans) {
        this.options = [];
        const users = await this.translate.get('GENERAL.USERS').toPromise().catch(err => console.log(err));
        const types = await this.translate.get('GENERAL.TYPES').toPromise().catch(err => console.log(err));
        const edit = await this.translate.get('GENERAL.NAMEICON').toPromise().catch(err => console.log(err));
        const del = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));

        this.options.push({ label: edit + ' ', value: 'Edit',
        command: async (event: any) => { await this.processChan(event, val); }});
        this.options.push({ label: users + ' ', value: 'Users',
        command: async (event: any) => { await this.processChan(event, val); }});
        this.options.push({ label: types + ' ', value: 'Types',
        command: async (event: any) => { await this.processChan(event, val); }});
        this.options.push({ label: del + ' ', value: 'Delete',
        command: async (event: any) => { await this.processChan(event, val); }});

        val.options = this.options;
        val.color = 'white';
        if (val.channelIcon !== null) {
          val.chanImage = this.domSanitizer.bypassSecurityTrustUrl(val.channelIcon);
        } else {
          val.chanImage = '';
          val.myImage = val.channelName.charAt(0).toUpperCase();

          val.col = this.colorpalette2[count];
          count += 1;
          if (count === this.colorpalette2.length) {
            count = 0;
          }
        }
        myochannels.push(val);
      }

      this.ochannels = myochannels;
    }

    this.loading = false;
    this.hideLoad();
  }

  async processChan(event: any, val: any): Promise<any> {
    this.orgchan = val;
    if (event.item.value === 'Delete') {
      // delete channel
      const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
      this.showConfirm(title + ' ' + this.orgchan.channelName + '?', '', 'cdchan');
    } else if (event.item.value === 'Edit') {
      // edit channel
      this.cancel();
      this.ChannelName = val.channelName;

      if (val.channelIcon !== null && val.channelIcon !== '') {
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(val.channelIcon);
      } else {
        this.icon = '';
      }
      this.iconname = '';
      this.ChannelIcon = val.channelIcon;

      this.iseditorgchannel = true;
    } else if (event.item.value === 'Users') {
      // manage channel users
      await this.getChannelUsers();
      const title = await this.translate.get('GENERAL.USERSFOR').toPromise().catch(err => console.log(err));
      // this.chanheader = title + ' ' + val.channelName;
      this.chanheader = val.channelName;
      this.ischanuser = true;
    } else if (event.item.value === 'Types') {
      // manage channel types
      await this.getChanTaskTypes();
      const title = await this.translate.get('GENERAL.TASKTYPESFOR').toPromise().catch(err => console.log(err));
      // this.chanheader = title + ' ' + val.channelName;
      this.chanheader = val.channelName;
      this.ischantasktype = true;
    }
  }

  async getChanTaskTypes(): Promise<any> {
    this.showLoad();
    this.loading = true;

    const types = await this.chanTaskTypeService.getChannelTaskTypes(0, 0, this.orgchan.channelId, 0, this.org.organisationName)
    .toPromise().catch(err => this.handleError(err));

    this.chantasktypes = [];
    if (types !== undefined && types !== null && types.length > 0) {
      for (const val of types) {
        val.color = 'transparent';
        this.chantasktypes.push(val);
      }
    }

    this.hideLoad();
    this.loading = false;
  }

  chanTaskTypeClicked(type: any): any {
    const ttypes = [];
    for (const val of this.chantasktypes) {
      const mt: any = [];
      mt.channelIcon = val.channelIcon;
      mt.channelId = val.channelId;
      mt.channelName = val.channelName;
      mt.ntmUrl = val.ntmUrl;
      mt.organisationName = val.organisationName;
      mt.ssoSecret = val.ssoSecret;
      mt.ssoUrl = val.ssoUrl;
      mt.taskTypeId = val.taskTypeId;
      mt.isDefault = val.isDefault;
      if (type.taskTypeId === val.taskTypeId) {
        mt.color = '#c9e2ee';
      } else {
        mt.color = 'transparent';
      }
      ttypes.push(mt);
    }

    this.chantasktypes = ttypes;
  }

  async getChannelUsers(): Promise<any> {
    this.showLoad();
    this.loading = true;

    const cusers = await this.chanUserService.getChannelUsers(0, 0, this.orgchan.channelId, ' ')
    .toPromise().catch(err => this.handleError(err));

    this.chanusers = [];
    if (cusers !== undefined && cusers !== null && cusers.length > 0) {
      let count = 0;
      for (const val of cusers) {
        val.color = 'white';
        if (val.user.image !== null) {
          val.user.userImage = this.domSanitizer.bypassSecurityTrustUrl(val.user.image);
        } else {
          val.user.userImage = '';
          val.user.myImage = val.user.firstName.charAt(0).toUpperCase() + val.user.lastName.charAt(0).toUpperCase();

          val.user.col = this.colorpalette3[count];
          count += 1;
          if (count === this.colorpalette3.length) {
            count = 0;
          }
        }
        this.chanusers.push(val);
      }
    }

    this.hideLoad();
    this.loading = false;
  }

  chanUserClicked(chanuser: any): any {
    const mcusers = [];
    for (const val of this.chanusers) {
      const mcu: any = [];
      mcu.channel = val.channel;
      mcu.channelId = val.channelId;
      mcu.phoneNumber = val.phoneNumber;
      mcu.user = val.user;
      if (chanuser.phoneNumber === val.phoneNumber) {
        mcu.color = '#c9e2ee';
      } else {
        mcu.color = 'white';
      }
      mcusers.push(mcu);
    }
    this.chanusers = mcusers;
  }

  async showDeleteChanTaskType(type: any): Promise<any> {
    this.chantasktype = type;
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + type.taskTypeId + '?', '', 'cdchantt');
  }

  async showDeleteChanUser(user: any): Promise<any> {
    this.chanuser = user;
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + user.user.firstName + ' ' + user.user.lastName + '?', user.phoneNumber, 'cdchanuser');
  }

  async showConfirmAddChannelUser(user: any): Promise<any> {
    this.addChanUserClicked(user);
    this.PhoneNumber = user.phoneNumber;
    const title = await this.translate.get('GENERAL.ADD').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + user.firstName + ' ' + user.lastName + '?', user.phoneNumber, 'cachanuser');
  }

  showAddTaskType(): any {
    this.cancel();
    this.TaskTypeId = 0;
    this.isaddchanneltype = true;
  }

  async showAddChanUser(): Promise<any> {
    this.loading = false;

    if (this.tempusers.length === 0) {
      await this.getUsers();
    }

    if (this.chanusers !== undefined && this.chanusers !== null && this.chanusers.length > 0) {
      const myusers = [];
      let count = 0;
      for (const val of this.tempusers) {
        let add = true;
        for (const u of this.chanusers) {
          if (u.phoneNumber === val.value.phoneNumber) {
            add = false;
          }
        }

        if (add === true) {
          if (val.value.image !== null) {
            val.value.userImage = this.domSanitizer.bypassSecurityTrustUrl(val.value.image);
          } else {
            val.value.userImage = '';
            val.value.myImage = val.value.firstName.charAt(0).toUpperCase() + val.value.lastName.charAt(0).toUpperCase();

            val.value.col = this.colorpalette4[count];
            count += 1;
            if (count === this.colorpalette4.length) {
              count = 0;
            }
          }

          myusers.push(val.value);
        }
      }

      this.users = myusers;
    } else {
      // this.users = this.tempusers;
      let count = 0;
      this.users = [];
      for (const val of this.tempusers) {
        if (val.value.image !== null) {
          val.value.userImage = this.domSanitizer.bypassSecurityTrustUrl(val.value.image);
        } else {
          val.value.userImage = '';
          val.value.myImage = val.value.firstName.charAt(0).toUpperCase() + val.value.lastName.charAt(0).toUpperCase();

          val.value.col = this.colorpalette5[count];
          count += 1;
          if (count === this.colorpalette5.length) {
            count = 0;
          }
        }
        this.users.push(val.value);
      }
    }

    this.cancel();
    this.PhoneNumber = '';
    this.isaddchanneluser = true;
  }

  async addChanTaskType(): Promise<any> {
    let isadd = true;
    this.typeiderror = false;

    if (this.TaskTypeId === undefined || this.TaskTypeId === null || +this.TaskTypeId === 0) {
      isadd = false;
      this.typeiderror = true;
    }

    if (isadd === true) {
      this.loading = true;
      this.showLoad();

      const type = new ChannelTaskType();
      type.ChannelId = this.orgchan.channelId;
      type.TaskTypeId = this.TaskTypeId as number;
      if (this.isdefaulttasktype === true) {
        type.IsDefault = 'Y';
      } else {
        type.IsDefault = 'N';
      }

      const res = await this.chanTaskTypeService.postChannelTaskType(type)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // user deleted
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.TASKTYPEADDED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          await this.getChanTaskTypes();
          await this.getOrgChannels();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.TASKTYPENOTADDED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  async addChanUser(): Promise<any> {
    let isadd = true;
    this.phoneerror = false;

    if (this.PhoneNumber === undefined || this.PhoneNumber === null || this.PhoneNumber.trim() === '') {
      isadd = false;
      this.phoneerror = true;
    }

    if (isadd === true) {
      this.loading = true;
      this.showLoad();

      const cuser = new ChannelUser();
      cuser.ChannelId = this.orgchan.channelId;
      cuser.PhoneNumber = this.PhoneNumber as string;

      const res = await this.chanUserService.postChannelUser(cuser)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // user deleted
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.CHANNELUSERADDED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          await this.getChannelUsers();
          await this.getOrgChannels();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.CHANNELUSERNOTADDED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  showAddOrgChannel(): any {
    this.cancel();
    this.isaddorgchannel = true;
  }

  async createOrgChannel(): Promise<any> {
    let iscreate = true;
    this.channelnameerror = false;
    this.channeliconerror = false;

    if (this.ChannelName === undefined || this.ChannelName === null || this.ChannelName.trim() === '') {
      this.channelnameerror = true;
      iscreate = false;
    }

    // if (this.ChannelIcon === undefined || this.ChannelIcon === null || this.ChannelIcon.trim() === '') {
    //   this.channeliconerror = true;
    //   iscreate = false;
    // }

    if (iscreate === true) {
      // create organisation channel
      const chan = new Channel();
      chan.OrganisationName = this.org.organisationName;
      chan.ChannelName = this.ChannelName as string;
      chan.ChannelIcon = this.ChannelIcon as string;

      this.loading = true;
      this.showLoad();

      const res = await this.channelService.postChannel(chan)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res.trim() !== '') {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGCHANNELCREATED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          this.page = 1;
          await this.getOrgChannels();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGCHANNELNOTCREATED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  async saveOrgChannel(): Promise<any> {
    let iscreate = true;
    this.channelnameerror = false;
    this.channeliconerror = false;

    if (this.ChannelName === undefined || this.ChannelName === null || this.ChannelName.trim() === '') {
      this.channelnameerror = true;
      iscreate = false;
    }
    if (iscreate === true) {
      // update organisation channel
      const val = new StringUpdater();
      val.Id = this.orgchan.channelId;
      val.Values = [];

      const channame = new KeyValue();
      channame.Key = 'chan_name';
      channame.Value = this.ChannelName as string;
      val.Values.push(channame);

      const chanicon = new KeyValue();
      chanicon.Key = 'chan_icon';
      chanicon.Value = this.ChannelIcon as string;
      val.Values.push(chanicon);

      this.loading = true;
      this.showLoad();

      const res = await this.channelService.putChannel(val)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGCHANNELUPDATED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          this.page = 1;
          await this.getOrgChannels();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGCHANNELNOTUPDATED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  async showDeleteOrgUser(orguser: any): Promise<any> {
    this.orguser = orguser;
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + orguser.user.firstName + ' ' + orguser.user.lastName + '?', orguser.phoneNumber, 'cdu');
  }

  async showAddOrgUser(): Promise<any> {
    this.loading = false;

    if (this.tempusers.length === 0) {
      await this.getUsers();
    }

    if (this.orgusers !== undefined && this.orgusers !== null && this.orgusers.length > 0) {
      const myusers = [];
      for (const val of this.tempusers) {
        let add = true;
        for (const u of this.orgusers) {
          if (u.phoneNumber === val.value.phoneNumber) {
            add = false;
          }
        }

        if (add === true) {
          myusers.push(val);
        }
      }

      this.users = myusers;
    } else {
      this.users = this.tempusers;
    }

    this.isaddorguser = true;
  }

  async getUsers(): Promise<any> {
    let org = ' ';
    const o = localStorage.getItem('uo');
    if (o !== undefined && o !== null) {
      org = o;
    }

    this.loading = true;
    this.showLoad();

    const myusers = [];
    const users = await this.userService.getUsers(this.page, this.size, ' ', ' ', org)
    .toPromise().catch(err => this.handleError(err));

    if (users != null && users.length > 0) {
      for (const val of users) {
        val.color = 'white';
        this.tempusers.push({ label: val.firstName + ' ' + val.lastName, value: val });
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async addOrgUser(): Promise<any> {
    let isadd = true;
    this.usererror = false;
    this.uiderror = false;

    if (this.user === undefined || this.user === null) {
      isadd = false;
      this.usererror = true;
    }
    if (this.Uid === undefined || this.Uid === null || +this.Uid === 0) {
      isadd = false;
      this.uiderror = true;
    }

    if (isadd === true) {
      this.loading = true;
      this.showLoad();

      const uorg = new OrganisationUser();
      uorg.OrganisationName = this.org.organisationName;
      uorg.PhoneNumber = this.user.phoneNumber;
      uorg.UserId = +this.Uid;

      const res = await this.orgUserService.postOrganisationUser(uorg)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // user deleted
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERADDED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          await this.getOrgUsers();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.USERNOTADDED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  addChanUserClicked(u: any): any {
    const musers = [];
    for (const val of this.users) {
      const mu: any = [];
      mu.col = val.col;
      mu.device = val.device;
      mu.firstName = val.firstName;
      mu.image = val.image;
      mu.lastActivity = val.lastActivity;
      mu.lastName = val.lastName;
      mu.myImage = val.myImage;
      mu.orgs = val.orgs;
      mu.password = val.password;
      mu.phoneNumber = val.phoneNumber;
      mu.status = val.status;
      mu.userImage = val.userImage;
      if (u.phoneNumber === val.phoneNumber) {
        mu.color = '#c9e2ee';
      } else {
        mu.color = 'white';
      }
      musers.push(mu);
    }
    this.users = musers;
  }

  showChannels(org: any): any {

  }

  channelClicked(channel: any): any {
    const mchans = [];
    for (const val of this.ochannels) {
      const mc: any = [];
      mc.chanImage = val.chanImage;
      mc.channelIcon = val.channelIcon;
      mc.channelId = val.channelId;
      mc.channelName = val.channelName;
      mc.col = val.col;
      mc.myImage = val.myImage;
      mc.options = val.options;
      mc.orgnisationName = val.organisationName;
      mc.typeCount = val.typeCount;
      mc.userCount = val.userCount;
      if (channel.channelId === val.channelId) {
        mc.color = '#c9e2ee';
      } else {
        mc.color = 'white';
      }
      mchans.push(mc);
    }

    this.ochannels = mchans;
  }

  async apply(): Promise<any> {
    if (this.filterform === undefined || this.filterform === null
    || this.filterform.value === undefined || this.filterform.value === null
    || this.filterform.value.filter === undefined || this.filterform.value.filter === null) {
      // get all
      this.page = 1;
      this.orga = ' ';
      await this.getOrganisations();
    } else if (this.filterform.value.filter === 'organisation') {
      if (this.filterform.value.name === undefined || this.filterform.value.name === null
         || this.filterform.value.name.trim() === '') {
        // no name
        const title = await this.translate.get('GENERAL.ENTERVALUE').toPromise().catch(err => console.log(err));
        this.showConfirm(title, '', 'cm', 'info');
      } else {
        // filter by organisation name
        this.page = 1;
        this.orga = this.filterform.value.name;
        await this.getOrganisations();
      }
    } else if (this.filterform.value.filter === 'channel') {
      // filter by channel name
    }
  }

  async applyObsolete(): Promise<any> {
    if (this.filter === undefined || this.filter === null) {
      // get all
      this.page = 1;
      this.orga = ' ';
      await this.getOrganisations();
    } else if (this.filter === 'organisation') {
      if (this.Name === undefined || this.Name === null || this.Name.trim() === '') {
        // no name
        const title = await this.translate.get('GENERAL.ENTERVALUE').toPromise().catch(err => console.log(err));
        this.showConfirm(title, '', 'cm', 'info');
      } else {
        // filter by organisation name
        this.page = 1;
        this.orga = this.Name;
        await this.getOrganisations();
      }
    } else if (this.filter === 'channel') {
      // filter by channel name
    }
  }

  loadMoreO(): any {
    this.page += 1;
    this.getOrganisations();
  }

  toggleFilter(): any {
    this.isfilter = !this.isfilter;
  }

  onFileSelected(): any {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.iconname = inputNode.files[0].name;
        const base64 = e.target.result;
        this.ChannelIcon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.iconname = '';
        this.ChannelIcon = '';
        this.icon = null;
      });
    }
  }

  onFileSelectedEdit(): any {
    const inputNode: any = document.querySelector('#file2');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.iconname = inputNode.files[0].name;
        const base64 = e.target.result;
        this.ChannelIcon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.iconname = '';
        this.ChannelIcon = '';
        this.icon = null;
      });
    }
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  async onConfirmDelete(): Promise<any> {
    this.messageService.clear('cd');
    this.deleteOrg();
  }

  async onConfirmDeleteUser(): Promise<any> {
    this.messageService.clear('cdu');
    this.deleteOrgUser();
  }

  async onConfirmDeleteChannel(): Promise<any> {
    this.messageService.clear('cdchan');
    this.deleteOrgChannel();
  }

  async onConfirmDeleteChannelTaskType(): Promise<any> {
    this.messageService.clear('cdchantt');
    this.deleteChannelTaskType();
  }

  async onConfirmDeleteChannelUser(): Promise<any> {
    this.messageService.clear('cdchanuser');
    this.deleteChannelUser();
  }

  async onConfirmAddChannelUser(): Promise<any> {
    this.messageService.clear('cachanuser');
    this.addChanUser();
  }

  async onConfirmOk(): Promise<any> {
    this.messageService.clear('cm');
  }

  onReject(): any {
      this.messageService.clear('cd');
      this.messageService.clear('cdu');
      this.messageService.clear('cdchan');
      this.messageService.clear('cdchantt');
      this.messageService.clear('cdchanuser');
      this.messageService.clear('cu');
      this.messageService.clear('cl');
      this.messageService.clear('cm');
  }

  onRejectAddChannelUser(): any {
    this.PhoneNumber = '';
    this.messageService.clear('cachanuser');
  }

  clear(): any {
      this.messageService.clear();
  }

  onResize(event: any): any {
    // const val = event.target.innerWidth;
    let height = 0;
    if (event === null) {
      height = window.innerHeight;
    } else {
      height = event.target.innerHeight;
    }
    this.contentdiv = (height - this.menuheight - 25) + 'px';
    const headerheight = document.getElementById('myheader')?.offsetHeight;
    const mainheight = document.getElementById('maindata')?.offsetHeight;

    if (headerheight !== undefined && headerheight !== null
      && mainheight !== undefined && mainheight !== null) {
      this.divheight = (((height - this.menuheight - 25) - headerheight - mainheight) - 90) + 'px';
      this.divheight2 = this.divheight;
    }
  }

  async handleError(err: any): Promise<any> {
    this.loading = false;
    this.hideLoad();

    if (err !== undefined && err != null) {
      if (err.error !== undefined && err.error !== null) {
        if (err.error.MessageText !== undefined && err.error.MessageText !== null) {
          if (err.error.MessageText === 'duplicate key value violates unique constraint "t_org_pkey"') {
            // user already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
            const detail = await this.translate.get('GENERAL.ORGEXISTS').toPromise().catch(myerr => console.log(myerr));
            this.showWarn(title, detail, 30000);
          } else if (err.error.MessageText === 'duplicate key value violates unique constraint "t_org_usr_user_id_org_name_phone_nr_key"') {
            // user already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
            const detail = await this.translate.get('GENERAL.ORGUSEREXISTS').toPromise().catch(myerr => console.log(myerr));
            this.showWarn(title, detail, 30000);
          } else if (err.error.MessageText === 'duplicate key value violates unique constraint "t_channel_chan_name_org_name_key"') {
            // user already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
            const detail = await this.translate.get('GENERAL.ORGCHANNELEXISTS').toPromise().catch(myerr => console.log(myerr));
            this.showWarn(title, detail, 30000);
          } else if (err.error.MessageText ===
            'duplicate key value violates unique constraint "t_chan_tsk_type_chan_id_task_type_id_key"') {
            // user already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
            const detail = await this.translate.get('GENERAL.TASKTYPEEXISTS').toPromise().catch(myerr => console.log(myerr));
            this.showWarn(title, detail, 30000);
          } else {
            console.log(err);
          }
        } else if (err.error.Message !== undefined && err.error.Message !== null) {
          if (err.error.Message === 'default task type already set') {
            // default task type already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
            const detail = await this.translate.get('GENERAL.DEFAULTTASKTYPEALREADYSET').toPromise().catch(myerr => console.log(myerr));
            this.showWarn(title, detail, 30000);
          }
        } else {
          console.log(err);
        }
      }
    }
  }

}
