export class User {
    public PhoneNumber!: string;
    public FirstName!: string;
    public LastName!: string;
    public LastActivity!: Date;
    public Password!: string;
    public Device!: string;
    public Image!: string;
    public Status!: string;
}
