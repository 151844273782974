import { ChannelTaskType } from './../../Models/channel-task-type';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { StringUpdater } from 'src/app/Helpers/updaters';
import { Channel } from 'src/app/Models/channel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChanTaskTypeService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getChannelTaskTypes(page: number, size: number, chanid: string, typeid: number, org: string): Observable<any> {
    const url = this.apiUrl + '/api/channeltasktype/' + page + '/' + size + '/' + chanid
     + '/' + typeid + '/' + org + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postChannelTaskType(type: ChannelTaskType): Observable<any> {
    return this.httpclient.post(this.apiUrl + '/api/channeltasktype', type)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteChannelTaskType(type: ChannelTaskType): Observable<any> {
    return this.httpclient.put(this.apiUrl + '/api/channeltasktype/delete/id', type)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
