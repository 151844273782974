import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { StringUpdater, KeyValue } from 'src/app/Helpers/updaters';
import { Organisation } from 'src/app/Models/organisation';
import { OrganisationUser } from 'src/app/Models/organisation-user';
import { ChannelService } from 'src/app/Services/channel/channel.service';
import { OrgUserService } from 'src/app/Services/org-user/org-user.service';
import { OrgService } from 'src/app/Services/org/org.service';
import { UserService } from 'src/app/Services/user/user.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  orgs: any = [];
  org: any;

  isnoorgs = false;
  showchannels = false;
  loadmoreo = false;

  isfilter = false;

  divname = 'mydiv';

  page = 1;
  size = 100;

  loading = false;

  OrgName = '';
  NtmUrl = '';
  SsoUrl = '';
  SsoSecret = '';

  orgnameerror = false;
  ntmurlerror = false;
  ssourlerror = false;
  ssosecreterror = false;

  usererror = false;
  uiderror = false;

  iscreateorg = false;
  iseditorg = false;
  isaddorguser = false;

  isdetail = false;

  orgusers: any = [];
  orguser: any;

  ochannels: any = [];

  divheight = '0px';
  divheight2 = '0px';
  contentdiv = '';

  tempusers: any = [];
  users: any = [];
  user: any;
  Uid = '';

  showfilter = true;

  filters: any = [];
  filter: any;
  Name = '';
  orga = '';

  filterform: any;

  showdivs = false;

  constructor(
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private messageService: MessageService,
    private orgService: OrgService,
    private orgUserService: OrgUserService,
    private channelService: ChannelService,
    private keycloak: KeycloakService,
    private fb: FormBuilder
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    const menuheight = document.getElementById('menudiv')?.offsetHeight;
    const contentheight = localStorage.getItem('content');

    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 15) + 'px';
    } else {
      this.contentdiv = '85vh';
    }
  }

  async ngOnInit(): Promise<any> {
    this.filterform = this.fb.group({
      filter: new FormControl(),
      name: new FormControl()
    }) as FormGroup;

    const o = localStorage.getItem('uo');
    if (o !== undefined && o !== null) {
      this.orga = o;
    }

    const org = await this.translate.get('GENERAL.ORGANISATION').toPromise().catch((err: any) => console.log(err));
    const chan = await this.translate.get('GENERAL.CHANNEL').toPromise().catch((err: any) => console.log(err));

    this.filters.push({ label: org + ' ', value: 'organisation'});
    // this.filters.push({ label: chan, value: 'channel'});

    const orgadmin = this.keycloak.isUserInRole('orgadminrole');
    if (orgadmin === true) {
      this.showfilter = false;
    }
    await this.getOrganisations();
    await this.showFirst();
  }

  filterSelection(): any {
    if (this.filterform.value.filter === 'organisation') {
      // this.isorg = true;
      // this.isname = false;
      this.filterform.controls.name.setValue('');
    } else if (this.filterform.value.filter === 'channel') {
      // this.isorg = false;
      // this.isname = true;
      // this.organisation = null;
    } else {
      // this.isorg = false;
      // this.isname = false;

      this.filterform.reset();
      // this.organisation = null;
    }
  }

  filterSelectionObsolete(): any {
    if (this.filter === 'organisation') {
      // this.isorg = true;
      // this.isname = false;
      this.Name = '';
    } else if (this.filter === 'channel') {
      // this.isorg = false;
      // this.isname = true;
      // this.organisation = null;
    } else {
      // this.isorg = false;
      // this.isname = false;

      this.Name = ' ';
      // this.organisation = null;
    }
  }

  async showFirst(): Promise<any> {
    if (this.orgs !== undefined && this.orgs !== null && this.orgs.length > 0) {
      await this.orgClicked(this.orgs[0]);
    }
  }

  async getOrganisations(): Promise<any> {
    this.loading = true;
    this.showLoad();
    this.isnoorgs = false;

    const orgs = await this.orgService.getOrganisations(this.page, this.size, this.orga)
    .toPromise().catch((err: any) => this.handleError(err));

    const os: any = [];
    if (orgs !== undefined && orgs !== null && orgs.length > 0) {
      if (orgs.length < this.size) {
        this.loadmoreo = false;
      } else {
        this.loadmoreo = true;
      }

      let options: any = [];
      for (const val of orgs) {
        options = [];
        const det = await this.translate.get('GENERAL.DETAIL')
        .toPromise().catch((err: any) => console.log(err));

        options.push({ label: det, value: 'Detail',
        command: async (event: any) => { await this.showDetail(val); }});

        val.options = options;
        val.color = 'white';
        if (this.page > 1) {
          this.orgs.push(val);
        } else {
          os.push(val);
        }
      }
    } else {
      this.loadmoreo = false;

      if (this.page === 1 && os.length === 0) {
        this.isnoorgs = true;
      }
    }

    if (this.page === 1) {
      this.orgs = os;
    }

    this.loading = false;
    this.hideLoad();
  }

  async createOrg(): Promise<any> {
    let iscreate = true;

    this.orgnameerror = false;
    this.ntmurlerror = false;
    this.ssourlerror = false;
    this.ssosecreterror = false;

    if (this.OrgName === undefined || this.OrgName === null || this.OrgName.trim() === '') {
      this.orgnameerror = true;
      iscreate = false;
    }

    if (this.NtmUrl === undefined || this.NtmUrl === null || this.NtmUrl.trim() === '') {
      this.ntmurlerror = true;
      iscreate = false;
    }

    if (this.SsoUrl === undefined || this.SsoUrl === null || this.SsoUrl.trim() === '') {
      this.ssourlerror = true;
      iscreate = false;
    }

    if (this.SsoSecret === undefined || this.SsoSecret === null || this.SsoSecret.trim() === '') {
      this.ssosecreterror = true;
      iscreate = false;
    }

    if (iscreate === true) {
      // create org
      this.loading = true;
      this.showLoad();

      const org = new Organisation();
      org.OrganisationName = this.OrgName;
      org.NtmUrl = this.NtmUrl;
      org.SsoUrl = this.SsoUrl;
      org.SsoSecret = this.SsoSecret;

      const res = await this.orgService.postOrganisation(org)
      .toPromise().catch((err: any) => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch((err: any) => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGCREATED').toPromise().catch((err: any) => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          this.page = 1;
          await this.getOrganisations();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch((err: any) => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGNOTCREATED').toPromise().catch((err: any) => console.log(err));
          this.showError(title, detail, 5000);
        }

        this.loading = false;
        this.hideLoad();
      }
    }
  }

  async saveOrg(): Promise<any> {
    let iscreate = true;

    this.orgnameerror = false;
    this.ntmurlerror = false;
    this.ssourlerror = false;
    this.ssosecreterror = false;

    if (this.OrgName === undefined || this.OrgName === null || this.OrgName.trim() === '') {
      this.orgnameerror = true;
      iscreate = false;
    }

    if (this.NtmUrl === undefined || this.NtmUrl === null || this.NtmUrl.trim() === '') {
      this.ntmurlerror = true;
      iscreate = false;
    }

    if (this.SsoUrl === undefined || this.SsoUrl === null || this.SsoUrl.trim() === '') {
      this.ssourlerror = true;
      iscreate = false;
    }

    if (this.SsoSecret === undefined || this.SsoSecret === null || this.SsoSecret.trim() === '') {
      this.ssosecreterror = true;
      iscreate = false;
    }

    if (iscreate === true) {
      // create org
      this.loading = true;
      this.showLoad();

      const val = new StringUpdater();
      val.Id = this.OrgName;
      val.Values = [];

      const ntmurl = new KeyValue();
      ntmurl.Key = 'ntm_url';
      ntmurl.Value = this.NtmUrl;
      val.Values.push(ntmurl);

      const ssourl = new KeyValue();
      ssourl.Key = 'sso_url';
      ssourl.Value = this.SsoUrl;
      val.Values.push(ssourl);

      const ssosecret = new KeyValue();
      ssosecret.Key = 'sso_secret';
      ssosecret.Value = this.SsoSecret;
      val.Values.push(ssosecret);

      const res = await this.orgService.putOrganisation(val)
      .toPromise().catch((err: any) => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch((err: any) => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGUPDATED').toPromise().catch((err: any) => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          this.page = 1;
          await this.getOrganisations();

          for (const v of this.orgs) {
            if (v.organisationName === this.org.organisationName) {
              this.org = v;
              break;
            }
          }
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch((err: any) => console.log(err));
          const detail = await this.translate.get('GENERAL.ORGNOTUPDATED').toPromise().catch((err: any) => console.log(err));
          this.showError(title, detail, 5000);
        }

        this.loading = false;
        this.hideLoad();
      }
    }
  }

  cancel(): any {
    this.iscreateorg = false;
    this.iseditorg = false;
    this.isaddorguser = false;

    this.orgnameerror = false;
    this.ntmurlerror = false;
    this.ssourlerror = false;
    this.ssosecreterror = false;

    this.usererror = false;
    this.uiderror = false;

    this.OrgName = '';
    this.NtmUrl = '';
    this.SsoUrl = '';
    this.SsoSecret = '';

    this.user = null;
    this.Uid = '';
  }

  showCreateOrg(): any {
    this.loading = false;
    this.cancel();
    this.iscreateorg = true;
  }

  showEditOrg(): any {
    this.loading = false;
    this.cancel();

    this.OrgName = this.org.organisationName;
    this.NtmUrl = this.org.ntmUrl;
    this.SsoUrl = this.org.ssoUrl;
    this.SsoSecret = this.org.ssoSecret;

    this.iseditorg = true;
  }

  async showDetail(org: any): Promise<any> {
    this.org = org;
    this.isdetail = true;

    await this.getOrgUsers();
    await this.getOrgChannels();
  }

  async orgClicked(org: any): Promise<any> {
    if (this.isdetail === true) {
      return;
    }

    this.org = org;

    const myorgs = [];
    for (const val of this.orgs) {
      const value: any = [];
      value.ntmUrl = val.ntmUrl;
      value.options = val.options;
      value.organisationName = val.organisationName;
      value.ssoSecret = val.ssoSecret;
      value.ssoUrl = val.ssoUrl;
      if (val.organisationName === org.organisationName) {
        value.color = 'lightgray';
      } else {
        value.color = 'white';
      }
      myorgs.push(value);
    }

    this.orgs = myorgs;

    this.orgusers = [];
    this.ochannels = [];
    await this.getOrgUsers();
    await this.getOrgChannels();

    const height = document.getElementById('orgvalues')?.offsetHeight;

    if (height !== undefined && height !== null) {
      this.divheight = (height - 85) + 'px';
      this.divheight2 = (height - 65) + 'px';
    } else {
      this.divheight = '300px';
      this.divheight2 = '300px';
    }
    this.showdivs = true;
}

  async showDelete(): Promise<any> {
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch((err: any) => console.log(err));
    if (this.orgusers !== undefined && this.orgusers !== null && this.orgusers.length > 0
      && this.ochannels !== undefined && this.ochannels !== null && this.ochannels.length > 0) {
        const addinfo = await this.translate.get('GENERAL.CONTAINSUSERSANDCHANNELS').toPromise().catch((err: any) => console.log(err));
        this.showConfirm(title + ' ' + this.org.organisationName + '?', addinfo, 'cd');
    } else if (this.orgusers !== undefined && this.orgusers !== null && this.orgusers.length > 0) {
      const addinfo = await this.translate.get('GENERAL.CONTAINSUSERS').toPromise().catch((err: any) => console.log(err));
      this.showConfirm(title + ' ' + this.org.organisationName + '?', addinfo, 'cd');
    } else if (this.ochannels !== undefined && this.ochannels !== null && this.ochannels.length > 0) {
      const addinfo = await this.translate.get('GENERAL.CONTAINSCHANNELS').toPromise().catch((err: any) => console.log(err));
      this.showConfirm(title + ' ' + this.org.organisationName + '?', addinfo, 'cd');
    } else {
      this.showConfirm(title + ' ' + this.org.organisationName + '?', '', 'cd');
    }
  }

  async deleteOrg(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const res = await this.orgService.deleteOrganisation(this.org.organisationName)
    .toPromise().catch((err: any) => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch((err: any) => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGDELETED').toPromise().catch((err: any) => console.log(err));
        this.showSuccess(title, detail);

        this.cancel();
        this.page = 1;
        await this.getOrganisations();
        this.isdetail = false;
        this.org = null;
        await this.showFirst();
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch((err: any) => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGNOTDELETED').toPromise().catch((err: any) => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async deleteOrgUser(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const myorguser = new OrganisationUser();
    myorguser.OrganisationName = this.orguser.organisationName;
    myorguser.PhoneNumber = this.orguser.phoneNumber;
    myorguser.UserId = this.orguser.userId;

    const res = await this.orgUserService.deleteOrganisation(myorguser)
    .toPromise().catch((err: any) => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch((err: any) => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGUSERDELETED').toPromise().catch((err: any) => console.log(err));
        this.showSuccess(title, detail);

        await this.getOrgUsers();
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch((err: any) => console.log(err));
        const detail = await this.translate.get('GENERAL.ORGUSERNOTDELETED').toPromise().catch((err: any) => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async getOrgUsers(): Promise<any> {
    this.loading = true;
    this.showLoad();

    this.orgusers = [];
    const orgusers = await this.orgUserService.getOrganisationUsers(0, 0, this.org.organisationName, ' ')
    .toPromise().catch((err: any) => this.handleError(err));

    if (orgusers !== undefined || orgusers !== null && orgusers.length > 0) {
      for (const val of orgusers) {
        this.orgusers.push(val);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async getOrgChannels(): Promise<any> {
    this.loading = true;
    this.showLoad();

    this.ochannels = [];
    const chans = await this.channelService.getChannels(0, 0, ' ', ' ', this.org.organisationName)
    .toPromise().catch((err: any) => this.handleError(err));

    if (chans !== undefined || chans !== null && chans.length > 0) {
      for (const val of chans) {
        this.ochannels.push(val);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async showDeleteOrgUser(orguser: any): Promise<any> {
    this.orguser = orguser;
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch((err: any) => console.log(err));
    this.showConfirm(title + ' ' + orguser.user.firstName + ' ' + orguser.user.lastName + '?', orguser.phoneNumber, 'cdu');
  }

  async showAddOrgUser(): Promise<any> {
    this.loading = false;

    if (this.tempusers.length === 0) {
      await this.getUsers();
    }

    if (this.orgusers !== undefined && this.orgusers !== null && this.orgusers.length > 0) {
      const myusers = [];
      for (const val of this.tempusers) {
        let add = true;
        for (const u of this.orgusers) {
          if (u.phoneNumber === val.value.phoneNumber) {
            add = false;
          }
        }

        if (add === true) {
          myusers.push(val);
        }
      }

      this.users = myusers;
    } else {
      this.users = this.tempusers;
    }

    this.isaddorguser = true;
  }

  async getUsers(): Promise<any> {
    let org = ' ';
    const o = localStorage.getItem('uo');
    if (o !== undefined && o !== null) {
      org = o;
    }

    this.loading = true;
    this.showLoad();

    const myusers = [];
    const users = await this.userService.getUsers(this.page, this.size, ' ', ' ', org)
    .toPromise().catch((err: any) => this.handleError(err));

    if (users != null && users.length > 0) {
      for (const val of users) {
        this.tempusers.push({ label: val.firstName + ' ' + val.lastName, value: val });
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async addOrgUser(): Promise<any> {
    let isadd = true;
    this.usererror = false;
    this.uiderror = false;

    if (this.user === undefined || this.user === null) {
      isadd = false;
      this.usererror = true;
    }
    if (this.Uid === undefined || this.Uid === null || +this.Uid === 0) {
      isadd = false;
      this.uiderror = true;
    }

    if (isadd === true) {
      this.loading = true;
      this.showLoad();

      const uorg = new OrganisationUser();
      uorg.OrganisationName = this.org.organisationName;
      uorg.PhoneNumber = this.user.phoneNumber;
      uorg.UserId = +this.Uid;

      const res = await this.orgUserService.postOrganisationUser(uorg)
      .toPromise().catch((err: any) => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // user deleted
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch((err: any) => console.log(err));
          const detail = await this.translate.get('GENERAL.USERADDED').toPromise().catch((err: any) => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          await this.getOrgUsers();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch((err: any) => console.log(err));
          const detail = await this.translate.get('GENERAL.USERNOTADDED').toPromise().catch((err: any) => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  showChannels(org: any): any {

  }

  channelClicked(Channel: any): any {

  }

  async apply(): Promise<any> {
    if (this.filterform === undefined || this.filterform === null
    || this.filterform.value === undefined || this.filterform.value === null
    || this.filterform.value.filter === undefined || this.filterform.value.filter === null) {
      // get all
      this.page = 1;
      this.orga = ' ';
      await this.getOrganisations();
    } else if (this.filterform.value.filter === 'organisation') {
      if (this.filterform.value.name === undefined || this.filterform.value.name === null
         || this.filterform.value.name.trim() === '') {
        // no name
        const title = await this.translate.get('GENERAL.ENTERVALUE').toPromise().catch((err: any) => console.log(err));
        this.showConfirm(title, '', 'cm', 'info');
      } else {
        // filter by organisation name
        this.page = 1;
        this.orga = this.filterform.value.name;
        await this.getOrganisations();
      }
    } else if (this.filterform.value.filter === 'channel') {
      // filter by channel name
    }
  }

  async applyObsolete(): Promise<any> {
    if (this.filter === undefined || this.filter === null) {
      // get all
      this.page = 1;
      this.orga = ' ';
      await this.getOrganisations();
    } else if (this.filter === 'organisation') {
      if (this.Name === undefined || this.Name === null || this.Name.trim() === '') {
        // no name
        const title = await this.translate.get('GENERAL.ENTERVALUE').toPromise().catch((err: any) => console.log(err));
        this.showConfirm(title, '', 'cm', 'info');
      } else {
        // filter by organisation name
        this.page = 1;
        this.orga = this.Name;
        await this.getOrganisations();
      }
    } else if (this.filter === 'channel') {
      // filter by channel name
    }
  }

  loadMoreO(): any {
    this.page += 1;
    this.getOrganisations();
  }

  toggleFilter(): any {
    this.isfilter = !this.isfilter;
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  async onConfirmDelete(): Promise<any> {
    this.messageService.clear('cd');
    this.deleteOrg();
  }

  async onConfirmDeleteUser(): Promise<any> {
    this.messageService.clear('cdu');
    this.deleteOrgUser();
  }

  async onConfirmOk(): Promise<any> {
    this.messageService.clear('cm');
  }

  onReject(): any {
      this.messageService.clear('cd');
      this.messageService.clear('cdu');
      this.messageService.clear('cu');
      this.messageService.clear('cl');
      this.messageService.clear('cm');
  }

  clear(): any {
      this.messageService.clear();
  }

  async handleError(err: any): Promise<any> {
    this.loading = false;
    this.hideLoad();

    if (err !== undefined && err != null) {
      if (err.error !== undefined && err.error !== null) {
        if (err.error.MessageText !== undefined && err.error.MessageText !== null) {
          if (err.error.MessageText === 'duplicate key value violates unique constraint "t_org_pkey"') {
            // user already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch((myerr: any) => console.log(myerr));
            const detail = await this.translate.get('GENERAL.ORGEXISTS').toPromise().catch((myerr: any) => console.log(myerr));
            this.showWarn(title, detail, 30000);
          } else if (err.error.MessageText === 'duplicate key value violates unique constraint "t_org_usr_user_id_org_name_phone_nr_key"') {
            // user already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch((myerr: any) => console.log(myerr));
            const detail = await this.translate.get('GENERAL.ORGUSEREXISTS').toPromise().catch((myerr: any) => console.log(myerr));
            this.showWarn(title, detail, 30000);
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
      }
    }
  }

}
